import {
    AttackDieType,
    DefenseDieType,
    DieSideId,
    DieType,
} from '@wareena/grid-battles-entity/game/codebook/dice';
import ChanceTable from './ChanceTable';
import GameDieSideAsImage from '@wareena/grid-battles-ui/game/dice/GameDieSideAsImage';
import DieIconAsImage from '@wareena/grid-battles-ui/game/dice/DieIconAsImage';

export default function ChancesTablePage() {
    const dieSides: DieSideId[] = [0, 1, 2, 3, 4, 5];
    const dice: { typeId: DieType; name: string }[] = [
        { typeId: AttackDieType.Yellow, name: 'Yellow Attack Die' },
        { typeId: AttackDieType.Blue, name: 'Blue Attack Die' },
        { typeId: AttackDieType.Red, name: 'Red Attack Die' },
        { typeId: DefenseDieType.Brown, name: 'Brown Defense Die' },
        { typeId: DefenseDieType.Grey, name: 'Grey Defense Die' },
        { typeId: DefenseDieType.Black, name: 'Black Defense Die' },
    ];

    return (
        <>
            <div className="rules-text mb-4">
                <h2 className="title is-2">Chances Table</h2>

                <p>
                    On this page, you can see what are your chances to deal hits to your enemy. You
                    can play with the various combinations of units and terrain they occupy and see
                    how they can influence the probabilities of inflicting losses. For example, you
                    can find out that even the weakest unit can be quite dangerous when placed on
                    top of a hill.
                </p>

                <ChanceTable />

                <h3 className="title is-3">Dice</h3>

                <p>
                    All our dice are shown below. You can see that <i>Yellow</i> is the worst attack
                    die, and <i>Red</i> is the best. Alternatively, <i>Brown</i> die is really the
                    worst for defense, while <i>Black</i> is the best.
                </p>

                <div className="dice-grid">
                    {dice.map((dieInfo) => (
                        <div className="card" key={dieInfo.typeId}>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className="image is-48x48">
                                            <DieIconAsImage size={40} dieType={dieInfo.typeId} />
                                        </figure>
                                    </div>
                                    <div className="media-content">
                                        <h4 className="title is-4">{dieInfo.name}</h4>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="is-flex is-align-items-center mb-1 has-small-column-gap">
                                        {dieSides.map((sideId) => (
                                            <GameDieSideAsImage
                                                key={sideId}
                                                size={45}
                                                side={sideId}
                                                dieType={dieInfo.typeId}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
