import { AttackResultType } from '../../types.js';
import collectBonusAttackDice from './collectBonusAttackDice.js';
import collectBonusDefenseDice from './collectBonusDefenseDice.js';
import rollDice from './rollDice.js';
export default function getDiceRollResults(gameState, rules) {
    const diceResults = [];
    const currentCombat = gameState.combats.find((c) => c.id === gameState.currentCombatId);
    if (!currentCombat) {
        return null;
    }
    const attackerId = currentCombat.attackerId;
    const defenderId = currentCombat.defenderId;
    const attackerUnit = gameState.units.find((u) => u.id === attackerId);
    const defenderUnit = gameState.units.find((u) => u.id === defenderId);
    if (!attackerUnit || !attackerId) {
        return null;
    }
    if (!defenderUnit || !defenderId) {
        return null;
    }
    const bonusesForAttacker = rules.getBonusesForUnit(attackerUnit);
    const bonusesForDefender = rules.getBonusesForUnit(defenderUnit);
    diceResults.push({
        type: AttackResultType.Normal,
        results: {
            attackerId,
            defenderId,
            attackerDiceResults: rollDice([
                ...attackerUnit.attackDice,
                ...collectBonusAttackDice(bonusesForAttacker),
            ]),
            defenderDiceResults: rollDice([
                ...defenderUnit.defenseDice,
                ...collectBonusDefenseDice(bonusesForDefender),
            ]),
        },
    });
    if (rules.isBattleBackPossible(attackerUnit, defenderUnit, gameState)) {
        diceResults.push({
            type: AttackResultType.BattleBack,
            results: {
                attackerId: defenderId,
                defenderId: attackerId,
                attackerDiceResults: rollDice([
                    ...defenderUnit.attackDice,
                    ...collectBonusAttackDice(bonusesForDefender),
                ]),
                defenderDiceResults: rollDice([
                    ...attackerUnit.defenseDice,
                    ...collectBonusDefenseDice(bonusesForAttacker),
                ]),
            },
        });
    }
    return diceResults;
}
