import { getAllSymbols, getSymbolsOnBattleDie, } from '@wareena/grid-battles-entity/game/codebook/dice';
export default function calcSymbolsFromRollResults(rollResults) {
    const rolledSymbols = new Map();
    getAllSymbols().forEach((symbol) => {
        rolledSymbols.set(symbol, 0);
    });
    rollResults.forEach((roll) => {
        const battleDieId = roll.die;
        const symbolsOnDie = getSymbolsOnBattleDie(battleDieId);
        const symbols = symbolsOnDie[roll.roll];
        symbols.forEach((symbol) => {
            rolledSymbols.set(symbol, (rolledSymbols.get(symbol) ?? 0) + 1);
        });
    });
    return rolledSymbols;
}
