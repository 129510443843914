import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
import GameStats from '@wareena/grid-battles-ui/game/GameStats';

export default function RoundsTurnsPage() {
    const gameStats = {
        game: {
            state: {
                victoryPoints: [
                    {
                        side: SideId.A,
                        points: 1,
                    },
                    {
                        side: SideId.B,
                        points: 3,
                    },
                ],
                round: 6,
                activeSide: SideId.A,
            },
        },
        playerA: {
            username: 'Adam',
            side: SideId.A,
        },
        playerB: {
            username: 'Betsy',
            side: SideId.B,
        },
        scenario: {
            startingSide: SideId.A,
            victoryConditions: 5,
            name: 'Fields of Grass',
        },
    };

    return (
        <>
            <div className="rules-text">
                <h2 className="title is-2">Rounds, Turns &amp; Activations</h2>

                <p>
                    The game is divided into <strong>rounds</strong>. Each round is started by the
                    same player, the one who commands the <i>starting side</i>. In each round,
                    players take <strong>turns</strong> to <strong>activate</strong> their{' '}
                    <i>fresh</i> units. At the end of a turn, activated units become{' '}
                    <i>fatigued.</i> If there are no fresh units to activate on either side, the
                    round ends, all fatigued units become fresh again and a new round begins.
                </p>

                <p>
                    On top of the game page, we can see the basic game statistics. It looks like
                    this:
                </p>

                <div className="box mt-5">
                    <div className="tutorial-game-stats">
                        <GameStats
                            game={gameStats.game}
                            playerA={gameStats.playerA}
                            playerB={gameStats.playerB}
                            scenario={gameStats.scenario}
                        />
                    </div>
                </div>

                <p>From the example above, we can tell that:</p>
                <ul className="list has-disc my-2 pl-5">
                    <li>
                        the game is currently in its 6th round (
                        <span className="icon-text" title="Round">
                            <span className="icon has-color-player-a">
                                <i className="fas fa-arrows-rotate"></i>
                            </span>
                            <span>6</span>
                        </span>
                        ),
                    </li>
                    <li>
                        The <i>starting side</i> is blue. So blue player begins each round. We
                        denote this by blue coloring of the &quot;Round&quot; icon (
                        <span className="icon has-color-player-a">
                            <i className="fas fa-arrows-rotate"></i>
                        </span>
                        )
                    </li>
                    <li>
                        You control a blue army, while your opponent controls an orange army (
                        <span className="icon-text is-medium">
                            <span className="icon">
                                <i className="fas fa-flag has-color-player-a"></i>
                            </span>
                            <span>Your turn</span>
                        </span>
                        ),
                    </li>
                    <li>You destroyed 1 of enemy&apos;s units,</li>
                    <li>Enemy destroyed 3 of your units,</li>
                    <li>
                        You are currently taking a turn, we are calling you an <i>active player</i>,
                    </li>
                    <li>
                        in order to achieve victory, one of you must destroy 5 units of the other (
                        <span
                            className="icon-text"
                            title={`You need to destroy ${gameStats.scenario.victoryConditions} enemy units`}
                        >
                            <span className="icon">
                                <i className="fas fa-trophy"></i>
                            </span>
                            <span>{gameStats.scenario.victoryConditions}</span>
                        </span>
                        ),
                    </li>
                    <li>
                        you are playing a scenario named &quot;{gameStats.scenario.name}&quot; (
                        <span className="icon-text">
                            <span className="icon">
                                <i className="fas fa-map"></i>
                            </span>
                            <span>{gameStats.scenario.name}</span>
                        </span>
                        ).
                    </li>
                </ul>
            </div>
        </>
    );
}
