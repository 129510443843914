import removeUnit from './removeUnit.js';
export default function applyNewHealth(unitId, newHealth, state) {
    const unit = state.units.find((u) => u.id === unitId);
    if (!unit) {
        throw 'This should never happen';
    }
    if (newHealth === 0) {
        return removeUnit(unitId, state);
    }
    if (unit.health === newHealth) {
        return state;
    }
    return {
        ...state,
        units: state.units.map((unit) => {
            if (unit.id === unitId) {
                return {
                    ...unit,
                    health: newHealth,
                };
            }
            return unit;
        }),
    };
}
