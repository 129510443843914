import machineSpec from './machines/default/machine.json';
import stateHandlers from './stateHandler/index.js';
function createStateMachine({ game, scenario, hexProperties, onRequestSuccess = null, }) {
    const validTransitions = machineSpec.transitions;
    const handlers = machineSpec.handlers.reduce((acc, handlerSpec) => {
        if (!handlerSpec.name) {
            return acc;
        }
        if (!stateHandlers[handlerSpec.name]) {
            return acc;
        }
        const handler = stateHandlers[handlerSpec.name];
        if (typeof handler === 'function') {
            acc[handlerSpec.name] = handler({
                game,
                scenario,
                hexProperties,
            });
        }
        else {
            acc[handlerSpec.name] = handler;
        }
        return acc;
    }, {});
    const getHandler = (stateName) => {
        if (!handlers[stateName]) {
            throw `Missing handler for state ${stateName}`;
        }
        return handlers[stateName];
    };
    const isTransitionValid = (currentStateName, nextStateName) => {
        if (!validTransitions[currentStateName]) {
            return false;
        }
        return validTransitions[currentStateName].includes(nextStateName);
    };
    const machine = {
        isTransitionValid,
        getHandler,
        changeState: function (state, nextStateName, payload) {
            const prevStateName = state.stateName;
            if (!isTransitionValid) {
                throw `Can not change state from ${prevStateName} to ${nextStateName}`;
            }
            const prevHandler = getHandler(prevStateName);
            const nextHandler = getHandler(nextStateName);
            let nextState = state;
            if (prevHandler.onLeave) {
                nextState = prevHandler.onLeave({ nextStateName, game, state, payload });
            }
            nextState.stateName = nextStateName;
            if (nextHandler.onEnter) {
                nextState = nextHandler.onEnter({
                    state: nextState,
                    payload,
                    stateMachine: this,
                });
            }
            return nextState;
        },
        executeRequest: function (request, state) {
            const handler = getHandler(state.stateName);
            const nextState = handler.executeRequest({
                stateMachine: this,
                request,
                state: structuredClone(state),
                game,
                scenario,
                hexProperties,
            });
            if (onRequestSuccess) {
                onRequestSuccess({
                    stateMachine: this,
                    request,
                    nextState,
                    previousState: state,
                });
            }
            return nextState;
        },
    };
    return machine;
}
export default createStateMachine;
