import assertDiceRollsAreValid from './assertDiceRollsAreValid.js';
import resolveRound from './resolveRound.js';
import writeStatsIntoState from './writeStatsIntoState.js';
import collectBonusAttackDice from '../../../helpers/battle/collectBonusAttackDice.js';
import collectBonusDefenseDice from '../../../helpers/battle/collectBonusDefenseDice.js';
import collectBonusSymbols from '../../../helpers/battle/collectBonusSymbols.js';
import { BattleBonusPurpose } from '@wareena/grid-battles-entity/game/codebook/battleBonus';
export default function runBattleRound(attackerForRound, defenderForRound, attackResult, attackResultType, state, rules, currentCombatId, errorMessage = 'No dice rolls') {
    if (!attackResult || attackResult.type !== attackResultType) {
        throw errorMessage;
    }
    let nextState;
    const bonusesForAttacker = rules.getBonusesForUnit(attackerForRound);
    const bonusesForDefender = rules.getBonusesForUnit(defenderForRound);
    const attackerBonusDice = collectBonusAttackDice(bonusesForAttacker);
    const defenderBonusDice = collectBonusDefenseDice(bonusesForDefender);
    assertDiceRollsAreValid(attackerForRound, attackerBonusDice, defenderForRound, defenderBonusDice, attackResult.results);
    const result = resolveRound(attackResult.results, collectBonusSymbols(bonusesForAttacker, BattleBonusPurpose.Attack), collectBonusSymbols(bonusesForDefender, BattleBonusPurpose.Defense), state, rules);
    nextState = writeStatsIntoState({
        stats: { ...result.stats, type: attackResult.type },
        state: result.state,
        combatId: currentCombatId,
    });
    return { nextState, isLastRound: result.isLastRound };
}
