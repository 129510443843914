'use client';

import createMap, {
    setTerrainToPositions,
} from '@wareena/grid-battles-engine/services/map/createMap';
import GameMap from '@wareena/ui/game/map';
import { getTerrainHex } from '@wareena/grid-battles-ui/game/terrain/index';
import { calcMapDimensions, createHexProperties } from '@wareena/game-algorithms';
import convertMapToScenarioFormat from '@wareena/grid-battles-engine/services/map/convertMapToScenarioFormat';
import { TerrainId } from '@wareena/grid-battles-entity/game/map/terrain';
import UnitLayer from '@wareena/grid-battles-ui/game/map/UnitLayer';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
import { createBlockedLosLine, createClearLosLine } from './LosLine';
import ResponsiveSvg from '@wareena/ui/game/map/ResponsiveSvg';

export default function LosExample() {
    const hexProperties = createHexProperties({
        hexSize: 50,
        isFlatTop: false,
        isFirstOffsetted: false,
    });

    const mapWidth = 8;
    const mapHeight = 5;

    let mapHexes = createMap(mapWidth, mapHeight);
    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.Wood,
        [
            { column: 2, row: 1 },
            { column: 7, row: 2 },
        ],
        'single1'
    );

    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.Hill,
        [
            { column: 2, row: 0 },
            { column: 3, row: 2 },
        ],
        'single1'
    );

    const mapDimensions = calcMapDimensions(mapWidth, mapHeight, hexProperties);

    const hexes = convertMapToScenarioFormat(mapHexes);

    const units = [
        createUnit(UnitTypeId.Archer, {
            id: 1,
            label: 'Archer 1',
            position: { column: 0, row: 2 },
        }),
        createUnit(UnitTypeId.Archer, {
            id: 2,
            label: 'Archer 2',
            position: { column: 1, row: 2 },
        }),
        createUnit(UnitTypeId.Archer, {
            id: 3,
            label: 'Archer 3',
            position: { column: 2, row: 2 },
        }),
        createUnit(UnitTypeId.Archer, {
            id: 4,
            label: 'Archer 4',
            position: { column: 2, row: 3 },
        }),
        createUnit(UnitTypeId.Archer, {
            id: 5,
            label: 'Archer 5',
            position: { column: 4, row: 3 },
        }),
        createUnit(UnitTypeId.Pikeman, {
            id: 11,
            side: SideId.B,
            position: { column: 1, row: 0 },
        }),
        createUnit(UnitTypeId.Swordman, {
            id: 12,
            side: SideId.B,
            position: { column: 2, row: 0 },
        }),
        createUnit(UnitTypeId.Axeman, {
            id: 13,
            side: SideId.B,
            position: { column: 3, row: 0 },
        }),
        createUnit(UnitTypeId.Maceman, {
            id: 14,
            side: SideId.B,
            position: { column: 3, row: 1 },
        }),
        createUnit(UnitTypeId.LightCavalry, {
            id: 15,
            side: SideId.B,
            position: { column: 4, row: 2 },
        }),
        createUnit(UnitTypeId.MediumCavalry, {
            id: 16,
            side: SideId.B,
            position: { column: 5, row: 2 },
        }),
        createUnit(UnitTypeId.Knight, {
            id: 17,
            side: SideId.B,
            position: { column: 7, row: 1 },
        }),
    ];

    const ClearLosLine = createClearLosLine(hexProperties);
    const BlockedLosLine = createBlockedLosLine(hexProperties);

    return (
        <>
            <ResponsiveSvg width={mapDimensions.width} height={mapDimensions.height}>
                <GameMap hexProperties={hexProperties} map={hexes} getTerrainHex={getTerrainHex} />
                <UnitLayer hexProperties={hexProperties} units={units} />
                <ClearLosLine start={{ column: 0, row: 2 }} stop={{ column: 1, row: 0 }} />
                <ClearLosLine start={{ column: 1, row: 2 }} stop={{ column: 2, row: 0 }} />
                <ClearLosLine start={{ column: 2, row: 3 }} stop={{ column: 4, row: 2 }} />
                <BlockedLosLine start={{ column: 2, row: 2 }} stop={{ column: 3, row: 0 }} />
                <BlockedLosLine start={{ column: 2, row: 2 }} stop={{ column: 3, row: 1 }} />
                <BlockedLosLine start={{ column: 2, row: 3 }} stop={{ column: 5, row: 2 }} />
                <BlockedLosLine start={{ column: 4, row: 3 }} stop={{ column: 7, row: 1 }} />
            </ResponsiveSvg>
        </>
    );
}
