'use client';

import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
import convertMapToScenarioFormat from '@wareena/grid-battles-engine/services/map/convertMapToScenarioFormat';
import createMap from '@wareena/grid-battles-engine/services/map/createMap';
import TutorialGameMap from '@local/tutorial/ui/TutorialGameMap';
import {
    Game,
    GameState,
    Player,
    Scenario,
} from '@wareena/grid-battles-engine/gameStateMachine/types';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import { ScenarioHexWithId } from '@wareena/grid-battles-entity/scenario/entity/scenario';

export default function ExampleEligibility() {
    const playerA: Player = {
        id: 1,
        side: SideId.A,
    };

    const playerB: Player = {
        id: 2,
        side: SideId.B,
    };

    const map = { cols: 5, rows: 4 };
    const mapHexes = createMap(map.cols, map.rows);

    const scenario: Scenario & {
        hexSize: number;
        isFlatTop: boolean;
        isFirstOffsetted: boolean;
        hexes: ScenarioHexWithId[][];
    } = {
        startingSide: SideId.A,
        victoryConditions: 5,
        activationsPerTurn: 3,
        width: map.cols,
        height: map.rows,
        hexSize: 50,
        isFlatTop: false,
        isFirstOffsetted: false,
        hexes: convertMapToScenarioFormat(mapHexes),
    };

    const state: GameState = {
        stateName: 'selectingAttacker',
        round: 1,
        units: [
            createUnit(UnitTypeId.Axeman, {
                id: 1,
                label: 'Axeman 1',
                position: { column: 1, row: 2 },
            }),
            createUnit(UnitTypeId.LightCavalry, { id: 2, position: { column: 2, row: 2 } }),
            createUnit(UnitTypeId.Archer, { id: 3, position: { column: 2, row: 3 } }),
            createUnit(UnitTypeId.Axeman, {
                id: 4,
                label: 'Axeman 2',
                position: { column: 4, row: 1 },
            }),
            createUnit(UnitTypeId.Archer, { id: 5, position: { column: 0, row: 3 } }),
            createUnit(UnitTypeId.Knight, { id: 6, position: { column: 0, row: 1 } }),
            createUnit(UnitTypeId.Pikeman, {
                id: 11,
                label: 'Pikeman 1',
                side: SideId.B,
                position: { column: 4, row: 0 },
            }),
            createUnit(UnitTypeId.Pikeman, {
                id: 12,
                label: 'Pikeman 2',
                side: SideId.B,
                position: { column: 1, row: 1 },
            }),
            createUnit(UnitTypeId.Pikeman, {
                id: 13,
                label: 'Pikeman 3',
                side: SideId.B,
                position: { column: 2, row: 1 },
            }),
        ],
        activeSide: playerA.side,
        unitsSelectedForActivation: [1, 2, 3],
        movingUnits: { currentlyMovingId: null, units: [] },
        victoryPoints: [],
    };

    const game: Game = {
        hexes: mapHexes,
        players: [playerA, playerB],
    };

    return (
        <TutorialGameMap
            scenario={scenario}
            state={state}
            game={game}
            checkEndOfAnExample={(state) => state.activeSide === SideId.B}
        />
    );
}
