'use client';

import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
import convertMapToScenarioFormat from '@wareena/grid-battles-engine/services/map/convertMapToScenarioFormat';
import createMap, {
    setTerrainToPositions,
} from '@wareena/grid-battles-engine/services/map/createMap';
import TutorialGameMap from '@local/tutorial/ui/TutorialGameMap';
import {
    Game,
    GameState,
    Player,
    Scenario,
} from '@wareena/grid-battles-engine/gameStateMachine/types';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import { ScenarioHexWithId } from '@wareena/grid-battles-entity/scenario/entity/scenario';
import { TerrainId } from '@wareena/grid-battles-entity/game/map/terrain';

export default function ExampleRanged() {
    const playerA: Player = {
        id: 1,
        side: SideId.A,
    };

    const playerB: Player = {
        id: 2,
        side: SideId.B,
    };

    const map = { cols: 8, rows: 8 };
    let mapHexes = createMap(map.cols, map.rows);

    mapHexes = setTerrainToPositions(mapHexes, TerrainId.Wood, [{ column: 2, row: 6 }], 'single1');
    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.Hill,
        [{ column: 1, row: 3 }],
        'twoAdjacents1Angle300'
    );
    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.Hill,
        [{ column: 2, row: 2 }],
        'twoAdjacents1Angle180'
    );
    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.Hill,
        [{ column: 1, row: 2 }],
        'twoAdjacents1Angle60'
    );

    const scenario: Scenario & {
        hexSize: number;
        isFlatTop: boolean;
        isFirstOffsetted: boolean;
        hexes: ScenarioHexWithId[][];
    } = {
        startingSide: SideId.A,
        victoryConditions: 5,
        activationsPerTurn: 3,
        width: map.cols,
        height: map.rows,
        hexSize: 50,
        isFlatTop: false,
        isFirstOffsetted: false,
        hexes: convertMapToScenarioFormat(mapHexes),
    };

    const state: GameState = {
        stateName: 'selectingForMovement',
        round: 1,
        units: [
            createUnit(UnitTypeId.Crossbowman, {
                id: 1,
                position: { column: 0, row: 7 },
            }),
            createUnit(UnitTypeId.Archer, { id: 2, position: { column: 3, row: 7 } }),
            createUnit(UnitTypeId.LongBowArcher, {
                id: 3,
                position: { column: 7, row: 7 },
            }),
            createUnit(UnitTypeId.Knight, {
                id: 4,
                label: 'Knight 1',
                side: SideId.A,
                isFatigued: true,
                position: { column: 6, row: 4 },
            }),
            createUnit(UnitTypeId.Knight, {
                id: 5,
                label: 'Knight 2',
                side: SideId.A,
                isFatigued: true,
                position: { column: 4, row: 5 },
            }),
            createUnit(UnitTypeId.Pikeman, {
                id: 11,
                label: 'Pikeman 1',
                side: SideId.B,
                position: { column: 4, row: 0 },
            }),
            createUnit(UnitTypeId.Pikeman, {
                id: 12,
                label: 'Pikeman 2',
                side: SideId.B,
                position: { column: 2, row: 2 },
            }),
            createUnit(UnitTypeId.Pikeman, {
                id: 13,
                label: 'Pikeman 3',
                side: SideId.B,
                position: { column: 3, row: 2 },
            }),
            createUnit(UnitTypeId.Pikeman, {
                id: 14,
                label: 'Pikeman 4',
                side: SideId.B,
                position: { column: 0, row: 0 },
            }),
            createUnit(UnitTypeId.Pikeman, {
                id: 15,
                label: 'Pikeman 5',
                side: SideId.B,
                position: { column: 0, row: 3 },
            }),
            createUnit(UnitTypeId.Pikeman, {
                id: 16,
                label: 'Pikeman 6',
                side: SideId.B,
                position: { column: 3, row: 3 },
            }),
        ],
        activeSide: playerA.side,
        unitsSelectedForActivation: [1, 2, 3],
        movingUnits: { currentlyMovingId: null, units: [] },
        victoryPoints: [],
    };

    const game: Game = {
        hexes: mapHexes,
        players: [playerA, playerB],
    };

    return (
        <TutorialGameMap
            scenario={scenario}
            state={state}
            game={game}
            checkEndOfAnExample={(state) => state.activeSide === SideId.B}
        />
    );
}
