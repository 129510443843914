import { isUserActive } from '../../utils/index.js';
import checkVictoryConditions from './checkVictoryConditions.js';
import runBattle from './runBattle.js';
import getCurrentCombat from './getCurrentCombat.js';
export default function attackDefender({ request, state, stateMachine, game, hexProperties, scenario, }) {
    const { userId, diceRollResults } = request.payload;
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
    const currentCombat = getCurrentCombat(state);
    let nextState = runBattle(state, game, hexProperties, diceRollResults);
    nextState = {
        ...nextState,
        currentCombatId: null,
        combats: nextState.combats.map((combat) => {
            if (combat.id === currentCombat.id) {
                return {
                    ...combat,
                    diceRolls: diceRollResults,
                    wasResolved: true,
                };
            }
            return combat;
        }),
    };
    const { wasVictoryConditionsAchieved, winner } = checkVictoryConditions(nextState, scenario);
    if (wasVictoryConditionsAchieved) {
        nextState = {
            ...nextState,
            winner,
        };
        return stateMachine.changeState(nextState, 'gameOver', {});
    }
    return stateMachine.changeState(nextState, 'selectingAttacker', {});
}
