import { CombatEffectSymbol, getSymbolsOnBattleDie, } from '@wareena/grid-battles-entity/game/codebook/dice';
export function createProbabilityTable(attackDicePool, defenseDicePool) {
    const dicePool = [...attackDicePool, ...defenseDicePool];
    const rows = 6 ** dicePool.length;
    const columns = dicePool.length;
    const table = [];
    for (let column = 0; column < columns; column += 1) {
        const numOfRepeats = 6 ** column;
        let row = 0;
        while (row < rows) {
            for (let side = 0; side < 6; side += 1) {
                for (let i = 0; i < numOfRepeats; i += 1) {
                    if (!table[row]) {
                        table.push([]);
                    }
                    table[row][column] = {
                        dieSide: side,
                        isAttacker: column < attackDicePool.length,
                        die: dicePool[column],
                    };
                    row += 1;
                }
            }
        }
    }
    return table;
}
export function sumResults(dicePool, diceResults) {
    const results = [];
    for (let row = 0; row < diceResults.length; row += 1) {
        const sumOfSymbols = {
            [CombatEffectSymbol.Bonus]: 0,
            [CombatEffectSymbol.Damage]: 0,
            [CombatEffectSymbol.Shield]: 0,
        };
        for (let col = 0; col < diceResults[row].length; col += 1) {
            const die = dicePool[col];
            const { dieSide, isAttacker } = diceResults[row][col];
            const symbols = getSymbolsOnBattleDie(die)[dieSide];
            symbols.map((symbol) => {
                if (isAttacker) {
                    if (symbol === CombatEffectSymbol.Bonus ||
                        symbol === CombatEffectSymbol.Damage) {
                        sumOfSymbols[symbol] += 1;
                    }
                }
                else {
                    if (symbol === CombatEffectSymbol.Shield) {
                        sumOfSymbols[symbol] += 1;
                    }
                }
            });
        }
        results.push(sumOfSymbols);
    }
    return results;
}
export function calculateHits(summaryDiceResults, attackBonusSymbols, defenseBonusSymbols, hasAdvantage) {
    const hitsPerRow = [];
    let damageFromBonuses = 0;
    let bonusFromBonuses = 0;
    let shieldsFromBonuses = 0;
    attackBonusSymbols.forEach((symbol) => {
        if (symbol === CombatEffectSymbol.Damage) {
            damageFromBonuses += 1;
        }
        else if (symbol === CombatEffectSymbol.Bonus) {
            bonusFromBonuses += 1;
        }
    });
    defenseBonusSymbols.forEach((symbol) => {
        if (symbol === CombatEffectSymbol.Shield) {
            shieldsFromBonuses += 1;
        }
    });
    summaryDiceResults.forEach((result) => {
        let hits = result[CombatEffectSymbol.Damage] + damageFromBonuses;
        if (hasAdvantage) {
            hits += result[CombatEffectSymbol.Bonus] + bonusFromBonuses;
        }
        const shields = result[CombatEffectSymbol.Shield] + shieldsFromBonuses;
        hits = Math.max(hits - shields, 0);
        hitsPerRow.push(hits);
    });
    return hitsPerRow;
}
export function calculateProbability(hits) {
    const occurences = [];
    const chanceForHits = [];
    hits.forEach((num) => {
        if (occurences[num] === undefined) {
            occurences[num] = 0;
        }
        occurences[num] += 1;
    });
    for (let numOfHits = 0; numOfHits < occurences.length; numOfHits += 1) {
        if (!occurences[numOfHits]) {
            chanceForHits[numOfHits] = {
                hits: numOfHits,
                exactChance: 0,
                atLeastChance: 0,
            };
        }
        const chance = occurences[numOfHits] / hits.length;
        chanceForHits[numOfHits] = {
            hits: numOfHits,
            exactChance: chance,
            atLeastChance: chance,
        };
    }
    chanceForHits.forEach((chance) => {
        if (chance.hits === 0) {
            return;
        }
        chanceForHits.forEach((otherChance) => {
            if (otherChance.hits > chance.hits) {
                chance.atLeastChance += otherChance.exactChance;
            }
        });
    });
    return chanceForHits;
}
function calculateChanceTable(attackDicePool, defenseDicePool, attackBonusSymbols, defenseBonusSymbols, hasBonus) {
    const dicePool = [...attackDicePool, ...defenseDicePool];
    const table = createProbabilityTable(attackDicePool, defenseDicePool);
    const summaryDiceResults = sumResults(dicePool, table);
    const hits = calculateHits(summaryDiceResults, attackBonusSymbols, defenseBonusSymbols, hasBonus);
    const chanceForHits = calculateProbability(hits);
    return {
        table,
        summaryDiceResults,
        hits,
        chanceForHits,
    };
}
export default calculateChanceTable;
