import { RequestType } from './types.js';
export const selectUnitForActivation = (unitId, userId) => {
    return {
        type: RequestType.SelectUnitForActivation,
        payload: {
            unitId,
            userId,
        },
    };
};
export const setStateName = (stateName, userId) => {
    return {
        type: RequestType.SetStateName,
        payload: {
            stateName,
            userId,
        },
    };
};
export const unselectUnitForActivation = (unitId, userId) => {
    return {
        type: RequestType.UnselectUnitForActivation,
        payload: {
            unitId,
            userId,
        },
    };
};
export const unselectAllUnitsForActivation = (userId) => {
    return {
        type: RequestType.UnselectAllUnitsForActivation,
        payload: {
            userId,
        },
    };
};
export const selectUnitForMovement = (unitId, userId) => {
    return {
        type: RequestType.SelectUnitForMovement,
        payload: {
            unitId,
            userId,
        },
    };
};
export const moveUnit = (unitId, position, userId) => {
    return {
        type: RequestType.MoveUnit,
        payload: {
            unitId,
            userId,
            position,
        },
    };
};
export const returnToPathLeg = (unitId, position, userId) => {
    return {
        type: RequestType.ReturnToPathLeg,
        payload: {
            unitId,
            userId,
            position,
        },
    };
};
export const finishMovementOfUnit = (unitId, userId) => {
    return {
        type: RequestType.FinishMovementOfUnit,
        payload: {
            unitId,
            userId,
        },
    };
};
export const stayAtOriginalPosition = (unitId, userId) => {
    return {
        type: RequestType.StayAtOriginalPosition,
        payload: {
            unitId,
            userId,
        },
    };
};
export const selectAttacker = (unitId, combatId, userId) => {
    return {
        type: RequestType.SelectAttacker,
        payload: {
            unitId,
            combatId,
            userId,
        },
    };
};
export const unselectAttacker = (userId) => {
    return {
        type: RequestType.UnselectAttacker,
        payload: {
            userId,
        },
    };
};
export const selectDefender = (unitId, userId) => {
    return {
        type: RequestType.SelectDefender,
        payload: {
            unitId,
            userId,
        },
    };
};
export const unselectDefender = (userId) => {
    return {
        type: RequestType.UnselectDefender,
        payload: {
            userId,
        },
    };
};
export const attack = (userId, diceRollResults) => {
    return {
        type: RequestType.Attack,
        payload: {
            userId,
            diceRollResults,
        },
    };
};
