import React from 'react';
import UnitCounter from '.';
import { createHexProperties } from '@wareena/game-algorithms';
import terrainMap from '../../terrain/index.js';
import { calculateCounterOrigin } from '../services/counter.js';
export default function UnitOnTerrainAsImage({ terrainId, unit, isFlatTop = false, hexSize = 50, position, graphicalVariant, isUnitBold = false, }) {
    const TerrainHex = terrainMap[terrainId];
    const hexProperties = createHexProperties({
        hexSize,
        isFlatTop,
        isFirstOffsetted: false,
    });
    const centerPos = hexProperties.calculateCenterPos(0, 0);
    const counterSize = hexProperties.isFlatTop
        ? hexProperties.height / 1.5
        : hexProperties.width / 1.5;
    const origin = calculateCounterOrigin(centerPos, counterSize);
    const hexCenter = hexProperties.calculateCenterPos(0, 0);
    const padding = 3;
    const hexWidth = hexProperties.width;
    const hexHeight = hexProperties.height;
    const imgWidth = hexWidth + 2 * padding;
    const imgHeight = hexHeight + 2 * padding;
    let hexId = undefined;
    if (position) {
        const colId = `${position.column}`.padStart(2, '0');
        const rowId = `${position.row}`.padStart(2, '0');
        hexId = `${colId}${rowId}`;
    }
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: imgWidth, height: imgHeight },
        React.createElement(TerrainHex, { center: {
                x: hexCenter.x + padding,
                y: hexCenter.y + padding,
            }, size: hexProperties.size, isFlatTop: hexProperties.isFlatTop, dimensions: {
                width: hexProperties.width,
                height: hexProperties.height,
            }, graphicalVariant: graphicalVariant, id: hexId }),
        React.createElement(UnitCounter, { counterSize: counterSize, unit: unit, origin: { x: origin.x + padding, y: origin.y + padding }, isBold: isUnitBold })));
}
