import { AttackResultType } from '../../../types.js';
import createRules from '../../../../rules/index.js';
import getCurrentCombat from './getCurrentCombat.js';
import runBattleRound from './runBattleRound.js';
function runBattle(state, game, hexProperties, diceRollResults) {
    const rules = createRules(game.hexes, hexProperties);
    const { currentCombat, attacker, defender } = collectCombatData(state);
    let isLastRound = false;
    const isBattleBackPossible = rules.isBattleBackPossible(attacker, defender, state);
    let nextState = state;
    const normalRoundResult = runBattleRound(attacker, defender, diceRollResults[0], AttackResultType.Normal, state, rules, currentCombat.id);
    nextState = normalRoundResult.nextState;
    isLastRound = normalRoundResult.isLastRound;
    if (isBattleBackPossible && !isLastRound) {
        const battleBackResult = runBattleRound(defender, attacker, diceRollResults[1], AttackResultType.BattleBack, nextState, rules, currentCombat.id, 'Missing battle back dice rolls');
        nextState = battleBackResult.nextState;
    }
    return nextState;
}
function collectCombatData(state) {
    const currentCombat = getCurrentCombat(state);
    const attacker = getUnitForCombat(currentCombat.attackerId, state, 'No attacker');
    const defender = getUnitForCombat(currentCombat.defenderId, state, 'No defender');
    return { currentCombat, attacker, defender };
}
function getUnitForCombat(unitId, state, errorMessage) {
    if (!unitId) {
        throw errorMessage;
    }
    const unit = state.units?.find((u) => u.id === unitId);
    if (!unit) {
        throw errorMessage;
    }
    return unit;
}
export default runBattle;
