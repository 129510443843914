import createPath from '@local/router/createPath';
import { RouteName } from '@local/router/routes';
import { TutorialPageId, getTutorialPages } from './tutorialRepository';

export function createPathToTutorial(pageNumber: number) {
    const tutorialPages = getTutorialPages();

    const page = tutorialPages[pageNumber - 1] ?? tutorialPages[0];
    const pageId = page?.id;

    return createPath(RouteName.Tutorial, { pageId });
}

export function createPathToTutorialById(pageId: TutorialPageId) {
    return createPath(RouteName.Tutorial, { pageId });
}
