import calcSymbolsFromRollResults from './calcSymbolsFromRollResults.js';
import getHits from './getHits.js';
import applyNewHealth from './applyNewHealth.js';
export default function resolveRound(diceRollResults, bonusSymbolsForAttacker, bonusSymbolsForDefender, state, rules) {
    const roundAttackerId = diceRollResults.attackerId;
    const roundDefenderId = diceRollResults.defenderId;
    const attackerForRound = state.units.find((u) => u.id === roundAttackerId);
    if (!attackerForRound) {
        throw 'attackerForRound should exist';
    }
    const defenderForRound = state.units.find((u) => u.id === roundDefenderId);
    if (!defenderForRound) {
        throw 'defenderForRound should exist';
    }
    const attackerSymbols = calcSymbolsFromRollResults(diceRollResults.attackerDiceResults);
    const defenderSymbols = calcSymbolsFromRollResults(diceRollResults.defenderDiceResults);
    bonusSymbolsForAttacker.forEach((symbol) => {
        attackerSymbols.set(symbol, (attackerSymbols.get(symbol) ?? 0) + 1);
    });
    bonusSymbolsForDefender.forEach((symbol) => {
        defenderSymbols.set(symbol, (defenderSymbols.get(symbol) ?? 0) + 1);
    });
    const resultForAttacker = resolveRoundForReceivingUnit(attackerForRound, defenderForRound, attackerSymbols, defenderSymbols, rules);
    const resultForDefender = resolveRoundForReceivingUnit(defenderForRound, attackerForRound, defenderSymbols, attackerSymbols, rules);
    const stats = {
        roundAttacker: {
            id: roundAttackerId,
            ...resultForAttacker,
        },
        roundDefender: {
            id: roundDefenderId,
            ...resultForDefender,
        },
    };
    let nextState = applyNewHealth(roundAttackerId, resultForAttacker.healthAfter, state);
    nextState = applyNewHealth(roundDefenderId, resultForDefender.healthAfter, nextState);
    return {
        state: nextState,
        isLastRound: resultForDefender.healthAfter === 0 || resultForAttacker.healthAfter === 0,
        stats,
    };
}
function resolveRoundForReceivingUnit(receivingUnit, dealingUnit, symbolsOfReceiving, symbolsOfDealing, rules) {
    const hitsAgainstReceiver = getHits(dealingUnit, receivingUnit, symbolsOfDealing, symbolsOfReceiving, rules);
    const newHealth = Math.max(receivingUnit.health - hitsAgainstReceiver, 0);
    return {
        receivedHits: hitsAgainstReceiver,
        healthBefore: receivingUnit.health,
        healthAfter: newHealth,
    };
}
