import { isUserActive } from '../utils/index.js';
export default function unselectAttacker({ request, state, stateMachine, game, }) {
    const { userId } = request.payload;
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
    if (!state.currentCombatId) {
        throw `There is no current combat`;
    }
    const currentCombatId = state.currentCombatId;
    const nextState = {
        ...state,
        combats: state.combats?.filter((combat) => combat.id !== currentCombatId) ?? [],
        currentCombatId: null,
    };
    return stateMachine.changeState(nextState, 'selectingAttacker', {});
}
