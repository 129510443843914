import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { getUnitTypes } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import UnitCounterAsImage from '@wareena/grid-battles-ui/game/unit/UnitCounterAsImage';
import ExampleMelee from './ExampleMelee';
import { AttackType } from '@wareena/grid-battles-entity/game/codebook/attackTypes';
import { getPageById } from '@local/tutorial/services/tutorialRepository';
import { createPathToTutorial } from '@local/tutorial/services/routing';
import Link from 'next/link';
import ExampleRanged from './ExampleRanged';

export default function MeleeAndRangedUnits() {
    const lineOfSightArticle = getPageById('line-of-sight')!;

    return (
        <>
            <div className="rules-text">
                <h2 className="title is-2">Melee and Ranged Units</h2>
                <p>
                    Different units have different capabilities when it comes to combat. One of the
                    most important property of a unit is the distance at which it can attack an
                    enemy.
                </p>

                <h3 className="title is-3">Melee Units</h3>
                <p>
                    Most units are equipped with weapons that can be used only on a very short
                    distance (swords, maces, pikes, axes...). We call such units{' '}
                    <strong>melee units</strong>.
                </p>
                <p>
                    In game terms, a very short (melee) distance means you need to become adjacent
                    to the enemy in order to attack. Below, you see the list of all melee units.
                </p>

                <table className="table is-striped is-narrow">
                    <thead>
                        <tr>
                            <th>Counter</th>
                            <th>Unit name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getUnitTypes()
                            .filter((unitType) => unitType.attackType === AttackType.Melee)
                            .map((unitType) => {
                                return (
                                    <tr key={unitType.id}>
                                        <td>
                                            <UnitCounterAsImage
                                                counterSize={60}
                                                unit={createUnit(unitType.id)}
                                            />
                                        </td>
                                        <td>{unitType.name}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>

                <p>
                    Try the example below to see how your activated melee units are able to attack
                    only adjacent enemies. <i>Pikeman 4</i> and <i>Pikeman</i> are too far for
                    anyone to be attacked. The rest of the enemy units can be attacked only by their
                    immediate &quot;neighbours&quot;.
                </p>

                <ExampleMelee />

                <h3 className="title is-3 mt-4">Ranged Units</h3>
                <p>
                    Units equipped with any type of missile weapon (bows, crossbows...) can attack
                    an enemy at a greater distance. These units are called{' '}
                    <strong>ranged units</strong>. The distance at which they are able to attack is
                    called <strong>range</strong>. In the table below you can find all ranged units
                    and their range.
                </p>

                <table className="table is-striped is-narrow">
                    <thead>
                        <tr>
                            <th>Counter</th>
                            <th>Unit name</th>
                            <th>Range</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getUnitTypes()
                            .filter((unitType) => unitType.attackType === AttackType.Ranged)
                            .sort((a, b) => a.range - b.range)
                            .map((unitType) => {
                                return (
                                    <tr key={unitType.id}>
                                        <td>
                                            <UnitCounterAsImage
                                                counterSize={60}
                                                unit={createUnit(unitType.id)}
                                            />
                                        </td>
                                        <td>{unitType.name}</td>
                                        <td>{unitType.range}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>

                <p>
                    Ranged units can attack enemies at a distance up to their range, but they must
                    have a clear view of the enemy. In simpler terms, they are not able to attack a
                    unit they do not see. We say that the unit must have a clear{' '}
                    <strong>Line of Sight (LOS)</strong> to the enemy.
                </p>

                <p>
                    The Line of Sight is blocked by another unit and some terrain types. You can
                    find more details in the article{' '}
                    <Link href={createPathToTutorial(lineOfSightArticle.numId)}>
                        {lineOfSightArticle.title}.
                    </Link>{' '}
                    Since rules for determining the Line of Sight can be complicated, the system is
                    trying to help you and highlights all the hexes that are in range and the Line
                    of Sight currently selected ranged unit.
                </p>

                <p>
                    In the example below you can see, that the system is showing you the hexes in
                    range and LOS even not only during the combat phase, but also while moving
                    units. All hexes your unit is able to attack are a little bit dimmed. Notice how
                    enemy units, friendly units and terrain block your view and that you are able to
                    see to and attack their hex, but not further.
                </p>

                <ExampleRanged />
            </div>
        </>
    );
}
