import { BattleBonusType, } from '@wareena/grid-battles-entity/game/codebook/battleBonus';
export default function collectBonusSymbols(bonuses, purpose) {
    const symbols = [];
    for (const bonus of bonuses) {
        if (bonus.purpose === purpose && bonus.type === BattleBonusType.CombatEffectSymbol) {
            symbols.push(bonus.symbol);
        }
    }
    return symbols;
}
