import { CombatEffectSymbol } from '@wareena/grid-battles-entity/game/codebook/dice';
export default function getHits(attacker, defender, attackerSymbols, defenderSymbols, rules) {
    const hits = attackerSymbols.get(CombatEffectSymbol.Damage) ?? 0;
    const shields = defenderSymbols.get(CombatEffectSymbol.Shield) ?? 0;
    let bonus = 0;
    if (rules.doesHaveAdvantageAgainst(attacker, defender)) {
        bonus = attackerSymbols.get(CombatEffectSymbol.Bonus) ?? 0;
    }
    return Math.max(hits + bonus - shields, 0);
}
