import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import createRules from '@wareena/grid-battles-engine/rules';
import { selectAttacker } from '@wareena/grid-battles-engine/gameStateMachine/request';
import { filterUnitsAbleToAttack, getActivatedUnits, getPossibleTargetsForUnits, hasUnitAlreadyAttacked, } from '@wareena/grid-battles-engine/gameStateMachine/helpers';
import EligibleUnitMarker from '../marker/EligibleUnitMarker.js';
import OpponentsTurnInstruction from './helper/OpponentsTurnInstruction.js';
const createComponents = ({ stateMachine, game, state, hexProperties, isLoggedInPlayerActive, loggedInPlayerId, }) => {
    const rules = createRules(game.hexes, hexProperties);
    const selectUnit = (unit) => {
        stateMachine.executeRequest(selectAttacker(unit.id, uuidv4(), loggedInPlayerId), state);
    };
    const activatedUnits = getActivatedUnits(state);
    const possibleTargets = getPossibleTargetsForUnits({
        units: activatedUnits,
        rules,
        hasUnitAlreadyAttacked: (unitId) => hasUnitAlreadyAttacked(unitId, state),
        allUnits: state.units,
    });
    const unitsAbleToAttack = filterUnitsAbleToAttack({
        possibleAttackers: activatedUnits,
        possibleTargets,
    });
    const ActiveLayerForPassivePlayer = () => {
        const hexes = [];
        unitsAbleToAttack.forEach((unit) => {
            hexes.push(React.createElement(EligibleUnitMarker, { key: `unitAbleToAttack-${unit.position.column}-${unit.position.row}`, position: unit.position, hexProperties: hexProperties }));
        });
        if (!hexes.length) {
            return null;
        }
        return React.createElement("g", null, hexes);
    };
    const ActiveLayer = () => {
        if (!isLoggedInPlayerActive) {
            return React.createElement(ActiveLayerForPassivePlayer, null);
        }
        const hexes = [];
        unitsAbleToAttack.forEach((unit) => {
            hexes.push(React.createElement(EligibleUnitMarker, { key: `ableToAtackActiveHex-${unit.position.column}-${unit.position.row}`, hexProperties: hexProperties, position: unit.position, onClick: () => selectUnit(unit) }));
        });
        if (!hexes.length) {
            return null;
        }
        return React.createElement("g", null, hexes);
    };
    const Instruction = () => {
        if (!isLoggedInPlayerActive) {
            return React.createElement(OpponentsTurnInstruction, null);
        }
        return React.createElement("p", null, "Select attacker");
    };
    return {
        ActiveLayer,
        Instruction,
    };
};
export default createComponents;
