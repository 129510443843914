import { BattleBonusPurpose, BattleBonusType, } from '@wareena/grid-battles-entity/game/codebook/battleBonus';
export default function collectBonusAttackDice(bonuses) {
    const dice = [];
    for (const bonus of bonuses) {
        if (bonus.purpose === BattleBonusPurpose.Attack && bonus.type === BattleBonusType.GameDie) {
            dice.push(bonus.gameDie);
        }
    }
    return dice;
}
