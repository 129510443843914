import { createHexProperties } from '@wareena/game-algorithms';
import {
    TerrainId,
    getTerrainName,
    isBlockingTerrain,
} from '@wareena/grid-battles-entity/game/map/terrain';
import terrainMap from '@wareena/grid-battles-ui/game/terrain/index';
import LosExample from './LosExample';
import InteractiveLosExample from './InteractiveLosExample';

export default function LineOfSightPage() {
    const hexProperties = createHexProperties({
        hexSize: 50,
        isFlatTop: false,
        isFirstOffsetted: false,
    });

    const hexCenter = hexProperties.calculateCenterPos(0, 0);

    const padding = 3;

    const terrainImgWidth = hexProperties.width + 2 * padding;
    const terrainImgHeight = hexProperties.height + 2 * padding;

    return (
        <>
            <div className="rules-text mb-4">
                <h2 className="title is-2">Line of Sight</h2>

                <p>
                    Ranged units are able to attack enemy units at a longer distance, but this
                    ability is limited by two factors. The first one is their{' '}
                    <strong>range,</strong> which is a number determining how far their missiles can
                    fly. The number is measured in hexes (a hex is a hexagonal tile on a map). So
                    range 4 hexes means the unit can attack an enemy 4 hexes away.
                </p>
                <p>
                    The other factor is Line of Sight, or <strong>LOS</strong> in its abbreviated
                    form. This factor determines if an attacker has a clear view of an enemy. This
                    is crucial because the unit is not able to attack an enemy it is not able to
                    see.
                </p>

                <h3 className="title is-3">Checking the Line of Sight</h3>

                <p>
                    To determine if your unit is able to see the enemy, you draw an imaginary line
                    from the center of your unit&apos;s hex to the center of the enemy hex. If this
                    line crosses no obstacles (other units and blocking terrain) the LOS is clear.
                    If the line goes along an edge (or a corner) of a blocking hex, then the view is
                    clear if one side of it is not blocking. See the image below for an example.
                </p>

                <LosExample />

                <p className="mt-4">In the example above:</p>

                <ul className="list has-disc">
                    <li>
                        <i>Archer 1</i> has clear LOS on <i>Pikeman</i> because it is not blocked by
                        anything.
                    </li>
                    <li>
                        <i>Archer 2</i> can see and attack <i>Swordman</i> even if he is on a Hill
                        terrain (which is one of the blocking terrains) because LOS calculation
                        ignores the terrain of starting and target hexes. LOS is clear.
                    </li>
                    <li>
                        <i>Archer 3</i> is unable to see <i>Axeman</i> because there is a wood
                        (another one of blocking terrains) between them. So LOS is blocked.
                    </li>
                    <li>
                        <i>Archer 3</i> is also unable to see and attack <i>Maceman.</i> Because
                        while it visually looks like there is no obstacle between them, LOS
                        calculation treats the whole hex <i>0201</i> to contain woods and the whole
                        hex
                        <i>0302</i> to be a hill. LOS is therefore blocked. You should always
                        consider the actual graphics only as an aesthetics and treat hexes to be
                        fully occupied by its main terrain feature.
                    </li>
                    <li>
                        <i>Archer 4</i> has clear LOS on <i>Light Cav.</i> When the LOS line runs
                        along the edge (or a corner) of a hex, you must consider both sides of it.
                        You can imagine it like drawing two other lines, one slightly to the right
                        of the original one and another slightly to the left. If at least one of
                        them crosses no blocking hex, then the LOS is clear. In this example, while
                        hex <i>0302</i> is blocking, the <i>0303</i> is not. Therefore the LOS is
                        considered clear.
                    </li>
                    <li>
                        However, <i>Archer 4</i> is unable to clearly see <i>Mdm Cav</i>. While
                        there is no blocking terrain between them, there is another unit on hex{' '}
                        <i>0402</i> (<i>Light Cav</i>) and since other units are also blocking the
                        view, the LOS is considered blocked.
                    </li>
                    <li>
                        Finally, <i>Archer 5</i> has no LOS on <i>Knight</i>. The line runs along
                        the edges, but on both sides of them are some obstacles blocking the view.
                    </li>
                </ul>

                <h3 className="title is-3 mt-4">List of Blocking Terrains</h3>

                <p>
                    Some terrains block LOS and some do not. You can see a list of all blocking
                    terrain types in the table below.
                </p>

                <table className="table is-striped is-narrow">
                    <thead>
                        <tr>
                            <th> </th>
                            <th>Terrain</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(terrainMap)
                            .filter((idx) => {
                                const terrainId: TerrainId = +idx;

                                return isBlockingTerrain(terrainId);
                            })
                            .map((idx) => {
                                const terrainId: TerrainId = +idx;

                                const TerrainHex = terrainMap[terrainId];

                                return (
                                    <tr key={terrainId}>
                                        <td>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={terrainImgWidth}
                                                height={terrainImgHeight}
                                            >
                                                <TerrainHex
                                                    isFlatTop={false}
                                                    size={hexProperties.size}
                                                    center={{
                                                        x: hexCenter.x + padding,
                                                        y: hexCenter.y + padding,
                                                    }}
                                                    dimensions={{
                                                        width: hexProperties.width,
                                                        height: hexProperties.height,
                                                    }}
                                                />
                                            </svg>
                                        </td>
                                        <td>{getTerrainName(terrainId)}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>

                <h3 className="title is-3 mt-4">Interactive Example</h3>
                <p>
                    In the interactive example below you can experiment and observe how the LOS and
                    unit&apos;s field of view (dimmed hexes) changes when there is a blocking
                    terrain or units in the way.
                </p>
                <p>
                    Above the map, you can see four &quot;buttons&quot; which allow you to modify
                    the situation. Each button will allow you to change the situation in a different
                    way:
                </p>
                <ul className="list has-disc">
                    <li>
                        Wood hex button: you can place or remove wood hexes on the map. Just click
                        on the map to place a wood there (or remove one already in the position)
                    </li>
                    <li>Hill hex button: Allows you to do the same but with hills.</li>
                    <li>
                        Orange Swordman button: Similar to hex buttons, this one will allow you to
                        place or remove enemy units on the map.
                    </li>
                    <li>
                        Blue Archer button: this one works a little differently, it allows you to
                        move the archer to a different position
                    </li>
                </ul>
                <p className="mt-4">
                    For every enemy unit present on the map we draw a line between this unit and the
                    archer and the color of this line represents the LOS state.
                </p>
                <ul className="list has-disc">
                    <li>The red line means the archer is unable to see the unit</li>
                    <li>
                        The yellow line means the archer is able to see the unit, but it is out of
                        its range. So the unit can not be attacked.
                    </li>
                    <li>
                        The green line represents both the clear LOS and that the target is in
                        Archer&apos;s range.
                    </li>
                </ul>
                <p className="mt-4">Play with the example and try different situations.</p>
                <InteractiveLosExample />
            </div>
        </>
    );
}
