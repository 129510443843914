export const hasUnitAlreadyAttacked = (unitId, state) => {
    const combat = state.combats?.find((c) => c.attackerId === unitId && c.round === state.round);
    if (combat?.wasResolved) {
        return true;
    }
    return false;
};
export const getActivatedUnits = (state) => {
    const activatedUnits = [];
    state.unitsSelectedForActivation.forEach((unitId) => {
        const unit = state.units.find((u) => u.id === unitId);
        if (unit) {
            activatedUnits.push(unit);
        }
    });
    return activatedUnits;
};
export const getPossibleTargetsForUnits = ({ units, rules, hasUnitAlreadyAttacked, allUnits, }) => {
    const possibleTargets = {
        count: 0,
        forUnit: {},
    };
    units.forEach((unit) => {
        possibleTargets.forUnit[unit.id] = [];
        if (hasUnitAlreadyAttacked(unit.id)) {
            return;
        }
        const targets = rules.getPossibleTargets(unit, allUnits);
        if (!targets?.length) {
            return;
        }
        possibleTargets.count += targets.length;
        possibleTargets.forUnit[unit.id] = targets;
    });
    return possibleTargets;
};
export const filterUnitsAbleToAttack = ({ possibleAttackers, possibleTargets, }) => {
    const unitsAbleToAttack = [];
    possibleAttackers.forEach((unit) => {
        const targets = possibleTargets.forUnit[unit.id];
        if (!targets?.length) {
            return;
        }
        unitsAbleToAttack.push(unit);
    });
    return unitsAbleToAttack;
};
