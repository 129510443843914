import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
export default function removeUnit(unitId, state) {
    let unitSideId = null;
    let victoryPoints = { ...state.victoryPoints };
    const nextUnits = [];
    state.units.forEach((unit) => {
        if (unit.id === unitId) {
            unitSideId = unit.side;
        }
        else {
            nextUnits.push(unit);
        }
    });
    if (unitSideId !== null) {
        const otherSideId = unitSideId === SideId.A ? SideId.B : SideId.A;
        victoryPoints = state.victoryPoints.map((vp) => {
            if (vp.side === otherSideId) {
                return {
                    ...vp,
                    points: vp.points + 1,
                };
            }
            return vp;
        });
    }
    return {
        ...state,
        victoryPoints,
        units: nextUnits,
    };
}
