import { PropsWithChildren, SVGProps, useState } from 'react';
import GameMap from '@wareena/ui/game/map';
import { getTerrainHex } from '@wareena/grid-battles-ui/game/terrain/index';
import { calcMapDimensions, createHexProperties } from '@wareena/game-algorithms';
import UnitLayer from '@wareena/grid-battles-ui/game/map/UnitLayer';
import {
    AttackResult,
    Game,
    GameState,
    Scenario,
} from '@wareena/grid-battles-engine/gameStateMachine/types';
import createStateComponents from '@wareena/grid-battles-ui/game/stateComponents/index';
import { ScenarioHexWithId } from '@wareena/grid-battles-entity/scenario/entity/scenario';
import createStateMachine, {
    RequestSuccessListenerParams,
} from '@wareena/grid-battles-engine/gameStateMachine/index';
import createRules from '@wareena/grid-battles-engine/rules/index';
import getDiceRollResults from '@wareena/grid-battles-engine/gameStateMachine/helpers/battle/getDiceRollResults';
import { attack } from '@wareena/grid-battles-engine/gameStateMachine/request/index';
import { getStateWithUpdatedPosition } from '@wareena/grid-battles-engine/services/gameStateUtils';
// import ResponsiveSvg from '@wareena/ui/game/map/ResponsiveSvg';
import CombatActionPanel from '@wareena/grid-battles-ui/game/CombatActionPanel/index';

interface TutorialGameMapProps {
    scenario: Scenario & {
        hexSize: number;
        isFlatTop: boolean;
        isFirstOffsetted: boolean;
        hexes: ScenarioHexWithId[][];
    };
    game: Game;
    state: GameState;
    checkEndOfAnExample?: (newState: GameState, prevState: GameState) => boolean;
}

export default function TutorialGameMap({
    scenario,
    state,
    game,
    checkEndOfAnExample = () => false,
    children,
}: PropsWithChildren<TutorialGameMapProps>) {
    const [gameState, setGameState] = useState(structuredClone(state));
    const [isExampleAtTheEnd, setIsExampleAtTheEnd] = useState(false);
    const [combatDetail, setCombatDetail] = useState<{
        isVisible: boolean;
        combatId: string | null;
    }>({ isVisible: false, combatId: null });

    const loggedInPlayerId = 1;

    const hexProperties = createHexProperties({
        hexSize: 50,
        isFlatTop: scenario.isFlatTop,
        isFirstOffsetted: scenario.isFirstOffsetted,
    });

    const mapDimensions = calcMapDimensions(scenario.width, scenario.height, hexProperties);

    const stateMachine = createStateMachine({
        game,
        scenario,
        hexProperties,
        onRequestSuccess: ({ nextState, previousState }: RequestSuccessListenerParams) => {
            setGameState(nextState);

            if (checkEndOfAnExample(nextState, previousState)) {
                setIsExampleAtTheEnd(true);
            }
        },
    });

    const rules = createRules(game.hexes, hexProperties);

    const { ActiveLayer, Instruction, ActionPanel, getUnitReplacement } = createStateComponents({
        stateMachine,
        game,
        scenario,
        state: gameState,
        loggedInPlayerId,
        hexProperties,
    });

    function handleReset() {
        setGameState(structuredClone(state));
        setIsExampleAtTheEnd(false);
    }

    function handleShowCombatDetail() {
        const currentCombat = gameState.combats?.find((c) => c.id === gameState.currentCombatId);

        if (!currentCombat || !currentCombat.attackerId || !currentCombat.defenderId) {
            return;
        }

        const diceRollResults: AttackResult[] | null = getDiceRollResults(gameState, rules);

        if (!diceRollResults) {
            return;
        }

        setCombatDetail((prevDetail) => {
            return {
                ...prevDetail,
                isVisible: true,
                combatId: gameState.currentCombatId!,
            };
        });

        stateMachine.executeRequest(attack(loggedInPlayerId, diceRollResults), gameState);
    }

    function getCombat(combatId: string) {
        return gameState?.combats?.find((c) => c.id === combatId);
    }

    function hideCombatDetail() {
        setCombatDetail((prevDetail) => {
            return {
                ...prevDetail,
                isVisible: false,
            };
        });
    }

    const stateForBoldCalculation =
        gameState.stateName === 'movingUnit' ? getStateWithUpdatedPosition(gameState) : gameState;

    return (
        <div className="tutorial-game-screen">
            <div className="has-background-grey p-1 mb-2 is-flex is-align-items-center is-justify-content-space-between">
                <div className="instructions has-text-white">
                    <p>Instructions:</p>
                    {isExampleAtTheEnd ? (
                        <p>🎉 This example is successfully at its end 🎉</p>
                    ) : (
                        <Instruction />
                    )}
                </div>
                <button
                    className={`button ${isExampleAtTheEnd ? 'is-danger' : 'is-light'} is-small`}
                    onClick={handleReset}
                >
                    Reset this example
                </button>
            </div>
            {ActionPanel ? (
                <div className="tutorial-action-panel">
                    <ActionPanel onShowCombatDetail={handleShowCombatDetail} />
                </div>
            ) : null}
            {combatDetail.isVisible ? (
                <div className="tutorial-action-panel">
                    <CombatActionPanel
                        combat={getCombat(combatDetail.combatId as string)!}
                        rules={rules}
                        gameState={gameState}
                        scenario={scenario}
                        hexes={game.hexes}
                        onClose={() => hideCombatDetail()}
                    />
                </div>
            ) : null}
            <ResponsiveSvg
                width={mapDimensions.width}
                height={mapDimensions.height}
                maxWidth={mapDimensions.width}
                svgProps={{
                    style: isExampleAtTheEnd
                        ? {
                              filter: 'grayscale(80%)',
                          }
                        : {},
                }}
            >
                <GameMap
                    hexProperties={hexProperties}
                    map={scenario.hexes}
                    getTerrainHex={getTerrainHex}
                />
                <UnitLayer
                    hexProperties={hexProperties}
                    units={gameState.units}
                    getUnitReplacement={getUnitReplacement}
                    getBoldStatus={(unit) => rules.isUnitBold(unit, stateForBoldCalculation)}
                />
                <ActiveLayer onShowCombatDetail={handleShowCombatDetail} />
                {isExampleAtTheEnd ? (
                    <rect
                        x={0}
                        y={0}
                        width={mapDimensions.width}
                        height={mapDimensions.height}
                        fill="transparent"
                    />
                ) : null}
                {children}
            </ResponsiveSvg>
        </div>
    );
}

function ResponsiveSvg({
    children,
    width,
    height,
    maxWidth,
    svgProps = {},
}: PropsWithChildren<{
    width: number;
    height: number;
    maxWidth?: number;
    svgProps?: SVGProps<SVGSVGElement>;
}>) {
    const props: SVGProps<SVGSVGElement> = {
        ...svgProps,
    };

    if (maxWidth) {
        props.style = props.style ?? {};
        props.style.maxWidth = maxWidth;
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox={`0 0 ${width} ${height}`}
            {...props}
        >
            {children}
        </svg>
    );
}
