import React from 'react';
export default function ActionPanelContainer({ children }) {
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', alignItems: 'center' } }, children));
}
export function ActionPanelText({ children }) {
    return React.createElement("p", { className: "is-size-3 text-align-center" }, children);
}
export function ActionPanelPreview({ children, width }) {
    if (width !== undefined) {
        return React.createElement("div", { style: { width } }, children);
    }
    return React.createElement("div", null, children);
}
export function ActionPanelButtons({ children }) {
    return React.createElement("div", { className: "action-panel-buttons buttons mt-2" }, children);
}
