import React from 'react';
import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
function GameStats({ game, playerA, playerB, scenario }) {
    const getVictoryPoints = (side) => {
        return game.state.victoryPoints.find((v) => v.side === side)?.points ?? 0;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "game-stats-scenario-name" },
            React.createElement("h1", null,
                React.createElement("span", { className: "icon-text" },
                    React.createElement("span", { className: "icon" },
                        React.createElement("i", { className: "fas fa-map" })),
                    React.createElement("span", null, scenario.name)))),
        React.createElement("div", { className: "game-stats-turn" },
            React.createElement("span", { className: "icon-text is-medium" },
                React.createElement("span", { className: "icon" },
                    React.createElement("i", { className: `fas fa-flag ${game.state.activeSide === playerA.side
                            ? 'has-color-player-a'
                            : 'has-color-player-b'}` })),
                React.createElement("span", null, game.state.activeSide === SideId.A ? 'Your turn' : "Computer's turn"))),
        React.createElement("div", { className: "game-stats-score", title: "Score" },
            React.createElement("div", { className: "score has-background-color-player-a" }, getVictoryPoints(playerA.side)),
            "\u00A0:\u00A0",
            React.createElement("div", { className: "score has-background-color-player-b" }, getVictoryPoints(playerB.side))),
        React.createElement("div", { className: "game-stats-victory" },
            React.createElement("span", { className: "icon-text", title: `You need to destroy ${scenario.victoryConditions} enemy units` },
                React.createElement("span", { className: "icon" },
                    React.createElement("i", { className: "fas fa-trophy" })),
                React.createElement("span", null, scenario.victoryConditions))),
        React.createElement("div", { className: "game-stats-round" },
            React.createElement("span", { className: "icon-text", title: "Round" },
                React.createElement("span", { className: "icon" },
                    React.createElement("i", { className: `fas fa-arrows-rotate ${scenario.startingSide === SideId.A
                            ? 'has-color-player-a'
                            : 'has-color-player-b'}` })),
                React.createElement("span", null, game.state.round)))));
}
export default GameStats;
