import { getRandomInteger } from '@wareena/game-algorithms';
export default function rollDice(dice) {
    const rollResults = [];
    dice.forEach((battleDie) => {
        rollResults.push({
            die: battleDie,
            roll: getRandomInteger(0, 5),
        });
    });
    return rollResults;
}
