import { HexProperties, Position } from '@wareena/game-algorithms';

function LosLine({
    start,
    stop,
    hexProperties,
    color,
}: {
    start: Position;
    stop: Position;
    hexProperties: HexProperties;
    color: string;
}) {
    const line = {
        start: hexProperties.calculateCenterPos(start.column, start.row),
        stop: hexProperties.calculateCenterPos(stop.column, stop.row),
    };

    return (
        <line
            x1={line.start.x}
            y1={line.start.y}
            x2={line.stop.x}
            y2={line.stop.y}
            stroke={color}
            strokeWidth={5}
        />
    );
}

export function createClearLosLine(hexProperties: HexProperties) {
    return function ClearLosLine({ start, stop }: { start: Position; stop: Position }) {
        return (
            <LosLine
                hexProperties={hexProperties}
                color="rgba(0, 255, 0, 0.5)"
                start={start}
                stop={stop}
            />
        );
    };
}

export function createBlockedLosLine(hexProperties: HexProperties) {
    return function BlockedLosLine({ start, stop }: { start: Position; stop: Position }) {
        return (
            <LosLine
                hexProperties={hexProperties}
                color="rgba(255, 0, 0, 0.5)"
                start={start}
                stop={stop}
            />
        );
    };
}

export function createOutOfRangeLosLine(hexProperties: HexProperties) {
    return function BlockedLosLine({ start, stop }: { start: Position; stop: Position }) {
        return (
            <LosLine
                hexProperties={hexProperties}
                color="rgba(255, 255, 0, 0.5)"
                start={start}
                stop={stop}
            />
        );
    };
}

export default LosLine;
