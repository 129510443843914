import createRules from '../../../rules/index.js';
import { RequestType } from '../../request/types.js';
import { endTurn } from '../common/index.js';
import { isUserActive } from '../utils/index.js';
const factory = ({ game, scenario, hexProperties, }) => {
    const onEnter = ({ state, stateMachine }) => {
        const rules = createRules(game.hexes, hexProperties);
        let canContinue = false;
        for (let i = 0; i < state.unitsSelectedForActivation.length; i++) {
            const unitId = state.unitsSelectedForActivation[i];
            const unit = state.units.find((u) => u.id === unitId);
            if (!unit) {
                continue;
            }
            if (hasAttackedAlready(unitId, state)) {
                continue;
            }
            const targets = rules.getPossibleTargets(unit, state.units);
            if (targets.length) {
                canContinue = true;
                break;
            }
        }
        if (!canContinue) {
            return endTurn({
                state,
                scenario,
                stateMachine,
            });
        }
        return state;
    };
    const hasAttackedAlready = (unitId, state) => {
        const combat = state.combats?.find((c) => {
            if (c.attackerId === unitId && c.round === state.round) {
                return true;
            }
            return false;
        });
        return combat && combat.wasResolved;
    };
    const onLeave = ({ state }) => state;
    const executeRequest = ({ request, state, stateMachine }) => {
        switch (request.type) {
            case RequestType.SelectAttacker:
                return selectAttacker({
                    request: request,
                    state,
                    stateMachine,
                });
        }
        return state;
    };
    const selectAttacker = ({ request, state, stateMachine, }) => {
        const { unitId, combatId, userId } = request.payload;
        if (!isUserActive(userId, game.players, state.activeSide)) {
            throw `User ${userId} is not active`;
        }
        const unit = state.units.find((u) => u.id === unitId);
        if (!unit) {
            throw `Can not find unit with id ${unitId}`;
        }
        if (unit.side !== state.activeSide) {
            throw `Unit ${unit.label} does not belong to active side`;
        }
        if (unit.isFatigued) {
            throw `Unit ${unit.label} is fatigued`;
        }
        if (!state.unitsSelectedForActivation.includes(unit.id)) {
            throw `Unit ${unit.label} is not activated`;
        }
        const rules = createRules(game.hexes, hexProperties);
        const targets = rules.getPossibleTargets(unit, state.units);
        if (!targets?.length) {
            throw `Unit ${unit.label} has no valid targets`;
        }
        const combat = state.combats?.find((c) => {
            if (c.attackerId === unit.id && c.round === state.round) {
                return true;
            }
            return false;
        });
        let combats = [];
        const attacker = JSON.parse(JSON.stringify(unit));
        if (combat) {
            if (combat.wasResolved) {
                throw `Unit ${unit.label} has attacked already`;
            }
            combats =
                state.combats?.map((c) => {
                    if (c.id !== combat.id) {
                        return c;
                    }
                    return {
                        ...combat,
                        id: combatId,
                        attacker,
                        isAttackerBold: rules.isUnitBold(attacker, state),
                        defenderId: null,
                        defender: null,
                    };
                }) ?? [];
        }
        else {
            combats = [
                ...(state.combats ?? []),
                {
                    id: combatId,
                    round: state.round,
                    attackerId: unit.id,
                    attacker,
                    isAttackerBold: rules.isUnitBold(attacker, state),
                    defenderId: null,
                    defender: null,
                    wasResolved: false,
                },
            ];
        }
        const nextState = {
            ...state,
            currentCombatId: combatId,
            combats,
        };
        return stateMachine.changeState(nextState, 'selectingDefender', {});
    };
    return {
        onEnter,
        onLeave,
        executeRequest,
    };
};
export default factory;
