import React from 'react';
import HexOnPosition from '@wareena/ui/game/map/HexOnPosition';
function FieldOfView({ hexProperties, gameState, rules, unit, }) {
    const positionsInFieldOfView = rules.getFieldOfView(unit, gameState);
    if (!positionsInFieldOfView.length) {
        return null;
    }
    return (React.createElement("g", null, positionsInFieldOfView.map((position) => (React.createElement(HexOnPosition, { key: `fov-${position.column}-${position.row}`, position: position, hexProperties: hexProperties, style: {
            fill: 'black',
            fillOpacity: 0.2,
            strokeWidth: 0,
        } })))));
}
export default FieldOfView;
