export default function assertCorrectDiceWereThrown(rolls, dicePool) {
    const diceCounts = new Map();
    dicePool.forEach((battleDieId) => {
        if (diceCounts.get(battleDieId) === undefined) {
            diceCounts.set(battleDieId, 0);
        }
        diceCounts.set(battleDieId, diceCounts.get(battleDieId) + 1);
    });
    rolls.forEach((dieRoll) => {
        const battleDieId = dieRoll.die;
        const roll = dieRoll.roll;
        if (roll < 0 || roll > 5) {
            throw 'Roll must be between 0 and 5';
        }
        if (roll % 1) {
            throw 'Roll must be an integer';
        }
        if (diceCounts.get(battleDieId) === undefined) {
            throw `Invalid battle die ${battleDieId}`;
        }
        diceCounts.set(battleDieId, diceCounts.get(battleDieId) - 1);
    });
    diceCounts.forEach((count, battleDieId) => {
        if (count !== 0) {
            throw `Invalid number of dice [${count > 0 ? `${count} less` : `${-1 * count} more`}] were thrown for a battle die ${battleDieId}`;
        }
    });
    return true;
}
