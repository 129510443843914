import { isUserActive } from '../utils/index.js';
const canSelectMoreUnits = (state, scenario) => {
    const limit = scenario.activationsPerTurn;
    const currentlySelected = state.unitsSelectedForActivation?.length ?? 0;
    return currentlySelected < limit;
};
const assertCanSelectUnitForActivation = (userId, unitId, game, scenario, state) => {
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw 'User is not active';
    }
    if (!canSelectMoreUnits(state, scenario)) {
        throw `Can not select more than ${scenario.activationsPerTurn} units`;
    }
    const unit = state.units.find((u) => u.id === unitId);
    if (!unit) {
        throw `Can not find unit with id ${unitId}`;
    }
    if (unit.side !== state.activeSide) {
        throw `Unit ${unit.label} does not belong to active side`;
    }
    if (unit.isFatigued) {
        throw `Unit ${unit.label} is fatigued`;
    }
    if (state.unitsSelectedForActivation.find((uid) => uid === unitId)) {
        throw `Unit ${unit.label} is already selected ${unitId}`;
    }
};
const selectUnitForActivation = (request, game, scenario, state) => {
    const { unitId, userId } = request.payload;
    assertCanSelectUnitForActivation(userId, unitId, game, scenario, state);
    return {
        ...state,
        unitsSelectedForActivation: [...state.unitsSelectedForActivation, unitId],
    };
};
export default selectUnitForActivation;
