export function getStateWithUpdatedPosition(state) {
    if (state.stateName !== 'movingUnit') {
        return state;
    }
    if (!state.movingUnits?.currentlyMovingId) {
        return state;
    }
    const movingUnitId = state.movingUnits.currentlyMovingId;
    const movementData = state.movingUnits.units.find((u) => u.id === movingUnitId);
    if (!movementData?.path || movementData.path.length === 0) {
        return state;
    }
    const possibleNewPosition = movementData.path[movementData.path.length - 1].to;
    return {
        ...state,
        units: state.units.map((u) => {
            if (u.id === movingUnitId) {
                return {
                    ...u,
                    position: possibleNewPosition,
                };
            }
            return u;
        }),
    };
}
