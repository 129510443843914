import React from 'react';
import Hex from '@wareena/ui/game/map/Hex';
import { selectUnitForMovement } from '@wareena/grid-battles-engine/gameStateMachine/request';
import OpponentsTurnInstruction from './helper/OpponentsTurnInstruction.js';
const ActiveUnit = ({ unit, hexProperties, onClick = null, isLoggedInPlayerActive = false, }) => {
    const center = hexProperties.calculateCenterPos(unit.position.column, unit.position.row);
    const style = {
        fill: 'rgba(0, 0, 0, 0)',
        stroke: 'gold',
        strokeWidth: '3px',
    };
    if (isLoggedInPlayerActive) {
        style.cursor = 'pointer';
    }
    return (React.createElement(Hex, { center: center, size: hexProperties.size, key: `activeUnitHex-${unit.position.column}-${unit.position.row}`, isFlatTop: hexProperties.isFlatTop, dimensions: {
            width: hexProperties.width,
            height: hexProperties.height,
        }, style: style, onClick: () => {
            onClick?.(unit);
        } }));
};
const createComponents = ({ stateMachine, state, hexProperties, isLoggedInPlayerActive, loggedInPlayerId, }) => {
    const selectUnit = (unit) => {
        stateMachine.executeRequest(selectUnitForMovement(unit.id, loggedInPlayerId), state);
    };
    const hasUnitAlreadyMoved = (unitId) => {
        if (state.movingUnits && state.movingUnits.units) {
            const movementData = state.movingUnits.units.find((d) => d.id === unitId);
            if (movementData?.finishedMovement) {
                return true;
            }
        }
        return false;
    };
    const ActiveLayerForPassivePlayer = () => {
        const hexes = [];
        state.unitsSelectedForActivation.forEach((unitId) => {
            const unit = state.units.find((u) => u.id === unitId);
            if (!unit) {
                return;
            }
            hexes.push(React.createElement(ActiveUnit, { key: `activeHexForPasivePlayer-${unit.position.column}-${unit.position.row}`, unit: unit, hexProperties: hexProperties, isLoggedInPlayerActive: false }));
        });
        if (!hexes.length) {
            return null;
        }
        return React.createElement("g", null, hexes);
    };
    const ActiveLayer = () => {
        if (!isLoggedInPlayerActive) {
            return React.createElement(ActiveLayerForPassivePlayer, null);
        }
        const hexes = [];
        state.unitsSelectedForActivation.forEach((unitId) => {
            const unit = state.units.find((u) => u.id === unitId);
            if (!unit) {
                return;
            }
            if (hasUnitAlreadyMoved(unit.id)) {
                return;
            }
            hexes.push(React.createElement(ActiveUnit, { key: `selectedForActivationActiveHex-${unit.position.column}-${unit.position.row}`, unit: unit, hexProperties: hexProperties, isLoggedInPlayerActive: isLoggedInPlayerActive, onClick: selectUnit }));
        });
        if (!hexes.length) {
            return null;
        }
        return React.createElement("g", null, hexes);
    };
    const Instruction = () => {
        if (!isLoggedInPlayerActive) {
            return React.createElement(OpponentsTurnInstruction, null);
        }
        return React.createElement("p", null, "Select 1 unit to move");
    };
    return {
        ActiveLayer,
        Instruction,
    };
};
export default createComponents;
