import React from 'react';
import Hex from './Hex.js';
function ActiveLayer({ map = [], hexProperties, onClick, keyPrefix = 'activeHex', }) {
    const hexes = [];
    for (let colIdx = 0; colIdx < map.length; colIdx++) {
        for (let rowIdx = 0; rowIdx < map[colIdx].length; rowIdx++) {
            const { row, column } = map[colIdx][rowIdx];
            const center = hexProperties.calculateCenterPos(colIdx, rowIdx);
            const colId = `${column}`.padStart(2, '0');
            const rowId = `${row}`.padStart(2, '0');
            const hexId = `${colId}${rowId}`;
            hexes.push(React.createElement(Hex, { center: center, size: hexProperties.size, key: `${keyPrefix}-${column}-${row}`, isFlatTop: hexProperties.isFlatTop, dimensions: {
                    width: hexProperties.width,
                    height: hexProperties.height,
                }, style: {
                    cursor: 'pointer',
                    fill: 'rgba(0, 0, 0, 0)',
                }, onClick: () => onClick({ column: colIdx, row: rowIdx }), "data-hex-id": hexId }));
        }
    }
    return React.createElement("g", null, hexes);
}
export default ActiveLayer;
