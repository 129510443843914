import { createPathToTutorial } from '@local/tutorial/services/routing';
import { getPageById } from '@local/tutorial/services/tutorialRepository';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import HealthSymbol from '@wareena/grid-battles-ui/game/unit/UnitCounter/HealthSymbol';
import UnitCounter from '@wareena/grid-battles-ui/game/unit/UnitCounter/index';
import Link from 'next/link';

export default function HealthPage() {
    const dicePage = getPageById('dice')!;

    return (
        <>
            <div className="rules-text mb-4">
                <h2 className="title is-2">Health</h2>

                <p>
                    Each unit on a map consists of multiple men and they have different types of
                    armour and other type of protection. All this affects how long is the unit able
                    to withstand losses before it becomes so ineffective that we can say it was
                    destroyed (its men either fled from the combat, were wounded or killed).
                </p>

                <p>
                    We decided to represent this &quot;durability&quot; of a unit by a single number
                    called <strong>health</strong>. The higher the health, the bigger number of men
                    in a unit and/or better armor.
                </p>

                <svg xmlns="http://www.w3.org/2000/svg" width={125} height={63}>
                    <UnitCounter
                        counterSize={60}
                        origin={{ x: 5, y: 0 }}
                        unit={createUnit(UnitTypeId.LightCavalry)}
                    />
                    <HealthSymbol healthPoints={3} position={{ x: 90, y: 33 }} size={25} />
                    <circle
                        cx={13}
                        cy={50}
                        r={10}
                        fill="transparent"
                        stroke="red"
                        strokeWidth={2}
                    />
                    <line x1={13} y1={40} x2={100} y2={20} stroke="red" strokeWidth={2} />
                    <line x1={13} y1={60} x2={102} y2={60} stroke="red" strokeWidth={2} />
                    <circle
                        cx={102}
                        cy={40}
                        r={20}
                        fill="transparent"
                        stroke="red"
                        strokeWidth={2}
                    />
                </svg>

                <h3 className="title is-3 mt-4">Health and Hits</h3>

                <p>
                    Each scored hit against a unit means the loss of one of its health points. When
                    the unit&apos;s health points reach zero, the number of losses is too great, the
                    rest of its men flee the combat and the unit ceases to exist. Every lost unit
                    counts towards the enemy&apos;s victory points.
                </p>

                <p>
                    Hits are scored by evaluating the results of a dice thrown. You can read about
                    that on its{' '}
                    <Link href={createPathToTutorial(dicePage?.numId)}>own tutorial page.</Link>
                </p>
            </div>
        </>
    );
}
