'use client';

import { PropsWithChildren, useState } from 'react';
import createMap, {
    setTerrainToPositions,
} from '@wareena/grid-battles-engine/services/map/createMap';
import GameMap from '@wareena/ui/game/map';
import terrainMap, { getTerrainHex } from '@wareena/grid-battles-ui/game/terrain/index';
import {
    Position,
    calcMapDimensions,
    createHexProperties,
    isSamePosition,
} from '@wareena/game-algorithms';
import convertMapToScenarioFormat from '@wareena/grid-battles-engine/services/map/convertMapToScenarioFormat';
import { TerrainId } from '@wareena/grid-battles-entity/game/map/terrain';
import UnitLayer from '@wareena/grid-battles-ui/game/map/UnitLayer';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
import { createBlockedLosLine, createClearLosLine, createOutOfRangeLosLine } from './LosLine';
import ActiveLayer from '@wareena/ui/game/map/ActiveLayer';
import { GameState, Unit } from '@wareena/grid-battles-engine/gameStateMachine/types';
import UnitCounter from '@wareena/grid-battles-ui/game/unit/UnitCounter/index';
import createRules from '@wareena/grid-battles-engine/rules/index';
import FieldOfView from '@wareena/grid-battles-ui/game/map/FieldOfView';
import ResponsiveSvg from '@wareena/ui/game/map/ResponsiveSvg';

type Action = 'place-hill' | 'place-wood' | 'place-unit' | 'place-attacker';

export default function InteractiveLosExample() {
    const mapWidth = 9;
    const mapHeight = 9;

    const [mapHexes, setMapHexes] = useState(createMap(mapWidth, mapHeight));
    const [enemyUnits, setEnemyUnits] = useState<Unit[]>([
        createEnemyUnit(1, { column: 0, row: 4 }),
        createEnemyUnit(2, { column: 7, row: 3 }),
        createEnemyUnit(3, { column: 1, row: 2 }),
        createEnemyUnit(4, { column: 6, row: 1 }),
        createEnemyUnit(5, { column: 2, row: 0 }),
        createEnemyUnit(6, { column: 5, row: 0 }),
        createEnemyUnit(7, { column: 4, row: 0 }),
        createEnemyUnit(8, { column: 5, row: 7 }),
        createEnemyUnit(9, { column: 2, row: 6 }),
        createEnemyUnit(10, { column: 6, row: 5 }),
    ]);
    const [actionName, setActionName] = useState<Action>('place-wood');
    const [myUnitPosition, setMyUnitPosition] = useState<Position>({ column: 4, row: 4 });

    const hexProperties = createHexProperties({
        hexSize: 50,
        isFlatTop: false,
        isFirstOffsetted: false,
    });

    const smallHexProperties = createHexProperties({
        hexSize: 40,
        isFlatTop: false,
        isFirstOffsetted: false,
    });

    const mapDimensions = calcMapDimensions(mapWidth, mapHeight, hexProperties);

    const hexes = convertMapToScenarioFormat(mapHexes);

    const myUnit = createUnit(UnitTypeId.Archer, {
        id: 10001,
        label: 'Archer 1',
        position: myUnitPosition,
    });

    const units = [myUnit, ...enemyUnits];

    const ClearLosLine = createClearLosLine(hexProperties);
    const BlockedLosLine = createBlockedLosLine(hexProperties);
    const OutOfRangeLosLine = createOutOfRangeLosLine(hexProperties);

    const rules = createRules(mapHexes, hexProperties);

    const losLines = enemyUnits.map((u) => {
        if (rules.isLosUnobstructed(myUnit.position, u.position, units)) {
            if (myUnit.range >= hexProperties.getDistance(myUnit.position, u.position)) {
                return (
                    <ClearLosLine key={`los-${u.id}`} start={myUnit.position} stop={u.position} />
                );
            } else {
                return (
                    <OutOfRangeLosLine
                        key={`los-${u.id}`}
                        start={myUnit.position}
                        stop={u.position}
                    />
                );
            }
        }

        return <BlockedLosLine key={`los-${u.id}`} start={myUnit.position} stop={u.position} />;
    });

    function handleClick(position: Position) {
        const hex = mapHexes.find((h) => isSamePosition(h, position));

        if (!hex) {
            return;
        }

        if (actionName === 'place-hill') {
            if (hex.terrainId !== TerrainId.Hill) {
                setMapHexes(setTerrainToPositions(mapHexes, TerrainId.Hill, [position], 'single1'));
            } else {
                setMapHexes(setTerrainToPositions(mapHexes, TerrainId.Plain, [position]));
            }
        } else if (actionName === 'place-wood') {
            if (hex.terrainId !== TerrainId.Wood) {
                setMapHexes(setTerrainToPositions(mapHexes, TerrainId.Wood, [position], 'single1'));
            } else {
                setMapHexes(setTerrainToPositions(mapHexes, TerrainId.Plain, [position]));
            }
        } else if (actionName === 'place-unit') {
            const unitOnPosition = units.find((u) => isSamePosition(position, u.position));

            if (unitOnPosition) {
                if (unitOnPosition.side === SideId.B) {
                    setEnemyUnits((prevUnits) => {
                        return prevUnits.filter((u) => u.id !== unitOnPosition.id);
                    });
                }
            } else {
                setEnemyUnits((prevUnits) => {
                    const nextId = prevUnits.reduce((acc, u) => (u.id > acc ? u.id : acc), 0) + 1;

                    return [...prevUnits, createEnemyUnit(nextId, position)];
                });
            }
        } else if (actionName === 'place-attacker') {
            const unitOnPosition = units.find((u) => isSamePosition(position, u.position));

            if (!unitOnPosition) {
                setMyUnitPosition({ ...position });
            }
        }
    }

    const HillHex = terrainMap[TerrainId.Hill];
    const WoodHex = terrainMap[TerrainId.Wood];

    const hexCenter = smallHexProperties.calculateCenterPos(0, 0);

    return (
        <>
            <div>
                <strong>Select element:</strong>
            </div>
            <div>
                <ImageButton
                    size={smallHexProperties.height + 10}
                    isActive={actionName === 'place-wood'}
                    onClick={() => setActionName('place-wood')}
                >
                    <WoodHex
                        isFlatTop={false}
                        size={smallHexProperties.size}
                        center={{ x: 5 + smallHexProperties.height / 2, y: hexCenter.y + 5 }}
                        dimensions={{
                            width: smallHexProperties.width,
                            height: smallHexProperties.height,
                        }}
                    />
                </ImageButton>
                <ImageButton
                    size={smallHexProperties.height + 10}
                    isActive={actionName === 'place-hill'}
                    onClick={() => setActionName('place-hill')}
                >
                    <HillHex
                        isFlatTop={false}
                        size={smallHexProperties.size}
                        center={{ x: 5 + smallHexProperties.height / 2, y: hexCenter.y + 5 }}
                        dimensions={{
                            width: smallHexProperties.width,
                            height: smallHexProperties.height,
                        }}
                    />
                </ImageButton>
                <ImageButton
                    size={smallHexProperties.height + 10}
                    isActive={actionName === 'place-unit'}
                    onClick={() => setActionName('place-unit')}
                >
                    <UnitCounter
                        origin={{
                            x: (smallHexProperties.height - 50) / 2,
                            y: (smallHexProperties.height - 50) / 2,
                        }}
                        counterSize={60}
                        unit={createUnit(UnitTypeId.Swordman, { side: SideId.B })}
                    />
                </ImageButton>
                <ImageButton
                    size={smallHexProperties.height + 10}
                    isActive={actionName === 'place-attacker'}
                    onClick={() => setActionName('place-attacker')}
                >
                    <UnitCounter
                        origin={{
                            x: (smallHexProperties.height - 50) / 2,
                            y: (smallHexProperties.height - 50) / 2,
                        }}
                        counterSize={60}
                        unit={myUnit}
                    />
                </ImageButton>
            </div>
            <div>
                <strong>Place it on a map:</strong>
            </div>
            <ResponsiveSvg width={mapDimensions.width} height={mapDimensions.height}>
                <GameMap hexProperties={hexProperties} map={hexes} getTerrainHex={getTerrainHex} />
                <UnitLayer hexProperties={hexProperties} units={units} />
                <FieldOfView
                    hexProperties={hexProperties}
                    rules={rules}
                    unit={myUnit}
                    gameState={{ units } as GameState}
                />
                {losLines}
                <ActiveLayer map={hexes} hexProperties={hexProperties} onClick={handleClick} />
            </ResponsiveSvg>
        </>
    );
}

function createEnemyUnit(id: number, position: Position) {
    const label = id >= 10 ? `Sword ${id}` : `Swordman ${id}`;

    return createUnit(UnitTypeId.Swordman, {
        id,
        label,
        side: SideId.B,
        position: { ...position },
    });
}

function ImageButton({
    size,
    isActive,
    onClick,
    children,
}: PropsWithChildren<{ size: number; isActive: boolean; onClick: () => void }>) {
    const [bgColor, setBgColor] = useState('white');

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            onMouseEnter={() => setBgColor('#dedede')}
            onMouseLeave={() => setBgColor('white')}
        >
            <g style={{ cursor: 'pointer' }} onClick={onClick}>
                <rect
                    x={2}
                    y={2}
                    width={size - 4}
                    height={size - 4}
                    stroke="black"
                    fill={isActive ? 'lightgrey' : bgColor}
                    rx={4}
                />
                {children}
            </g>
        </svg>
    );
}
