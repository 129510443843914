import React from 'react';
import UnitCounter from './UnitCounter/index.js';
function UnitCounterAsImage({ counterSize, unit, containerProps = {}, wrapperProps = {}, svgTagProps = {}, bgColor = 'white', isBold = false, }) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: counterSize + 6, height: counterSize + 6, ...svgTagProps },
        React.createElement("g", { ...wrapperProps },
            React.createElement(UnitCounter, { origin: { x: 3, y: 3 }, counterSize: counterSize, unit: unit, containerProps: containerProps, bgColor: bgColor, isBold: isBold }))));
}
export function EliminatedUnitCounterAsImage({ counterSize, unit, containerProps = {}, wrapperProps = {}, svgTagProps = {}, bgColor = 'white', isBold = false, }) {
    const gProps = { filter: 'grayscale(70%)', ...wrapperProps };
    const padding = 3;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: counterSize + padding * 2, height: counterSize + padding * 2, ...svgTagProps },
        React.createElement("g", { ...gProps },
            React.createElement(UnitCounter, { origin: { x: padding, y: padding }, counterSize: counterSize, unit: unit, containerProps: containerProps, bgColor: bgColor, isBold: isBold })),
        React.createElement(Eliminated, { origin: {
                x: padding,
                y: padding,
            }, sideSize: counterSize, offset: padding })));
}
function Eliminated({ origin, offset, sideSize, }) {
    const strokeWidth = 5;
    return (React.createElement("g", null,
        React.createElement("line", { x1: origin.x + offset, y1: origin.y + offset, x2: origin.x + sideSize - offset, y2: origin.y + sideSize - offset, stroke: "crimson", strokeWidth: strokeWidth, strokeLinecap: "round" }),
        React.createElement("line", { x1: origin.x + sideSize - offset, y1: origin.y + offset, x2: origin.x + offset, y2: origin.y + sideSize - offset, stroke: "crimson", strokeWidth: strokeWidth, strokeLinecap: "round" })));
}
export default UnitCounterAsImage;
