import { isUserActive } from '../utils/index.js';
const assertCanUnselectUnitForActivation = (game, state, userId, unitId) => {
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
    if (!state.unitsSelectedForActivation.find((uid) => uid === unitId)) {
        throw `Unit ${unitId} is not selected`;
    }
};
const unselectUnitForActivation = (request, game, state) => {
    const { unitId, userId } = request.payload;
    assertCanUnselectUnitForActivation(game, state, userId, unitId);
    return {
        ...state,
        unitsSelectedForActivation: [
            ...state.unitsSelectedForActivation.filter((id) => id !== unitId),
        ],
    };
};
export default unselectUnitForActivation;
