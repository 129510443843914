import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
import { getUnitTypes } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
const createUnit = (typeId, properties) => {
    const unitType = getUnitTypes().find((t) => t.id === typeId);
    if (!unitType) {
        throw `Unknown unit type ${typeId}`;
    }
    return {
        id: 0,
        label: unitType.label,
        name: unitType.name,
        side: SideId.A,
        unitTypeId: typeId,
        position: {
            row: 0,
            column: 0,
        },
        baseTypeId: unitType.baseTypeId,
        maxMovementPoints: unitType.movementPoints,
        movementPoints: unitType.movementPoints,
        health: unitType.health,
        isFatigued: false,
        iconName: unitType.iconName,
        range: unitType.range,
        attackType: unitType.attackType,
        attackDice: unitType.attackDice,
        defenseDice: unitType.defenseDice,
        bonusAgainstBaseTypes: unitType.bonusAgainstBaseTypes,
        ...properties,
    };
};
export default createUnit;
