import React from 'react';
import GameDieSideAsImage from '../dice/GameDieSideAsImage.js';
import BonusSymbolAsImage from '../dice/BonusSymbolAsImage.js';
export default function EquationSymbols({ diceResults, bonusSymbols, }) {
    const equationSide = [];
    diceResults.forEach((roll, index) => {
        equationSide.push(React.createElement(GameDieSideAsImage, { key: `roll-${index}-${roll.die}-${roll.roll}`, size: 50, dieType: roll.die, side: roll.roll }));
        if (index !== diceResults.length - 1) {
            equationSide.push(React.createElement("span", { key: `rollplus-${index}`, className: "icon" },
                React.createElement("i", { className: "fas fa-plus" })));
        }
    });
    if (bonusSymbols.length) {
        equationSide.push(React.createElement("span", { key: `plus`, className: "icon" },
            React.createElement("i", { className: "fas fa-plus" })));
    }
    bonusSymbols.forEach((symbol, index) => {
        equationSide.push(React.createElement(BonusSymbolAsImage, { key: `symbol-${index}`, symbol: symbol, size: 30 }));
        if (index !== bonusSymbols.length - 1) {
            equationSide.push(React.createElement("span", { key: `symbolplus-${index}`, className: "icon" },
                React.createElement("i", { className: "fas fa-plus" })));
        }
    });
    if (equationSide.length > 1) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { display: 'flex', alignItems: 'center', flexWrap: 'wrap' } },
                React.createElement("span", { className: "combat-equation-braces" }, "("),
                equationSide,
                React.createElement("span", { className: "combat-equation-braces" }, ")"))));
    }
    return React.createElement(React.Fragment, null, equationSide);
}
