import React from 'react';
export default function CancelIcon({ counterSize, counterOrigin, onClick, }) {
    const pointSize = counterSize / 32;
    const halfSize = counterSize / 2;
    const rect = {
        x: counterOrigin.x - 2 * pointSize,
        y: counterOrigin.y + halfSize + 2 * pointSize,
        width: halfSize,
        height: halfSize,
    };
    const center = {
        x: rect.x + rect.width / 2,
        y: rect.y + rect.height / 2,
    };
    const path = `
        M ${rect.x + 4 * pointSize} ${rect.y + 2 * pointSize}
        L ${center.x} ${center.y - pointSize}
        L ${rect.x + rect.width - 4 * pointSize} ${rect.y + 2 * pointSize}
        L ${rect.x + rect.width - 2 * pointSize} ${rect.y + 4 * pointSize}
        L ${center.x + pointSize} ${center.y}
        L ${rect.x + rect.width - 2 * pointSize} ${rect.y + rect.height - 4 * pointSize}
        L ${rect.x + rect.width - 4 * pointSize} ${rect.y + rect.height - 2 * pointSize}
        L ${center.x} ${center.y + pointSize}
        L ${rect.x + 4 * pointSize} ${rect.y + rect.height - 2 * pointSize}
        L ${rect.x + 2 * pointSize} ${rect.y + rect.height - 4 * pointSize}
        L ${center.x - pointSize} ${center.y}
        L ${rect.x + 2 * pointSize} ${rect.y + 4 * pointSize}
        Z
    `;
    const containerProps = {};
    if (onClick) {
        containerProps.onClick = onClick;
        containerProps.style = { cursor: 'pointer' };
    }
    return (React.createElement("g", { ...containerProps },
        React.createElement("rect", { x: rect.x, y: rect.y, width: rect.width, height: rect.height, fill: "rgba(255, 0, 0, 0.8)", rx: 2, stroke: "black", strokeWidth: 1, style: {
                filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, .7))',
            } }),
        React.createElement("path", { d: path.trim(), stroke: "black", strokeWidth: 1, fill: "white" })));
}
