import { ReactElement } from 'react';
import MovementPage from '../ui/pages/MovementPage';
import RoundsTurnsPage from '../ui/pages/RoundsTurnsPage';
import ActivationsPage from '../ui/pages/ActivationsPage';
import CombatPage from '../ui/pages/CombatPage';
import MeleeAndRangedUnits from '../ui/pages/MeleeAndRangedUnits';
import LineOfSightPage from '../ui/pages/LineOfSightPage';
import HealthPage from '../ui/pages/Health';
import DicePage from '../ui/pages/Dice';
import ChancesTablePage from '../ui/pages/ChancesTable';
import AdvantagePage from '../ui/pages/Advantage';
import BoldAndBattleBackPage from '../ui/pages/BoldAndBattleBack';
import TerrainAndCombatPage from '../ui/pages/TerrainAndCombat';

const tutorialPages = [
    {
        id: 'rounds-turns',
        numId: 1,
        page: RoundsTurnsPage,
        title: 'Rounds, Turns & Activations',
    },
    {
        id: 'activations',
        numId: 2,
        page: ActivationsPage,
        title: 'Activations',
    },
    { id: 'movement', numId: 3, page: MovementPage, title: 'Movement' },
    { id: 'combat', numId: 4, page: CombatPage, title: 'Combat Basics' },
    {
        id: 'melee-and-ranged-units',
        numId: 5,
        page: MeleeAndRangedUnits,
        title: 'Melee and Ranged',
    },
    { id: 'line-of-sight', numId: 6, page: LineOfSightPage, title: 'Line of Sight' },
    { id: 'health', numId: 7, page: HealthPage, title: 'Health' },
    { id: 'dice', numId: 8, page: DicePage, title: 'Dice and Combat' },
    { id: 'chances-table', numId: 9, page: ChancesTablePage, title: 'Chances Table' },
    { id: 'advantage', numId: 10, page: AdvantagePage, title: 'Advantage' },
    {
        id: 'bold-and-battle-back',
        numId: 11,
        page: BoldAndBattleBackPage,
        title: 'Bold and Battle Back',
    },
    {
        id: 'terrain-and-combat',
        numId: 12,
        page: TerrainAndCombatPage,
        title: 'Terrain and Combat',
    },
] as const;

export type TutorialPageId = (typeof tutorialPages)[number]['id'];

export function getTutorialPages() {
    return [...tutorialPages];
}

export function getPageById(pageId: TutorialPageId) {
    return tutorialPages.find((p) => p.id === pageId);
}
