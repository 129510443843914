export default function checkVictoryConditions(state, scenario) {
    let wasVictoryConditionsAchieved = false;
    let winner = null;
    let mostPoints = 0;
    state.victoryPoints.forEach((vp) => {
        if (vp.points >= scenario.victoryConditions && vp.points > mostPoints) {
            wasVictoryConditionsAchieved = true;
            winner = vp.side;
            mostPoints = vp.points;
        }
    });
    return {
        wasVictoryConditionsAchieved,
        winner,
    };
}
