import { isSamePosition } from '@wareena/game-algorithms';
import { TerrainId } from '@wareena/grid-battles-entity/game/map/terrain';
function createMap(cols, rows) {
    const hexes = [];
    for (let col = 0; col < cols; col += 1) {
        for (let row = 0; row < rows; row += 1) {
            hexes.push({
                column: col,
                row: row,
                terrainId: TerrainId.Plain,
            });
        }
    }
    return hexes;
}
export function setTerrainToPositions(mapHexes, terrainId, positions, graphicalVariant) {
    return mapHexes.map((hex) => {
        for (let i = 0; i < positions.length; i += 1) {
            if (isSamePosition(hex, positions[i])) {
                return {
                    ...hex,
                    terrainId,
                    graphicalVariant,
                };
            }
        }
        return {
            ...hex,
        };
    });
}
export default createMap;
