import * as selectingForActivation from './selectingForActivation/index.js';
import * as selectingForMovement from './selectingForMovement/index.js';
import * as movingUnit from './movingUnit/index.js';
import selectingAttacker from './selectingAttacker/index.js';
import selectingDefender from './selectingDefender/index.js';
import * as gameOver from './gameOver/index.js';
const stateHandlers = {
    selectingForActivation,
    selectingForMovement,
    movingUnit,
    selectingAttacker,
    selectingDefender,
    gameOver,
};
export default stateHandlers;
