'use client';

import { createPathToTutorialById } from '@local/tutorial/services/routing';
import { createHexProperties } from '@wareena/game-algorithms';
import collectBonusAttackDice from '@wareena/grid-battles-engine/gameStateMachine/helpers/battle/collectBonusAttackDice';
import collectBonusDefenseDice from '@wareena/grid-battles-engine/gameStateMachine/helpers/battle/collectBonusDefenseDice';
import collectBonusSymbols from '@wareena/grid-battles-engine/gameStateMachine/helpers/battle/collectBonusSymbols';
import createRules from '@wareena/grid-battles-engine/rules/index';
import calculateChanceTable from '@wareena/grid-battles-engine/services/calculateChanceTable';
import createMap, {
    setTerrainToPositions,
} from '@wareena/grid-battles-engine/services/map/createMap';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { BattleBonusPurpose } from '@wareena/grid-battles-entity/game/codebook/battleBonus';
import { CombatEffectSymbol, DieType } from '@wareena/grid-battles-entity/game/codebook/dice';
import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
import { UnitTypeId, getUnitTypes } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import {
    TerrainId,
    getTerrainName,
    isPassableTerrain,
} from '@wareena/grid-battles-entity/game/map/terrain';
import BonusSymbolAsImage from '@wareena/grid-battles-ui/game/dice/BonusSymbolAsImage';
import DieIconAsImage from '@wareena/grid-battles-ui/game/dice/DieIconAsImage';
import terrainMap from '@wareena/grid-battles-ui/game/terrain/index';
import UnitOnTerrainAsImage from '@wareena/grid-battles-ui/game/unit/UnitCounter/UnitOnTerrainAsImage';
import UnitCounterAsImage from '@wareena/grid-battles-ui/game/unit/UnitCounterAsImage';
import Link from 'next/link';
import { useState } from 'react';

export default function ChanceTable() {
    const [attackerTypeId, setAttackerTypeId] = useState(UnitTypeId.Archer);
    const [defenderTypeId, setDefenderTypeId] = useState(UnitTypeId.Pikeman);
    const [attackerTerrainId, setAttackerTerrainId] = useState(TerrainId.Plain);
    const [defenderTerrainId, setDefenderTerrainId] = useState(TerrainId.Plain);

    const unitTypes = getUnitTypes();
    const terrainIds: TerrainId[] = Object.keys(terrainMap)
        .map((idx) => +idx)
        .filter((terrainId) => isPassableTerrain(terrainId));

    const attacker = createUnit(attackerTypeId, { position: { column: 0, row: 0 } });
    const defender = createUnit(defenderTypeId, {
        position: { column: 1, row: 0 },
        side: SideId.B,
    });

    const hexProperties = createHexProperties({
        hexSize: 50,
        isFlatTop: false,
        isFirstOffsetted: false,
    });

    let map = createMap(2, 2);
    map = setTerrainToPositions(map, attackerTerrainId, [attacker.position]);
    map = setTerrainToPositions(map, defenderTerrainId, [defender.position]);

    const rules = createRules(map, hexProperties);

    const hasAdvantage = rules.doesHaveAdvantageAgainst(attacker, defender);

    const attackerBonuses = rules.getBonusesForUnit(attacker);
    const defenderBonuses = rules.getBonusesForUnit(defender);

    const bonusAttackDice = collectBonusAttackDice(attackerBonuses);
    const bonusDefenseDice = collectBonusDefenseDice(defenderBonuses);
    const attackerDicePool = [...attacker.attackDice, ...bonusAttackDice];
    const defenderDicePool = [...defender.defenseDice, ...bonusDefenseDice];
    const bonusAttackSymbols = collectBonusSymbols(attackerBonuses, BattleBonusPurpose.Attack);
    const bonusDefenseSymbols = collectBonusSymbols(defenderBonuses, BattleBonusPurpose.Defense);

    const { chanceForHits } = calculateChanceTable(
        attackerDicePool,
        defenderDicePool,
        bonusAttackSymbols,
        bonusDefenseSymbols,
        hasAdvantage
    );

    return (
        <>
            <h4 className="title is-4 mt-4">Select the combatants and their terrain:</h4>

            <form>
                <div className="field is-horizontal">
                    <div className="field-label is-large">
                        <label className="label" htmlFor="attacker-type-id">
                            Attacker
                        </label>
                    </div>
                    <div className="field-body">
                        <div className="field has-addons">
                            <div className="control has-icons-left">
                                <div className="select is-large">
                                    <select
                                        onChange={(event) => setAttackerTypeId(+event.target.value)}
                                        value={attackerTypeId}
                                        id="attacker-type-id"
                                    >
                                        {unitTypes.map((unitType) => (
                                            <option key={unitType.id} value={unitType.id}>
                                                {unitType.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <span className="icon is-large is-left">
                                    <UnitCounterAsImage unit={attacker} counterSize={50} />
                                </span>
                            </div>
                            <div className="control has-icons-left">
                                <div className="select is-large">
                                    <select
                                        onChange={(event) =>
                                            setAttackerTerrainId(+event.target.value)
                                        }
                                        id="attacker-terrain-id"
                                    >
                                        {terrainIds.map((terrainId) => (
                                            <option key={terrainId} value={terrainId}>
                                                {getTerrainName(terrainId)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <span className="icon is-large is-left">
                                    <TerrainHexAsImage terrainId={attackerTerrainId} hexSize={20} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-large">
                        <label className="label" htmlFor="defender-type-id">
                            Defender
                        </label>
                    </div>
                    <div className="field-body">
                        <div className="field has-addons">
                            <div className="control has-icons-left">
                                <div className="select is-large">
                                    <select
                                        onChange={(event) => setDefenderTypeId(+event.target.value)}
                                        value={defenderTypeId}
                                        id="defender-type-id"
                                    >
                                        {unitTypes.map((unitType) => (
                                            <option key={unitType.id} value={unitType.id}>
                                                {unitType.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <span className="icon is-large is-left">
                                    <UnitCounterAsImage unit={defender} counterSize={50} />
                                </span>
                            </div>
                            <div className="control has-icons-left">
                                <div className="select is-large">
                                    <select
                                        onChange={(event) =>
                                            setDefenderTerrainId(+event.target.value)
                                        }
                                        id="defender-terrain-id"
                                    >
                                        {terrainIds.map((terrainId) => (
                                            <option key={terrainId} value={terrainId}>
                                                {getTerrainName(terrainId)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <span className="icon is-large is-left">
                                    <TerrainHexAsImage terrainId={defenderTerrainId} hexSize={20} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-large">
                        <label className="label">Results</label>
                    </div>
                    <div className="field-body">
                        <table className="table is-striped is-bordered mt-4">
                            <thead>
                                <tr>
                                    <th colSpan={2}>Chance to deal:</th>
                                    <th colSpan={2}>Chance to deal:</th>
                                </tr>
                            </thead>
                            <tbody>
                                {chanceForHits.map((data) => (
                                    <tr key={data.hits}>
                                        <td>
                                            <strong>
                                                {data.hits === 0
                                                    ? 'no hits'
                                                    : `at least ${data.hits} ${
                                                          data.hits === 1 ? 'hit' : 'hits'
                                                      }`}
                                            </strong>
                                        </td>
                                        <td>
                                            {Math.round(data.atLeastChance * 100 * 100) / 100} %
                                        </td>
                                        <td>
                                            <strong>
                                                {data.hits === 0
                                                    ? 'no hits'
                                                    : `exactly ${data.hits} ${
                                                          data.hits === 1 ? 'hit' : 'hits'
                                                      }`}
                                            </strong>
                                        </td>
                                        <td>{Math.round(data.exactChance * 100 * 100) / 100} %</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>

            <h4 className="title is-4 mt-6">
                Statistics of {attacker.label} vs. {defender.label}
            </h4>

            <div className="columns">
                <div className="column is-half">
                    <table className="table is-striped is-bordered">
                        <tbody>
                            <tr>
                                <th>Attacker:</th>
                                <td>
                                    <UnitOnTerrainAsImage
                                        unit={attacker}
                                        isFlatTop={false}
                                        terrainId={attackerTerrainId}
                                    />
                                </td>
                            </tr>
                            <DicePool
                                title="Attacker dice pool:"
                                unitDicePool={attacker.attackDice}
                                bonusDicePool={bonusAttackDice}
                            />
                            <BonusSymbolsPool
                                title="Bonus symbols for attacker:"
                                symbols={bonusAttackSymbols}
                            />
                            <tr>
                                <th>
                                    Does have an{' '}
                                    <Link href={createPathToTutorialById('advantage')}>
                                        <i>Advantage</i>
                                    </Link>{' '}
                                    against {defender.label}:
                                </th>
                                <td>
                                    <span
                                        className={`tag ${
                                            hasAdvantage ? 'is-success' : 'is-danger'
                                        }`}
                                    >
                                        {hasAdvantage ? 'yes' : 'no'}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="column is-half">
                    <table className="table is-striped is-bordered">
                        <tbody>
                            <tr>
                                <th>Defender:</th>
                                <td>
                                    <UnitOnTerrainAsImage
                                        unit={defender}
                                        isFlatTop={false}
                                        terrainId={defenderTerrainId}
                                    />
                                </td>
                            </tr>
                            <DicePool
                                title="Defender dice pool:"
                                unitDicePool={defender.defenseDice}
                                bonusDicePool={bonusDefenseDice}
                            />
                            <BonusSymbolsPool
                                title="Bonus symbols for defender:"
                                symbols={bonusDefenseSymbols}
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

function TerrainHexAsImage({
    terrainId,
    isFlatTop = false,
    hexSize = 50,
    graphicalVariant,
}: {
    terrainId: TerrainId;
    isFlatTop?: boolean;
    hexSize?: number;
    graphicalVariant?: string;
}) {
    const TerrainHex = terrainMap[terrainId];

    const hexProperties = createHexProperties({
        hexSize,
        isFlatTop,
        isFirstOffsetted: false,
    });

    const padding = 3;

    const terrainImgWidth = hexProperties.width + 2 * padding;
    const terrainImgHeight = hexProperties.height + 2 * padding;

    const hexCenter = hexProperties.calculateCenterPos(0, 0);

    const variantProps = graphicalVariant !== undefined ? { graphicalVariant } : {};

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={terrainImgWidth}
                height={terrainImgHeight}
            >
                <TerrainHex
                    isFlatTop={isFlatTop}
                    size={hexProperties.size}
                    center={{ x: hexCenter.x + padding, y: hexCenter.y + padding }}
                    dimensions={{
                        width: hexProperties.width,
                        height: hexProperties.height,
                    }}
                    {...variantProps}
                />
            </svg>
        </>
    );
}

function DicePool({
    title,
    unitDicePool,
    bonusDicePool,
}: {
    title: string;
    unitDicePool: DieType[];
    bonusDicePool: DieType[];
}) {
    return (
        <>
            <tr>
                <th rowSpan={3}>{title}</th>
                <td>
                    <div className="is-flex is-align-items-center mb-1 has-small-column-gap">
                        <strong>Total:</strong>
                        {[...unitDicePool, ...bonusDicePool].map((gameDie, idx) => {
                            return (
                                <DieIconAsImage
                                    key={`${gameDie}-${idx}`}
                                    dieType={gameDie}
                                    size={25}
                                />
                            );
                        })}
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div className="is-flex is-align-items-center mb-1 has-small-column-gap">
                        <strong>From unit:</strong>
                        {unitDicePool.map((gameDie, idx) => {
                            return (
                                <DieIconAsImage
                                    key={`${gameDie}-${idx}`}
                                    dieType={gameDie}
                                    size={25}
                                />
                            );
                        })}
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div className="is-flex is-align-items-center mb-1 has-small-column-gap">
                        <strong>From terrain:</strong>
                        {bonusDicePool.length === 0 ? (
                            <span>nothing</span>
                        ) : (
                            bonusDicePool.map((gameDie, idx) => (
                                <DieIconAsImage
                                    key={`${gameDie}-${idx}}`}
                                    dieType={gameDie}
                                    size={25}
                                />
                            ))
                        )}
                    </div>
                </td>
            </tr>
        </>
    );
}

function BonusSymbolsPool({ title, symbols }: { title: string; symbols: CombatEffectSymbol[] }) {
    return (
        <>
            <tr>
                <th>{title}</th>
                <td>
                    <div className="is-flex is-align-items-center mb-1 has-small-column-gap">
                        <strong>From terrain:</strong>
                        {symbols.length === 0
                            ? 'none'
                            : symbols.map((symbol, idx) => (
                                  <BonusSymbolAsImage
                                      symbol={symbol}
                                      size={20}
                                      key={`${symbol}-${idx}`}
                                  />
                              ))}
                    </div>
                </td>
            </tr>
        </>
    );
}
