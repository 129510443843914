import { RequestType } from '../../request/types.js';
import selectUnitForActivation from './selectUnitForActivation.js';
import unselectUnitForActivation from './unselectUnitForActivation.js';
import changeState from './changeState.js';
import unselectAllUnitsForActivation from './unselectAllUnitsForActivation.js';
export const onEnter = ({ state }) => {
    return {
        ...state,
        unitsSelectedForActivation: [],
    };
};
export const onLeave = ({ state }) => {
    return state;
};
export const executeRequest = ({ request, stateMachine, game, scenario, state, }) => {
    switch (request.type) {
        case RequestType.SelectUnitForActivation:
            return selectUnitForActivation(request, game, scenario, state);
        case RequestType.UnselectUnitForActivation:
            return unselectUnitForActivation(request, game, state);
        case RequestType.UnselectAllUnitsForActivation:
            return unselectAllUnitsForActivation(request, game, state);
        case RequestType.SetStateName:
            return changeState(game, state, request, stateMachine);
    }
    return state;
};
