import { AttackType } from '@wareena/grid-battles-entity/game/codebook/attackTypes';
import { getNeighbours, } from '@wareena/game-algorithms';
import { getTerrainBonuses, getMovementCost as getBasicMovementCost, isBlockingTerrain, } from '@wareena/grid-battles-entity/game/map/terrain';
const isSamePosition = (position1, position2) => {
    if (!position1 || !position2) {
        return false;
    }
    return position1.column === position2.column && position1.row === position2.row;
};
const NUM_OF_FRIENDS_TO_BE_BOLD = 2;
function createRules(mapHexes, hexProperties) {
    function getUnitOnPosition(position, units) {
        return units.find((u) => {
            if (u.position.row === position.row && u.position.column === position.column) {
                return true;
            }
            return false;
        });
    }
    function getExistingNeighbours(position) {
        const neighbours = getNeighbours({
            position,
            isFlatTop: hexProperties.isFlatTop,
            isFirstOffsetted: hexProperties.isFirstOffsetted,
        });
        return neighbours.filter((neighbour) => {
            const hex = mapHexes.find((h) => h.column === neighbour.column && h.row === neighbour.row);
            return !!hex;
        });
    }
    function getPossibleMeleeTargets(unit, allUnits) {
        const neighbours = getExistingNeighbours(unit.position);
        return neighbours.filter((neighbour) => {
            const unitOnPosition = getUnitOnPosition(neighbour, allUnits);
            if (!unitOnPosition) {
                return false;
            }
            if (unitOnPosition?.side !== unit.side) {
                return true;
            }
            return false;
        });
    }
    function doesHexContainObstruction(position, targetPosition, allUnits) {
        // ak je to cielovy hex, tak neblokuje
        if (isSamePosition(position, targetPosition)) {
            return false;
        }
        const hex = mapHexes.find((h) => isSamePosition(h, position));
        // ak hex neexistuje, tak blokuje
        if (!hex) {
            return true;
        }
        // ak je to blokujuci teren, tak blokuje
        if (isBlockingTerrain(hex.terrainId)) {
            return true;
        }
        // ak je na hexe jednotka, tak blokuje
        const unitOnPosition = getUnitOnPosition(position, allUnits);
        if (unitOnPosition) {
            return true;
        }
        return false;
    }
    function getPossibleRangedTargets(unit, allUnits) {
        const possibleTargets = [];
        allUnits.forEach((otherUnit) => {
            if (otherUnit.side === unit.side) {
                return;
            }
            const distance = hexProperties.getDistance(unit.position, otherUnit.position);
            if (distance > unit.range) {
                return;
            }
            const hasLos = hexProperties.isLosUnobstructed(unit.position, otherUnit.position, (position) => doesHexContainObstruction(position, otherUnit.position, allUnits));
            if (hasLos) {
                possibleTargets.push({ ...otherUnit.position });
            }
        });
        return possibleTargets;
    }
    function getPossibleTargets(unit, allUnits) {
        if (unit.attackType === AttackType.Melee) {
            return getPossibleMeleeTargets(unit, allUnits);
        }
        else if (unit.attackType === AttackType.Ranged) {
            return getPossibleRangedTargets(unit, allUnits);
        }
        return [];
    }
    function getNumOfAdjacentFriendlyUnits(unit, state) {
        const unitSide = unit.side;
        const neighbourPositions = getExistingNeighbours(unit.position);
        let numOfFriendlyUnits = 0;
        neighbourPositions.forEach((position) => {
            const unitOnPosition = getUnitOnPosition(position, state.units);
            if (unitOnPosition && unitOnPosition?.side === unitSide) {
                numOfFriendlyUnits += 1;
            }
        });
        return numOfFriendlyUnits;
    }
    function isUnitBold(unit, state) {
        return getNumOfAdjacentFriendlyUnits(unit, state) >= NUM_OF_FRIENDS_TO_BE_BOLD;
    }
    function willUnitOnPositionBeBold(unit, position, state) {
        let adjacentFriendlyUnits = getAdjacentFriendlyUnits(unit.side, position, state);
        adjacentFriendlyUnits = adjacentFriendlyUnits.filter((u) => u.id !== unit.id);
        return adjacentFriendlyUnits.length >= NUM_OF_FRIENDS_TO_BE_BOLD;
    }
    function getAdjacentFriendlyUnits(side, position, state) {
        const neighbourPositions = getExistingNeighbours(position);
        const friendlyUnits = [];
        neighbourPositions.forEach((position) => {
            const unitOnPosition = getUnitOnPosition(position, state.units);
            if (unitOnPosition?.side === side) {
                friendlyUnits.push(unitOnPosition);
            }
        });
        return friendlyUnits;
    }
    const isBattleBackPossible = (attacker, defender, state) => {
        if (attacker.attackType === AttackType.Ranged) {
            return false;
        }
        return isUnitBold(defender, state);
    };
    const areNeighbours = (positionA, positionB) => {
        const neighbours = getExistingNeighbours(positionA);
        if (neighbours.find((n) => isSamePosition(n, positionB)) !== undefined) {
            return true;
        }
        return false;
    };
    const getMovementCost = (from, to, forUnit) => {
        if (!areNeighbours(from, to)) {
            return null;
        }
        const targetHex = mapHexes.find((h) => isSamePosition(to, h));
        if (!targetHex) {
            return null;
        }
        const cost = getBasicMovementCost(targetHex.terrainId);
        // When unit did not spend any of its movement points,
        // it can move to the adjacent hex regardless of cost.
        // But it will cost all of its MPs when the cost is greater
        // than maxMovementPoints.
        if (cost !== null &&
            cost > forUnit.maxMovementPoints &&
            forUnit.maxMovementPoints === forUnit.movementPoints &&
            isSamePosition(from, forUnit.position)) {
            return forUnit.movementPoints;
        }
        return cost;
    };
    const isLosUnobstructed = (from, to, allUnits) => {
        return hexProperties.isLosUnobstructed(from, to, (position) => doesHexContainObstruction(position, to, allUnits));
    };
    function getFieldOfView(unit, state) {
        if (unit.attackType !== AttackType.Ranged) {
            return [];
        }
        function stringId(position) {
            return `${position.column}:${position.row}`;
        }
        const queue = [];
        const known = new Map();
        const positionsInFieldOfView = [];
        queue.push(unit.position);
        while (queue.length > 0) {
            const position = queue.shift();
            const id = stringId(position);
            if (known.has(id)) {
                continue;
            }
            known.set(id, true);
            if (!isSamePosition(position, unit.position)) {
                if (hexProperties.getDistance(position, unit.position) > unit.range) {
                    continue;
                }
                if (isLosUnobstructed(unit.position, position, state.units)) {
                    positionsInFieldOfView.push(position);
                }
            }
            const neighbours = getExistingNeighbours(position);
            const unknown = neighbours.filter((n) => {
                const id = stringId(n);
                return !known.has(id);
            });
            queue.push(...unknown);
        }
        return positionsInFieldOfView;
    }
    function getBonusesForUnit(unit) {
        const bonuses = [];
        const currentHex = mapHexes.find((h) => isSamePosition(h, unit.position));
        if (currentHex) {
            bonuses.push(...getTerrainBonuses(currentHex.terrainId));
        }
        return bonuses;
    }
    function doesHaveAdvantageAgainst(attacker, defender) {
        for (let i = 0; i < (attacker.bonusAgainstBaseTypes?.length ?? 0); i += 1) {
            if (attacker.bonusAgainstBaseTypes[i] === defender.baseTypeId) {
                return true;
            }
        }
        return false;
    }
    return {
        getUnitOnPosition,
        getNeighbours: (position) => {
            return getNeighbours({
                position,
                isFlatTop: hexProperties.isFlatTop,
                isFirstOffsetted: hexProperties.isFirstOffsetted,
            });
        },
        getExistingNeighbours,
        getPossibleTargets,
        isUnitBold,
        getNumOfAdjacentFriendlyUnits,
        isBattleBackPossible,
        getMovementCost,
        isLosUnobstructed,
        willUnitOnPositionBeBold,
        getFieldOfView,
        getBonusesForUnit,
        doesHaveAdvantageAgainst,
    };
}
export default createRules;
