import React from 'react';
import HexOnPosition from '@wareena/ui/game/map/HexOnPosition';
export default function PossibleTargetMarker({ position, hexProperties, onClick, }) {
    const style = {
        fill: 'rgba(0, 0, 0, 0)',
        stroke: 'red',
        strokeWidth: '3px',
    };
    const otherProps = {};
    if (onClick) {
        style.cursor = 'pointer';
        otherProps.onClick = onClick;
    }
    return (React.createElement(HexOnPosition, { position: position, hexProperties: hexProperties, style: style, ...otherProps }));
}
