import { RequestType } from '../../request/types.js';
import selectDefender from './selectDefender.js';
import unselectDefender from './unselectDefender.js';
import unselectAttacker from './unselectAttacker.js';
import attackDefender from './attackDefender/index.js';
const factory = ({ game, scenario, hexProperties }) => {
    const onEnter = ({ state }) => {
        return state;
    };
    const onLeave = ({ state }) => state;
    const executeRequest = ({ request, state, stateMachine }) => {
        switch (request.type) {
            case RequestType.SelectDefender:
                return selectDefender({
                    request: request,
                    state,
                    game,
                    hexProperties,
                });
            case RequestType.UnselectDefender:
                return unselectDefender({
                    request: request,
                    state,
                    game,
                });
            case RequestType.UnselectAttacker:
                return unselectAttacker({
                    request: request,
                    state,
                    stateMachine,
                    game,
                });
            case RequestType.Attack:
                return attackDefender({
                    request: request,
                    state,
                    stateMachine,
                    game,
                    hexProperties,
                    scenario,
                });
        }
        return state;
    };
    return {
        onEnter,
        onLeave,
        executeRequest,
    };
};
export default factory;
