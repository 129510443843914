import React from 'react';
import { getSymbolsOnBattleDie, AttackDieType, DefenseDieType, } from '@wareena/grid-battles-entity/game/codebook/dice';
import SymbolIcon from '../unit/dice/SymbolIcon.js';
const gameDieColors = {
    [AttackDieType.Yellow]: '#ffa',
    [AttackDieType.Blue]: '#009fff',
    [AttackDieType.Red]: '#ff3333',
    [DefenseDieType.Brown]: '#984421',
    [DefenseDieType.Grey]: '#c8beb7',
    [DefenseDieType.Black]: '#555',
};
export default function GameDieSideAsImage({ size, dieType, side, }) {
    const dieSymbols = getSymbolsOnBattleDie(dieType);
    const sideSymbols = dieSymbols[side];
    const symbolSize = size / 4;
    const symbolPositionsByNumberOfSymbols = [
        [],
        [{ x: (size - symbolSize) / 2, y: (size - symbolSize) / 2 }],
        [
            { x: (size * 3) / 4 - symbolSize / 2, y: size / 4 - symbolSize / 2 },
            { x: size / 4 - symbolSize / 2, y: (size * 3) / 4 - symbolSize / 2 },
        ],
        [
            { x: (size * 3) / 4 - symbolSize / 2, y: size / 4 - symbolSize / 2 },
            { x: (size - symbolSize) / 2, y: (size - symbolSize) / 2 },
            { x: size / 4 - symbolSize / 2, y: (size * 3) / 4 - symbolSize / 2 },
        ],
        [
            { x: size / 4 - symbolSize / 2, y: size / 4 - symbolSize / 2 },
            { x: (size * 3) / 4 - symbolSize / 2, y: size / 4 - symbolSize / 2 },
            { x: size / 4 - symbolSize / 2, y: (size * 3) / 4 - symbolSize / 2 },
            { x: (size * 3) / 4 - symbolSize / 2, y: (size * 3) / 4 - symbolSize / 2 },
        ],
        [
            { x: size / 4 - symbolSize / 2, y: size / 4 - symbolSize / 2 },
            { x: (size * 3) / 4 - symbolSize / 2, y: size / 4 - symbolSize / 2 },
            { x: size / 4 - symbolSize / 2, y: (size * 3) / 4 - symbolSize / 2 },
            { x: (size * 3) / 4 - symbolSize / 2, y: (size * 3) / 4 - symbolSize / 2 },
            { x: size / 2 - symbolSize / 2, y: size / 2 - symbolSize / 2 },
        ],
        [
            { x: size / 6 - symbolSize / 2, y: size / 6 - symbolSize / 2 },
            { x: size / 2 - symbolSize / 2, y: size / 3 - symbolSize / 2 },
            { x: (size * 5) / 6 - symbolSize / 2, y: size / 6 - symbolSize / 2 },
            { x: size / 6 - symbolSize / 2, y: (size * 5) / 6 - symbolSize / 2 },
            { x: size / 2 - symbolSize / 2, y: (size * 2) / 3 - symbolSize / 2 },
            { x: (size * 5) / 6 - symbolSize / 2, y: (size * 5) / 6 - symbolSize / 2 },
        ],
    ];
    const positions = symbolPositionsByNumberOfSymbols[sideSymbols.length];
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: size, height: size },
        React.createElement("rect", { x: 0, y: 0, stroke: "black", width: size, height: size, fill: gameDieColors[dieType], rx: size / 10, ry: size / 10 }),
        positions.map((position, idx) => {
            return (React.createElement(SymbolIcon, { key: idx, size: symbolSize, symbol: sideSymbols[idx], origin: position }));
        })));
}
