'use client';

import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
import convertMapToScenarioFormat from '@wareena/grid-battles-engine/services/map/convertMapToScenarioFormat';
import createMap from '@wareena/grid-battles-engine/services/map/createMap';
import TutorialGameMap from '@local/tutorial/ui/TutorialGameMap';
import {
    Game,
    GameState,
    Player,
    Scenario,
} from '@wareena/grid-battles-engine/gameStateMachine/types';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import { ScenarioHexWithId } from '@wareena/grid-battles-entity/scenario/entity/scenario';
import { setTerrainToPositions } from '@wareena/grid-battles-engine/services/map/createMap';
import { TerrainId } from '@wareena/grid-battles-entity/game/map/terrain';

export default function ExampleTerrain() {
    const playerA: Player = {
        id: 1,
        side: SideId.A,
    };

    const playerB: Player = {
        id: 2,
        side: SideId.B,
    };

    const map = { cols: 5, rows: 5 };
    let mapHexes = createMap(map.cols, map.rows);
    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.Wood,
        [{ column: 1, row: 1 }],
        'oneAdjacent1Angle300'
    );
    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.Wood,
        [{ column: 1, row: 0 }],
        'oneAdjacent1Angle120'
    );
    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.Hill,
        [{ column: 2, row: 1 }],
        'oneAdjacent1'
    );
    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.Hill,
        [{ column: 3, row: 0 }],
        'oneAdjacent1Angle180'
    );
    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.River,
        [{ column: 0, row: 3 }],
        'straight1'
    );
    mapHexes = setTerrainToPositions(mapHexes, TerrainId.River, [{ column: 2, row: 3 }], 'turn1');
    mapHexes = setTerrainToPositions(mapHexes, TerrainId.Bridge, [{ column: 1, row: 3 }]);
    mapHexes = setTerrainToPositions(
        mapHexes,
        TerrainId.Bridge,
        [{ column: 3, row: 4 }],
        'stone1Angle60'
    );

    const scenario: Scenario & {
        hexSize: number;
        isFlatTop: boolean;
        isFirstOffsetted: boolean;
        hexes: ScenarioHexWithId[][];
    } = {
        startingSide: SideId.A,
        victoryConditions: 5,
        activationsPerTurn: 3,
        width: map.cols,
        height: map.rows,
        hexSize: 50,
        isFlatTop: false,
        isFirstOffsetted: false,
        hexes: convertMapToScenarioFormat(mapHexes),
    };

    const lightCavalry = createUnit(UnitTypeId.LightCavalry, {
        id: 1,
        position: { column: 2, row: 2 },
    });

    const state: GameState = {
        stateName: 'selectingForMovement',
        round: 1,
        units: [lightCavalry],
        activeSide: playerA.side,
        unitsSelectedForActivation: [1],
        movingUnits: { currentlyMovingId: null, units: [] },
        victoryPoints: [],
    };

    const game: Game = {
        hexes: mapHexes,
        players: [playerA, playerB],
    };

    return (
        <TutorialGameMap
            scenario={scenario}
            state={state}
            game={game}
            checkEndOfAnExample={(state, prevState) => {
                if (state.stateName !== 'movingUnit' && prevState.stateName === 'movingUnit') {
                    return true;
                }

                return false;
            }}
        />
    );
}
