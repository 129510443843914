import { RequestType } from '../../request/types.js';
import { isUserActive } from '../utils/index.js';
export const onEnter = ({ state }) => {
    return {
        ...state,
    };
};
export const onLeave = ({ state }) => state;
export const executeRequest = ({ request, stateMachine, game, state, }) => {
    switch (request.type) {
        case RequestType.SelectUnitForMovement:
            return selectUnitForMovement(request, game, state, stateMachine);
    }
    return state;
};
const selectUnitForMovement = (request, game, state, stateMachine) => {
    const { unitId, userId } = request.payload;
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
    const unit = state.units.find((u) => u.id === unitId);
    if (!unit) {
        throw `Can not find unit with id ${unitId}`;
    }
    if (unit.side !== state.activeSide) {
        throw `Unit ${unit.label} does not belong to active side`;
    }
    if (unit.isFatigued) {
        throw `Unit ${unit.label} is fatigued`;
    }
    if (!state.unitsSelectedForActivation.find((uid) => uid === unitId)) {
        throw `Unit ${unit.label} is not activated`;
    }
    return stateMachine.changeState(state, 'movingUnit', {
        unitId,
    });
};
