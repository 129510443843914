import UnitCounterAsImage from '@wareena/grid-battles-ui/game/unit/UnitCounterAsImage';
import ExampleActivation from './ExampleActivation';
import ExampleEnemy from './ExampleEnemy';
import ExampleFatigued from './ExampleFatigued';
import ExampleNotEnough from './ExampleNotEnough';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';

export default function ActivationsPage() {
    return (
        <>
            <div className="rules-text">
                <h2 className="title is-2">Activations</h2>
                <p>
                    Each scenario in this game specifies its number of <strong>activations</strong>{' '}
                    per turn. This number means how many units you can activate and command during
                    your <strong>turn</strong>. Most scenarios allow you to activate three units per
                    turn.
                </p>
                <p>
                    When it is your turn, you must first make a choice of which units you wish to
                    activate. <strong>You can move and attack only with activated units.</strong>
                </p>
                <p>
                    You activate units just by clicking on them. The first click on the unit will
                    select it, another one will unselect it. After you select the last one, you will
                    get a chance to either confirm your decision, or to start over.
                </p>
                <p>
                    Try it out on the playfield below. Select three of the units and confirm their
                    activation.
                </p>

                <ExampleActivation />

                <h3 className="title is-3 mt-4">Enemy Units</h3>

                <p>
                    It may sound obvious, but{' '}
                    <strong>you are not able to activate enemy units.</strong>
                </p>
                <p>
                    On the playfield below, your enemy commands all orange units and you have
                    control over the blue ones. Try to activate some of those enemy units and you
                    will find out it is not possible.
                </p>

                <ExampleEnemy />

                <h3 className="title is-3 mt-4">Fatigued Units</h3>

                <p>
                    After you move and/or attack with your unit, it becomes <i>fatigued</i> for the
                    rest of the <i>round</i>. This way, you will be forced to act with all units in
                    your army before you will be able to activate any of them for the second time.
                </p>
                <article className="message">
                    <div className="message-body">
                        <p>
                            Remember: <i>Turn</i> is only a part of a <i>round</i>. Only one player
                            is active during the <i>turn</i>. During his/her turn, the active player
                            activates some of his/her units and has the option to move and/or attack
                            with them.
                        </p>
                        <p>
                            The <i>round</i> is a series of <i>turns</i> when players alternate
                            activating their units. The round ends only when there are no other
                            fresh units to activate or when a victory condition has been achieved.
                        </p>
                    </div>
                </article>

                <p>
                    To provide you with an easy way to distinguish fresh and fatigued units, we use
                    a different color for the background of a unit&apos;s counter. This is how they
                    look in comparison:
                </p>

                <div className="is-flex is-justify-content-flex-start">
                    <figure className="mr-4 is-flex is-flex-direction-column is-align-items-center">
                        <UnitCounterAsImage
                            counterSize={58}
                            unit={createUnit(UnitTypeId.Pikeman)}
                        />
                        <figcaption>Fresh unit</figcaption>
                    </figure>
                    <figure className="mr-4 is-flex is-flex-direction-column is-align-items-center">
                        <UnitCounterAsImage
                            counterSize={58}
                            unit={createUnit(UnitTypeId.Pikeman, { isFatigued: true })}
                            bgColor="#c0c0c0"
                        />
                        <figcaption>Fatigued unit</figcaption>
                    </figure>
                </div>

                <p className="mt-4">
                    Try to activate some of the fatigued units on the playfield below and you will
                    see it is not possible.
                </p>

                <ExampleFatigued />

                <h3 className="title is-3 mt-4">Not Enough Units</h3>

                <p>
                    If you have fewer fresh (or alive) units than the number of activations per
                    turn, you will be able to activate only as much as you have available.
                </p>
                <p>
                    In the example below, the scenario dictates to active 3 units per turn. But only
                    one of your units is fresh and therefore eligible for activation. You will be
                    able to activate only this one.
                </p>

                <ExampleNotEnough />
            </div>
        </>
    );
}
