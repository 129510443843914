import { createPathToTutorialById } from '@local/tutorial/services/routing';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import {
    AttackDieType,
    CombatEffectSymbol,
    DefenseDieType,
    DieSideId,
    DieType,
} from '@wareena/grid-battles-entity/game/codebook/dice';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import BonusSymbolAsImage from '@wareena/grid-battles-ui/game/dice/BonusSymbolAsImage';
import DieIconAsImage from '@wareena/grid-battles-ui/game/dice/DieIconAsImage';
import GameDieSideAsImage from '@wareena/grid-battles-ui/game/dice/GameDieSideAsImage';
import UnitCounter from '@wareena/grid-battles-ui/game/unit/UnitCounter/index';
import DieIcon from '@wareena/grid-battles-ui/game/unit/dice/DieIcon';
import Link from 'next/link';

export default function DicePage() {
    const dieSides: DieSideId[] = [0, 1, 2, 3, 4, 5];
    const dice: { typeId: DieType; name: string }[] = [
        { typeId: AttackDieType.Yellow, name: 'Yellow Attack Die' },
        { typeId: AttackDieType.Blue, name: 'Blue Attack Die' },
        { typeId: AttackDieType.Red, name: 'Red Attack Die' },
        { typeId: DefenseDieType.Brown, name: 'Brown Defense Die' },
        { typeId: DefenseDieType.Grey, name: 'Grey Defense Die' },
        { typeId: DefenseDieType.Black, name: 'Black Defense Die' },
    ];

    return (
        <>
            <div className="rules-text mb-4">
                <h2 className="title is-2">Dice and Combat</h2>

                <p>
                    Combat happens when one unit attacks another. It is resolved by rolling dice,
                    reading and applying the results of this roll.
                </p>

                <h3 className="title is-3">Combat Resolution</h3>

                <p>Combat is resolved in these steps:</p>

                <ol className="list">
                    <li>The Attacker rolls his attack dice</li>
                    <li>The Defender rolls his defense dice</li>
                    <li>Calculate the number of hits and apply the results</li>
                    <li>
                        If{' '}
                        <i>
                            <Link href={createPathToTutorialById('bold-and-battle-back')}>
                                Battle Back
                            </Link>
                        </i>{' '}
                        is possible and both units are still present, then the roles are reversed
                        and the defender can retaliate by following these steps:
                    </li>
                    <ol className="list">
                        <li>The Defender rolls his attack dice</li>
                        <li>The Attacker rolls his defense dice</li>
                        <li>Calculate the number of hits and apply the results</li>
                    </ol>
                </ol>

                <h3 className="title is-3 mt-4">Vocabulary</h3>
                <dl>
                    <dt>Attacker</dt>
                    <dd>The unit which initiated the combat (eg. attacked another unit).</dd>
                </dl>
                <dl className="mt-2">
                    <dt>Defender</dt>
                    <dd>The unit which was attacked in combat.</dd>
                </dl>
                <dl className="mt-2">
                    <dt>Round Attacker</dt>
                    <dd>
                        Attacker in a first round of combat (step #1)
                        <br />
                        or Defender in a second (
                        <Link href={createPathToTutorialById('bold-and-battle-back')}>
                            Battle Back
                        </Link>
                        ) round (step #4.1)
                    </dd>
                </dl>
                <dl className="mt-2">
                    <dt>Round Defender</dt>
                    <dd>
                        Defender in a first round of combat (step #2)
                        <br />
                        or Attacker in a second (
                        <Link href={createPathToTutorialById('bold-and-battle-back')}>
                            Battle Back
                        </Link>
                        ) round (step #4.2)
                    </dd>
                </dl>

                <h3 className="title is-3 mt-4">Symbols on Dice</h3>

                <p>
                    The dice used in this game are six-sided dice with various symbols on each side.
                    There are also dice with blank sides. Each symbol has its own meaning and the
                    results of combat are determined by applying them.
                </p>

                <div className="columns">
                    <div className="column is-1">
                        <BonusSymbolAsImage symbol={CombatEffectSymbol.Damage} size={40} />
                    </div>
                    <div className="column is-5">
                        <p>
                            <strong>Damage</strong>
                        </p>
                        <p>
                            This symbol is present on attack dice and represents a hit against an
                            enemy unit. It can be negated by a <i>shield</i> symbol on an
                            enemy&apos;s defense dice. Each damage symbol rolled which is not
                            negated will result in a loss of one health point of the enemy unit.
                        </p>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-1">
                        <BonusSymbolAsImage symbol={CombatEffectSymbol.Bonus} size={40} />
                    </div>
                    <div className="column is-5">
                        <p>
                            <strong>Bonus damage</strong>
                        </p>
                        <p>
                            You can find this symbol only on attack dice and is treated the same way
                            as the <i>Damage</i> symbol, but only if the <i>Round Attacker</i> has
                            an <i>Advantage.</i> Otherwise, it has no effect.
                        </p>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-1">
                        <BonusSymbolAsImage symbol={CombatEffectSymbol.Shield} size={40} />
                    </div>
                    <div className="column is-5">
                        <p>
                            <strong>Shield</strong>
                        </p>
                        <p>
                            A <i>Shield</i> symbol is present on a defense dice and each symbol
                            rolled can negate the <i>Damage</i> or <i>Bonus Damage</i> symbols
                            rolled on the enemy&apos;s dice.
                        </p>
                    </div>
                </div>

                <h3 className="title is-3">Scored Hits</h3>

                <p>
                    To get a number of scored hits, we need to look at all the symbols rolled and
                    subtract the number of <i>shields</i> rolled from the number of <i>damage</i>{' '}
                    (and possibly also <i>bonus damage</i> symbols)
                </p>

                <p>
                    <i>
                        <strong>scored hits</strong> = <strong>damage</strong> -{' '}
                        <strong>shields</strong>
                    </i>
                </p>

                <p>
                    In the case the attacking unit has the{' '}
                    <Link href={createPathToTutorialById('advantage')}>
                        <i>advantage</i>
                    </Link>{' '}
                    against the defending unit:
                </p>

                <p>
                    <i>
                        <strong>scored hits</strong> = (<strong>damage</strong> +{' '}
                        <strong>bonus damage</strong>) - <strong>shields</strong>
                    </i>
                </p>

                <h3 className="title is-3">Attack Dice</h3>

                <p>
                    Each unit has its own set of attack dice, but most units have only one attack
                    die. You can determine the number and type of dice just by looking at the
                    top-right side of a unit&apos;s counter.
                </p>

                <div className="columns">
                    <div className="column is-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={125} height={63}>
                            <UnitCounter
                                counterSize={60}
                                origin={{ x: 5, y: 0 }}
                                unit={createUnit(UnitTypeId.LightCavalry)}
                            />
                            <DieIcon
                                type={AttackDieType.Yellow}
                                size={25}
                                origin={{ x: 90, y: 10 }}
                            />
                            <circle
                                cx={58}
                                cy={22}
                                r={10}
                                fill="transparent"
                                stroke="red"
                                strokeWidth={2}
                            />
                            <line x1={58} y1={12} x2={100} y2={2} stroke="red" strokeWidth={2} />
                            <line x1={58} y1={32} x2={100} y2={42} stroke="red" strokeWidth={2} />
                            <circle
                                cx={102}
                                cy={22}
                                r={20}
                                fill="transparent"
                                stroke="red"
                                strokeWidth={2}
                            />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            <i>Light Cavalry</i> uses the single yellow die when resolving its
                            attack rolls.
                        </p>
                    </div>
                </div>

                <h3 className="title is-3 mt-4">Defense Dice</h3>

                <p>
                    The set of defense dice used by a unit is shown in the bottom left corner of its
                    counter.
                </p>

                <div className="columns">
                    <div className="column is-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={125} height={63}>
                            <UnitCounter
                                counterSize={60}
                                origin={{ x: 5, y: 0 }}
                                unit={createUnit(UnitTypeId.LightCavalry)}
                            />
                            <DieIcon
                                type={DefenseDieType.Brown}
                                size={25}
                                origin={{ x: 90, y: 30 }}
                            />
                            <circle
                                cx={58}
                                cy={52}
                                r={10}
                                fill="transparent"
                                stroke="red"
                                strokeWidth={2}
                            />
                            <line x1={58} y1={42} x2={98} y2={22} stroke="red" strokeWidth={2} />
                            <line x1={58} y1={62} x2={98} y2={62} stroke="red" strokeWidth={2} />
                            <circle
                                cx={102}
                                cy={42}
                                r={20}
                                fill="transparent"
                                stroke="red"
                                strokeWidth={2}
                            />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            A single brown die is rolled by <i>Light Cavalry</i> when defending
                            against attacks.
                        </p>
                    </div>
                </div>

                <h3 className="title is-3">Bonus Dice &amp; Bonus Symbols</h3>

                <p>
                    Some terrain can provide the attacker and/or defender in combat with bonus dice
                    or even symbols.
                </p>
                <p>
                    If a bonus die is provided it is added to the pool of dice to be rolled before
                    the roll is made. If a symbol is provided, it counts as if it was present on a
                    dice roll result.
                </p>

                <p>The table below summarizes when a bonus is applied, how and to which side.</p>

                <table className="table is-striped is-narrow">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Bonus</th>
                            <th>Applied to</th>
                            <th>When</th>
                            <th>How</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <DieIconAsImage size={25} dieType={AttackDieType.Yellow} />{' '}
                                <DieIconAsImage size={25} dieType={AttackDieType.Blue} />{' '}
                                <DieIconAsImage size={25} dieType={AttackDieType.Red} />
                            </td>
                            <td>Yellow, Blue or Red Attack Die</td>
                            <td>Round Attacker</td>
                            <td>Before the roll is made</td>
                            <td>Added to attack dice poll</td>
                        </tr>
                        <tr>
                            <td>
                                <DieIconAsImage size={25} dieType={DefenseDieType.Brown} />{' '}
                                <DieIconAsImage size={25} dieType={DefenseDieType.Grey} />{' '}
                                <DieIconAsImage size={25} dieType={DefenseDieType.Black} />
                            </td>
                            <td>Brown, Grey or Black Defense Die</td>
                            <td>Round Defender</td>
                            <td>Before the roll is made</td>
                            <td>Added to attack dice poll</td>
                        </tr>
                        <tr>
                            <td>
                                <BonusSymbolAsImage symbol={CombatEffectSymbol.Damage} size={25} />
                            </td>
                            <td>Damage</td>
                            <td>Round Attacker</td>
                            <td>After the roll is made</td>
                            <td>Added to the result of attack dice roll</td>
                        </tr>
                        <tr>
                            <td>
                                <BonusSymbolAsImage symbol={CombatEffectSymbol.Bonus} size={25} />
                            </td>
                            <td>Bonus Damage</td>
                            <td>
                                Round Attacker with{' '}
                                <Link href={createPathToTutorialById('advantage')}>
                                    <i>Advantage</i>
                                </Link>
                            </td>
                            <td>After the roll is made</td>
                            <td>Added to the result of attack dice roll</td>
                        </tr>
                        <tr>
                            <td>
                                <BonusSymbolAsImage symbol={CombatEffectSymbol.Shield} size={25} />
                            </td>
                            <td>Shield</td>
                            <td>Round Defender</td>
                            <td>After the roll is made</td>
                            <td>Added to the result of defense dice roll</td>
                        </tr>
                    </tbody>
                </table>

                <h3 className="title is-3">List of Dice</h3>

                <p>
                    Below you can see the list of all dice used in combat and what their respective
                    sides look like. We use six-sided dice. Attack dice can contain various number
                    of <i>Damage</i> or <i>Bonus Damage</i> symbols on each of their side. Defense
                    dice contain only <i>Shield</i> symbols and some of their sides can be blank.
                </p>

                <div className="dice-grid">
                    {dice.map((dieInfo) => (
                        <div className="card" key={dieInfo.typeId}>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className="image is-48x48">
                                            <DieIconAsImage size={40} dieType={dieInfo.typeId} />
                                        </figure>
                                    </div>
                                    <div className="media-content">
                                        <h4 className="title is-4">{dieInfo.name}</h4>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="is-flex is-align-items-center mb-1 has-small-column-gap">
                                        {dieSides.map((sideId) => (
                                            <GameDieSideAsImage
                                                key={sideId}
                                                size={45}
                                                side={sideId}
                                                dieType={dieInfo.typeId}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
