import React from 'react';
import { selectUnitForActivation, unselectUnitForActivation, setStateName, unselectAllUnitsForActivation, } from '@wareena/grid-battles-engine/gameStateMachine/request';
import Hex from '@wareena/ui/game/map/Hex';
import UnitCounter from '../unit/UnitCounter/index.js';
import OpponentsTurnInstruction from './helper/OpponentsTurnInstruction.js';
import ActionPanelContainer, { ActionPanelButtons, ActionPanelPreview, ActionPanelText, } from './helper/ActionPanelContainer/index.js';
const UnitSelectedForActivation = ({ unit, hexProperties, onClick = null, isLoggedInPlayerActive = false, }) => {
    const center = hexProperties.calculateCenterPos(unit.position.column, unit.position.row);
    const style = {
        fill: 'rgba(0, 0, 0, 0)',
        stroke: 'yellow',
        strokeWidth: '3px',
    };
    if (isLoggedInPlayerActive) {
        style.cursor = 'pointer';
    }
    return (React.createElement(Hex, { center: center, size: hexProperties.size, key: `units-selected-for-activation-${unit.position.column}-${unit.position.row}`, isFlatTop: hexProperties.isFlatTop, dimensions: {
            width: hexProperties.width,
            height: hexProperties.height,
        }, style: style, onClick: () => {
            onClick?.(unit);
        } }));
};
const createComponents = ({ stateMachine, scenario, state, isLoggedInPlayerActive, hexProperties, activePlayer, loggedInPlayerId, }) => {
    const isUnitSelected = (unitId) => {
        return state.unitsSelectedForActivation.find((id) => id === unitId);
    };
    const numAlreadySelected = state.unitsSelectedForActivation.length;
    const unitsEligibleForActivation = numAlreadySelected === scenario.activationsPerTurn
        ? []
        : state.units.filter((u) => {
            if (u.side === activePlayer.side && !u.isFatigued && !isUnitSelected(u.id)) {
                return true;
            }
            return false;
        });
    const selectUnit = (unit) => {
        stateMachine.executeRequest(selectUnitForActivation(unit.id, loggedInPlayerId), state);
    };
    const unselectUnit = (unit) => {
        stateMachine.executeRequest(unselectUnitForActivation(unit.id, loggedInPlayerId), state);
    };
    const unselectAll = () => {
        stateMachine.executeRequest(unselectAllUnitsForActivation(loggedInPlayerId), state);
    };
    const commitSelection = () => {
        stateMachine.executeRequest(setStateName('selectingForMovement', loggedInPlayerId), state);
    };
    const ActiveLayer = () => {
        if (!isLoggedInPlayerActive) {
            const hexes = [];
            state.unitsSelectedForActivation.forEach((unitId) => {
                const unit = state.units.find((u) => u.id === unitId);
                if (!unit) {
                    return;
                }
                hexes.push(React.createElement(UnitSelectedForActivation, { hexProperties: hexProperties, unit: unit, isLoggedInPlayerActive: isLoggedInPlayerActive, key: `activeLayer-${unit.position.column}-${unit.position.row}` }));
            });
            if (hexes.length) {
                return React.createElement("g", null, hexes);
            }
            return null;
        }
        const hexes = [];
        unitsEligibleForActivation.forEach((unit) => {
            const center = hexProperties.calculateCenterPos(unit.position.column, unit.position.row);
            hexes.push(React.createElement(Hex, { center: center, size: hexProperties.size, key: `eligibleForActivation-${unit.position.column}-${unit.position.row}`, isFlatTop: hexProperties.isFlatTop, dimensions: {
                    width: hexProperties.width,
                    height: hexProperties.height,
                }, style: {
                    cursor: 'pointer',
                    fill: 'rgba(0, 0, 0, 0)',
                    stroke: 'rgba(0, 0, 0, 0)',
                }, onClick: () => {
                    selectUnit(unit);
                } }));
        });
        state.unitsSelectedForActivation.forEach((unitId) => {
            const unit = state.units.find((u) => u.id === unitId);
            if (!unit) {
                return;
            }
            hexes.push(React.createElement(UnitSelectedForActivation, { hexProperties: hexProperties, unit: unit, isLoggedInPlayerActive: isLoggedInPlayerActive, onClick: unselectUnit, key: `selectedForActivation-${unit.position.column}-${unit.position.row}` }));
        });
        if (!hexes.length) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("g", null, hexes)));
    };
    const Instruction = () => {
        if (!isLoggedInPlayerActive) {
            return React.createElement(OpponentsTurnInstruction, null);
        }
        return React.createElement("p", null,
            "Select ",
            scenario.activationsPerTurn,
            " of your units to activate this turn.");
    };
    let ActionPanel = undefined;
    if (isLoggedInPlayerActive &&
        (numAlreadySelected === scenario.activationsPerTurn ||
            unitsEligibleForActivation.length === 0)) {
        ActionPanel = function ActionPanel() {
            const counterSize = 60;
            const counters = [];
            state.unitsSelectedForActivation.forEach((unitId) => {
                const unit = state.units.find((u) => u.id === unitId);
                if (!unit) {
                    return;
                }
                counters.push(React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: counterSize + 6, height: counterSize + 6, key: `selected-${unit.id}` },
                    React.createElement("g", { style: {
                            cursor: 'pointer',
                        }, onClick: () => {
                            unselectUnit(unit);
                        } },
                        React.createElement(UnitCounter, { origin: { x: 3, y: 3 }, counterSize: counterSize, unit: unit }))));
            });
            return (React.createElement(React.Fragment, null,
                React.createElement(ActionPanelContainer, null,
                    React.createElement(ActionPanelText, null, state.unitsSelectedForActivation.length === 1
                        ? `Activate this unit?`
                        : `Activate these ${state.unitsSelectedForActivation.length} units?`),
                    React.createElement(ActionPanelPreview, null, counters),
                    React.createElement(ActionPanelButtons, null,
                        React.createElement("button", { className: "button is-primary", onClick: () => commitSelection() },
                            React.createElement("span", { className: "icon-text" },
                                React.createElement("span", { className: "icon" },
                                    React.createElement("i", { className: "fas fa-bolt" })),
                                React.createElement("span", null, "Activate"))),
                        React.createElement("button", { className: "button is-secondary", onClick: () => unselectAll() }, "No, select other")))));
        };
    }
    return {
        ActiveLayer,
        Instruction,
        ActionPanel,
    };
};
export default createComponents;
