import React from 'react';
import { getDimensions } from '@wareena/game-algorithms';
import DieIcon from '../unit/dice/DieIcon.js';
export default function DieIconAsImage({ size, dieType, onClick, }) {
    const imageWidth = size;
    const gameDieSize = (imageWidth * 3) / 4;
    const gameDieDimensions = getDimensions((gameDieSize * 3) / 5, false);
    const onClickProps = onClick
        ? {
            onClick,
            style: { cursor: 'pointer' },
        }
        : {};
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: gameDieDimensions.width + gameDieSize / 20, height: gameDieDimensions.height + gameDieSize / 7, ...onClickProps },
        React.createElement(DieIcon, { type: dieType, origin: { x: gameDieSize / 20, y: gameDieSize / 7 }, size: gameDieSize })));
}
