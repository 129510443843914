import { isUserActive } from '../utils/index.js';
import createRules from '../../../rules/index.js';
export default function selectDefender({ request, state, game, hexProperties, }) {
    const { unitId, userId } = request.payload;
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
    const unit = state.units.find((u) => u.id === unitId);
    if (!unit) {
        throw `Can not find unit with id ${unitId}`;
    }
    if (!state.currentCombatId) {
        throw `There is no current combat`;
    }
    const currentCombat = state.combats?.find((c) => c.id === state.currentCombatId);
    if (!currentCombat) {
        throw `There is no current combat`;
    }
    const attacker = state.units.find((u) => u.id === currentCombat.attackerId);
    if (!attacker) {
        throw `Can not find unit with id ${currentCombat.attackerId}`;
    }
    if (unit.side === state.activeSide) {
        throw `Unit ${unit.label} can not belong to the active side`;
    }
    const rules = createRules(game.hexes, hexProperties);
    const targets = rules.getPossibleTargets(attacker, state.units);
    if (!targets?.length) {
        throw `Attacker ${attacker.label} has no valid targets`;
    }
    let isValidTarget = false;
    targets.forEach((target) => {
        if (target.column === unit.position.column && target.row === unit.position.row) {
            isValidTarget = true;
        }
    });
    if (!isValidTarget) {
        throw `Unit ${unit.label} is not valid target for ${attacker.label}`;
    }
    return {
        ...state,
        combats: state.combats?.map((combat) => {
            if (combat.id !== state.currentCombatId) {
                return combat;
            }
            return {
                ...combat,
                defenderId: unit.id,
                defender: JSON.parse(JSON.stringify(unit)),
                isDefenderBold: rules.isUnitBold(unit, state),
                isBattleBackPossible: rules.isBattleBackPossible(attacker, unit, state),
            };
        }),
    };
}
