import React from 'react';
export default function Hits({ hits, size = 12 }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { style: {
                display: 'flex',
                alignItems: 'center',
                fontSize: `${size * 1.25}px`,
                gap: '0.25rem',
            } },
            React.createElement("span", null, hits <= 0 ? '-0' : `-${hits}`),
            React.createElement(SvgHeart, { size: size }))));
}
export function SvgHeart({ size }) {
    const start = {
        x: size / 2,
        y: size / 4,
    };
    const radius = (size - 2) / 4;
    return (React.createElement("svg", { width: size, height: size, viewBox: `0 0 ${size} ${size}` },
        React.createElement("path", { d: `M ${start.x} ${start.y} a ${radius} ${radius} 0 0 1 ${radius * 2} 0 q 0 ${radius * 2} -${radius * 2} ${radius * 3} q -${radius * 2} -${radius} -${radius * 2} -${radius * 3} a ${radius} ${radius} 0 0 1 ${radius * 2} 0`, stroke: "rgb(145, 75, 75)", strokeWidth: "1", fill: "rgb(242, 75, 75)" })));
}
