import Link from 'next/link';
import ExampleEligibility from './ExampleEligibility';
import { createPathToTutorial } from '@local/tutorial/services/routing';
import terrainMap from '@wareena/grid-battles-ui/game/terrain/index';
import { TerrainId } from '@wareena/grid-battles-entity/game/map/terrain';
import { createHexProperties } from '@wareena/game-algorithms';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import UnitCounter from '@wareena/grid-battles-ui/game/unit/UnitCounter/index';
import { calculateCounterOrigin } from '@wareena/grid-battles-ui/game/unit/services/counter';
import EligibleUnitMarker from '@wareena/grid-battles-ui/game/marker/EligibleUnitMarker';
import SelectedUnitMarker from '@wareena/grid-battles-ui/game/marker/SelectedAttackerMarker';
import PossibleTargetMarker from '@wareena/grid-battles-ui/game/marker/PossibleTargetMarker';
import SelectedDefenderMarker from '@wareena/grid-battles-ui/game/marker/SelectedDefenderMarker';
import ConfirmationIcon from '@wareena/grid-battles-ui/game/iconButton/counterOverlay/ConfirmationIcon';
import CancelIcon from '@wareena/grid-battles-ui/game/iconButton/counterOverlay/CancelIcon';
import { getPageById } from '@local/tutorial/services/tutorialRepository';

export default function CombatPage() {
    const diceTutorialPage = getPageById('dice')!;

    const hexProperties = createHexProperties({
        hexSize: 50,
        isFlatTop: false,
        isFirstOffsetted: false,
    });

    const terrainHexCenter = hexProperties.calculateCenterPos(0, 0);

    const padding = 3;

    const terrainImgWidth = hexProperties.width + 2 * padding;
    const terrainImgHeight = hexProperties.height + 2 * padding;

    const TerrainHex = terrainMap[TerrainId.Plain];

    const counterSize = hexProperties.isFlatTop
        ? hexProperties.height / 1.5
        : hexProperties.width / 1.5;

    const centerPos = hexProperties.calculateCenterPos(0, 0);
    const counterOrigin = calculateCounterOrigin(centerPos, counterSize);

    return (
        <>
            <div className="rules-text">
                <h2 className="title is-2">Combat Basics</h2>

                <p>
                    The combat phase immediately follows the movement phase. After your units finish
                    their movement, if they are in a position to attack an enemy, they will have a
                    chance to do so. Some units are able to attack only an adjacent enemy (
                    <strong>melee</strong>), and some can attack at a distance (
                    <strong>ranged</strong>).
                </p>

                <h3 className="title is-3">Combat Steps</h3>

                <ol className="list">
                    <li>
                        Select one of your units eligible for an attack. Eligible means they were
                        activated this turn and are able to reach an enemy with their weapon.
                        Eligible units are marked with a yellow hexagon.
                    </li>
                    <li>
                        Select one of the possible targets. The system will mark them with a red
                        hexagon.
                    </li>
                    <li>Confirm unit selection. Or cancel and return to step 2.</li>
                    <li>
                        You will be shown details of the imminent combat with the option to either
                        attack an enemy or choose another one (return to step 2).
                    </li>
                    <li>
                        After you click an &quot;Attack&quot; button, you will be shown the results
                        of the attack.
                    </li>
                    <li>While you have another eligible unit, repeat from step 1.</li>
                </ol>

                <p className="mt-4">
                    Remember that during your turn each of your activated units can attack only
                    once. After that it becomes <i>fatigued</i> and its activation is over.
                </p>

                <p>
                    Combat is resolved by throwing dice. Each unit has its own set of dice for an
                    attack and another set for its defense. You can learn more about it in{' '}
                    <Link href={createPathToTutorial(diceTutorialPage.numId)}>
                        another page of this tutorial.
                    </Link>
                </p>

                <h3 className="title is-3">Example</h3>

                <p>
                    In the example below, you have a situation where you activated three units (
                    <i>Axeman 1</i>, <i>Light Cav</i> and <i>Archer</i>) and moved all three of them
                    to the position from which they are able to attack.
                </p>
                <ul className="list has-disc">
                    <li>
                        <i>Axeman 1</i> is a melee unit and is able to attack only enemy in an
                        adjacent hex. There is only one possible target for him: <i>Pikeman 2</i>.
                    </li>
                    <li>
                        <i>Light Cav</i> is also a melee unit, but it has two enemies adjacent (
                        <i>Pikeman 2</i> and <i>Pikeman 3</i>), so it will be able to choose which
                        one to attack.
                    </li>
                    <li>
                        <i>Archer</i> is a ranged unit, so is able to attack an enemy at a distance.
                        But he must be able to see them and must be in range of his weapon. There
                        are only two enemies he has a clear line of sight to and both of them are in
                        range. So he can attack either <i>Pikeman 3</i> or <i>Pikeman 1</i>.
                    </li>
                </ul>

                <p className="mt-4">
                    The interface tries to show you all the available options and thus simplifies
                    the decision-making process. So when you click on one of your units eligible for
                    an attack, it will highlight all possible targets. In the case of ranged units,
                    it will also show you all hexes that are in range and line of sight.
                </p>

                <ExampleEligibility />

                <h3 className="title is-3 mt-4">Symbols</h3>
                <div className="columns">
                    <div className="column is-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={terrainImgWidth}
                            height={terrainImgHeight}
                        >
                            <TerrainHex
                                isFlatTop={false}
                                size={hexProperties.size}
                                center={terrainHexCenter}
                                dimensions={{
                                    width: hexProperties.width,
                                    height: hexProperties.height,
                                }}
                            />
                            <UnitCounter
                                counterSize={counterSize}
                                origin={counterOrigin}
                                unit={createUnit(UnitTypeId.LightCavalry)}
                            />
                            <EligibleUnitMarker
                                position={{ column: 0, row: 0 }}
                                hexProperties={hexProperties}
                            />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            A simple golden hexagon around your unit is an indication of which of
                            your activated units is able to make an attack.
                        </p>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={terrainImgWidth}
                            height={terrainImgHeight}
                        >
                            <TerrainHex
                                isFlatTop={false}
                                size={hexProperties.size}
                                center={terrainHexCenter}
                                dimensions={{
                                    width: hexProperties.width,
                                    height: hexProperties.height,
                                }}
                            />
                            <UnitCounter
                                counterSize={counterSize}
                                origin={counterOrigin}
                                unit={createUnit(UnitTypeId.LightCavalry)}
                            />
                            <SelectedUnitMarker
                                position={{ column: 0, row: 0 }}
                                hexProperties={hexProperties}
                            />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            After you choose which unit will attack, it will be highlighted by a
                            yellow overlay. A click on this overlay will unselect the unit and you
                            can choose another one (if another is eligible).
                        </p>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={terrainImgWidth}
                            height={terrainImgHeight}
                        >
                            <TerrainHex
                                isFlatTop={false}
                                size={hexProperties.size}
                                center={terrainHexCenter}
                                dimensions={{
                                    width: hexProperties.width,
                                    height: hexProperties.height,
                                }}
                            />
                            <UnitCounter
                                counterSize={counterSize}
                                origin={counterOrigin}
                                unit={createUnit(UnitTypeId.LightCavalry)}
                            />
                            <PossibleTargetMarker
                                position={{ column: 0, row: 0 }}
                                hexProperties={hexProperties}
                            />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            A red hexagon around the enemy unit is used to mark all units which can
                            be a target for your attack.
                        </p>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={terrainImgWidth}
                            height={terrainImgHeight}
                        >
                            <TerrainHex
                                isFlatTop={false}
                                size={hexProperties.size}
                                center={terrainHexCenter}
                                dimensions={{
                                    width: hexProperties.width,
                                    height: hexProperties.height,
                                }}
                            />
                            <UnitCounter
                                counterSize={counterSize}
                                origin={counterOrigin}
                                unit={createUnit(UnitTypeId.LightCavalry)}
                            />
                            <SelectedDefenderMarker
                                position={{ column: 0, row: 0 }}
                                hexProperties={hexProperties}
                            />
                            <ConfirmationIcon
                                counterSize={counterSize}
                                counterOrigin={counterOrigin}
                            />
                            <CancelIcon counterSize={counterSize} counterOrigin={counterOrigin} />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            Your selected target is marked with a red overlay and two icon buttons
                            become available to you to either confirm an attack (&quot;tick&quot;{' '}
                            icon) or select another target (&quot;cross&quot; icon).
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
