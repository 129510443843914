import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
const makeActivatedUnitsFatigued = (units, activatedUnitsIds) => {
    return units.map((unit) => {
        if (activatedUnitsIds.includes(unit.id)) {
            return {
                ...unit,
                isFatigued: true,
            };
        }
        return unit;
    });
};
const makeAllUnitsFresh = (units) => {
    return units.map((unit) => {
        return {
            ...unit,
            isFatigued: false,
            movementPoints: unit.maxMovementPoints,
        };
    });
};
const calcNumOfFreshUnits = (units) => {
    const numOfFreshUnits = {
        [SideId.A]: 0,
        [SideId.B]: 0,
    };
    units.forEach((unit) => {
        if (!unit.isFatigued) {
            numOfFreshUnits[unit.side] += 1;
        }
    });
    return numOfFreshUnits;
};
export const endTurn = ({ state, scenario, stateMachine, }) => {
    let round = state.round;
    let nextStateUnits = makeActivatedUnitsFatigued(state.units, state.unitsSelectedForActivation);
    const otherSideId = state.activeSide === SideId.A ? SideId.B : SideId.A;
    const activeSideId = state.activeSide;
    const numOfFreshUnits = calcNumOfFreshUnits(nextStateUnits);
    let nextSideId = otherSideId;
    const allOfCurrentSideUnitsAreFatigued = numOfFreshUnits[activeSideId] === 0;
    const allOfOtherSideUnitsAreFatigued = numOfFreshUnits[otherSideId] === 0;
    if (allOfOtherSideUnitsAreFatigued) {
        if (allOfCurrentSideUnitsAreFatigued) {
            round = round + 1;
            nextSideId = scenario.startingSide;
            nextStateUnits = makeAllUnitsFresh(nextStateUnits);
        }
        else {
            nextSideId = activeSideId;
        }
    }
    const nextState = {
        ...state,
        units: nextStateUnits,
        unitsSelectedForActivation: [],
        movingUnits: null,
        currentCombatId: null,
        round,
        activeSide: nextSideId,
    };
    return stateMachine.changeState(nextState, 'selectingForActivation');
};
