import selectingForActivation from './selectingForActivation.js';
import selectingForMovement from './selectingForMovement.js';
import movingUnit from './movingUnit.js';
import selectingAttacker from './selectingAttacker.js';
import selectingDefender from './selectingDefender/index.js';
import gameOver from './gameOver.js';
import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
const factories = {
    selectingForActivation,
    selectingForMovement,
    movingUnit,
    selectingAttacker,
    selectingDefender,
    gameOver,
};
const createStateComponents = (params) => {
    const { state } = params;
    const stateName = state.stateName || 'selectingForActivation';
    const playerA = params.game.players.find((p) => p.side === SideId.A);
    const playerB = params.game.players.find((p) => p.side === SideId.B);
    const activeSide = state.activeSide || params.scenario.startingSide;
    const activePlayer = activeSide === SideId.A ? playerA : playerB;
    const isCurrentPlayerActive = activePlayer.id === params.loggedInPlayerId;
    const nullFactory = () => {
        return {};
    };
    const factory = factories[stateName] || nullFactory;
    const result = factory({
        ...params,
        playerA,
        playerB,
        activeSide,
        activePlayer,
        isLoggedInPlayerActive: isCurrentPlayerActive,
    });
    return {
        ActiveLayer: () => null,
        Instruction: () => null,
        Buttons: () => null,
        Preview: () => null,
        getUnitReplacement: () => null,
        ...(result ? result : {}),
    };
};
export default createStateComponents;
