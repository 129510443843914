export default function convertMapToScenarioFormat(map) {
    const scenarioMap = [];
    map.forEach((hex, idx) => {
        if (!scenarioMap[hex.column]) {
            scenarioMap[hex.column] = [];
        }
        scenarioMap[hex.column][hex.row] = {
            id: idx,
            ...hex,
        };
    });
    return scenarioMap;
}
