import React from 'react';
import { AttackType } from '@wareena/grid-battles-entity/game/codebook/attackTypes';
import { getAngleBetweenNeighbours, isSamePosition, } from '@wareena/game-algorithms';
import UnitCounter from '../unit/UnitCounter/index.js';
import HexOnPosition from '@wareena/ui/game/map/HexOnPosition';
import Arrow from '@wareena/ui/game/markers/Arrow';
import Hex from '@wareena/ui/game/map/Hex';
import { isPassableTerrain } from '@wareena/grid-battles-entity/game/map/terrain';
import { calculateCounterOrigin } from '../unit/services/counter.js';
import { finishMovementOfUnit, moveUnit, returnToPathLeg, stayAtOriginalPosition, } from '@wareena/grid-battles-engine/gameStateMachine/request';
import createRules from '@wareena/grid-battles-engine/rules';
import FieldOfView from '../map/FieldOfView.js';
import ConfirmationIcon from '../iconButton/counterOverlay/ConfirmationIcon.js';
import EligibleUnitMarker from '../marker/EligibleUnitMarker.js';
import OpponentsTurnInstruction from './helper/OpponentsTurnInstruction.js';
function MovingUnitCounter({ position, hexProperties, unit, movementPoints, onConfirm, getBoldStatus = () => false, }) {
    const counterSize = hexProperties.isFlatTop
        ? hexProperties.height / 1.5
        : hexProperties.width / 1.5;
    const center = hexProperties.calculateCenterPos(position.column, position.row);
    const counterOrigin = calculateCounterOrigin(center, counterSize);
    return (React.createElement("g", null,
        React.createElement(UnitCounter, { origin: counterOrigin, counterSize: counterSize, unit: {
                ...unit,
                movementPoints,
            }, isBold: getBoldStatus(unit) }),
        React.createElement(ConfirmationIcon, { counterSize: counterSize, counterOrigin: counterOrigin, onClick: onConfirm })));
}
function isPositionOnPath(position, path) {
    return path.find((leg) => {
        if (leg?.to.column === position.column && leg?.to.row === position.row) {
            return true;
        }
        return false;
    });
}
const createComponents = function createComponents({ stateMachine, game, state, hexProperties, isLoggedInPlayerActive, loggedInPlayerId, }) {
    const rules = createRules(game.hexes, hexProperties);
    const selectedUnit = state.units.find((unit) => unit.id === state.movingUnits.currentlyMovingId);
    if (!selectedUnit) {
        return null;
    }
    if (!state.movingUnits) {
        throw new Error('state.movingUnits is undefined and should not be');
    }
    const movementData = state.movingUnits.units.find((d) => d.id === selectedUnit.id);
    if (!movementData) {
        throw new Error('There should be some movement data available...');
    }
    const lastLeg = movementData.path[movementData.path.length - 1];
    const getPossibleLegs = (neighbours, pathAlreadyTaken) => {
        if (movementData.currentMovementPoints <= 0) {
            return [];
        }
        return neighbours.filter((neighbour) => {
            const hex = game.hexes.find((h) => isSamePosition(neighbour, h));
            if (!hex) {
                return false;
            }
            const lastLeg = pathAlreadyTaken[pathAlreadyTaken.length - 1];
            const movementCost = rules.getMovementCost(lastLeg.to, neighbour, selectedUnit);
            if (movementCost === null) {
                return false;
            }
            return (!rules.getUnitOnPosition(neighbour, state.units) &&
                !isPositionOnPath(neighbour, pathAlreadyTaken) &&
                isPassableTerrain(hex.terrainId) &&
                movementCost <= movementData.currentMovementPoints);
        });
    };
    const onMove = (position) => {
        stateMachine.executeRequest(moveUnit(selectedUnit.id, position, loggedInPlayerId), state);
    };
    const onReturnToLeg = (position) => {
        stateMachine.executeRequest(returnToPathLeg(selectedUnit.id, position, loggedInPlayerId), state);
    };
    const onFinishMovement = () => {
        stateMachine.executeRequest(finishMovementOfUnit(selectedUnit.id, loggedInPlayerId), state);
    };
    const onStayAtOriginPosition = () => {
        stateMachine.executeRequest(stayAtOriginalPosition(selectedUnit.id, loggedInPlayerId), state);
    };
    const OtherActiveUnits = () => {
        const hexes = [];
        state.unitsSelectedForActivation.forEach((unitId) => {
            const unit = state.units.find((u) => u.id === unitId);
            if (!unit) {
                return;
            }
            if (unit.id === selectedUnit.id) {
                //return;
            }
            hexes.push(React.createElement(EligibleUnitMarker, { key: `otherActiveUnitHex-${unit.position.column}-${unit.position.row}`, position: unit.position, hexProperties: hexProperties }));
        });
        return React.createElement("g", null, hexes);
    };
    const ActiveLayerForPassivePlayer = () => {
        const arrowPath = [];
        if (movementData.path.length > 1) {
            const counterSize = hexProperties.isFlatTop
                ? hexProperties.height / 1.5
                : hexProperties.width / 1.5;
            const lastLegCenter = hexProperties.calculateCenterPos(lastLeg.to.column, lastLeg.to.row);
            const counterOrigin = calculateCounterOrigin(lastLegCenter, counterSize);
            arrowPath.push(React.createElement(UnitCounter, { key: `shadow-counter-${lastLeg.to.column}-${lastLeg.to.row}`, origin: counterOrigin, counterSize: counterSize, unit: {
                    ...selectedUnit,
                    movementPoints: movementData.currentMovementPoints,
                }, isBold: rules.isUnitBold(selectedUnit, state) }));
        }
        movementData.path.forEach((data) => {
            if (!data.from) {
                return;
            }
            const angle = getAngleBetweenNeighbours({
                fromPosition: data.from,
                toPosition: data.to,
                isFlatTop: hexProperties.isFlatTop,
                isFirstOffsetted: hexProperties.isFirstOffsetted,
            });
            if (angle === null) {
                return;
            }
            const centerTo = hexProperties.calculateCenterPos(data.to.column, data.to.row);
            const centerFrom = hexProperties.calculateCenterPos(data.from.column, data.from.row);
            const vector = {
                x: (centerTo.x - centerFrom.x) * 0.7,
                y: (centerTo.y - centerFrom.y) * 0.7,
            };
            const center = {
                x: centerFrom.x + vector.x,
                y: centerFrom.y + vector.y,
            };
            arrowPath.push(React.createElement(Arrow, { key: `arrow-path${data.to.column}-${data.to.row}`, angle: angle, tipPoint: center, baseSize: hexProperties.size * 0.8, style: { opacity: 0.5 } }));
        });
        return (React.createElement("g", null,
            React.createElement(OtherActiveUnits, null),
            arrowPath));
    };
    const ActiveLayer = () => {
        if (!isLoggedInPlayerActive) {
            return React.createElement(ActiveLayerForPassivePlayer, null);
        }
        const hexes = [];
        hexes.push(React.createElement(OtherActiveUnits, { key: "active-units" }));
        if (movementData.path.length <= 1) {
            hexes.push(React.createElement(EligibleUnitMarker, { position: selectedUnit.position, hexProperties: hexProperties, key: `movingUnitActiveHex-${selectedUnit.position.column}-${selectedUnit.position.row}` }));
        }
        const lastLeg = movementData.path[movementData.path.length - 1];
        const arrowPath = [];
        if (selectedUnit.attackType === AttackType.Ranged) {
            hexes.unshift(React.createElement(FieldOfView, { key: `field-of-view-${selectedUnit.id}`, hexProperties: hexProperties, gameState: {
                    ...state,
                    units: state.units.filter((u) => u.id !== selectedUnit.id),
                }, rules: rules, unit: { ...selectedUnit, position: lastLeg.to } }));
        }
        if (movementData.path.length > 1) {
            arrowPath.push(React.createElement(HexOnPosition, { position: selectedUnit.position, hexProperties: hexProperties, key: `path-${selectedUnit.position.column}-${selectedUnit.position.row}`, style: {
                    cursor: 'pointer',
                    fill: 'rgba(0, 0, 0, 0)',
                    strokeWidth: 0,
                }, onClick: () => onReturnToLeg(selectedUnit.position) }));
        }
        movementData.path.forEach((data, idx) => {
            if (!data.from) {
                return;
            }
            const angle = getAngleBetweenNeighbours({
                fromPosition: data.from,
                toPosition: data.to,
                isFlatTop: hexProperties.isFlatTop,
                isFirstOffsetted: hexProperties.isFirstOffsetted,
            });
            if (angle === null) {
                return;
            }
            const centerTo = hexProperties.calculateCenterPos(data.to.column, data.to.row);
            const centerFrom = hexProperties.calculateCenterPos(data.from.column, data.from.row);
            const vector = {
                x: (centerTo.x - centerFrom.x) * 0.7,
                y: (centerTo.y - centerFrom.y) * 0.7,
            };
            const center = {
                x: centerFrom.x + vector.x,
                y: centerFrom.y + vector.y,
            };
            arrowPath.push(React.createElement(Arrow, { key: `arrow-path${data.to.column}-${data.to.row}`, angle: angle, tipPoint: center, baseSize: hexProperties.size * 0.8, style: { cursor: 'pointer', opacity: 0.5 } }));
            if (idx < movementData.path.length - 1) {
                arrowPath.push(React.createElement(HexOnPosition, { position: data.to, key: `path-${data.to.column}-${data.to.row}`, hexProperties: hexProperties, style: {
                        cursor: 'pointer',
                        fill: 'rgba(0, 0, 0, 0)',
                    }, onClick: () => onReturnToLeg(data.to) }));
            }
        });
        hexes.push(...arrowPath);
        if (movementData.path.length > 1) {
            hexes.push(React.createElement(MovingUnitCounter, { key: `moving-unit-counter-${lastLeg.to.column}-${lastLeg.to.row}`, position: lastLeg.to, unit: selectedUnit, movementPoints: movementData.currentMovementPoints, hexProperties: hexProperties, onConfirm: onFinishMovement, getBoldStatus: (unit) => {
                    return rules.willUnitOnPositionBeBold(unit, lastLeg.to, state);
                } }));
        }
        else {
            const counterSize = hexProperties.isFlatTop
                ? hexProperties.height / 1.5
                : hexProperties.width / 1.5;
            const center = hexProperties.calculateCenterPos(selectedUnit.position.column, selectedUnit.position.row);
            const counterOrigin = calculateCounterOrigin(center, counterSize);
            hexes.push(React.createElement(ConfirmationIcon, { key: `confirmation-icon`, counterSize: counterSize, counterOrigin: counterOrigin, onClick: onStayAtOriginPosition }));
        }
        const neighbours = rules.getExistingNeighbours(lastLeg.to ?? selectedUnit.position);
        if (movementData.currentMovementPoints > 0) {
            const possibleLegs = getPossibleLegs(neighbours, movementData.path);
            possibleLegs.forEach((neighbour) => {
                const center = hexProperties.calculateCenterPos(neighbour.column, neighbour.row);
                hexes.push(React.createElement(Hex, { center: center, size: hexProperties.size, key: `possibleLeg-${neighbour.column}-${neighbour.row}`, isFlatTop: hexProperties.isFlatTop, dimensions: {
                        width: hexProperties.width,
                        height: hexProperties.height,
                    }, style: {
                        cursor: 'pointer',
                        fill: 'rgba(0, 0, 0, 0)',
                        strokeWidth: '0',
                    }, onClick: () => onMove({ row: neighbour.row, column: neighbour.column }) }));
                hexes.push(React.createElement(Arrow, { key: `arrow-${neighbour.column}-${neighbour.row}`, angle: neighbour.angle, tipPoint: center, baseSize: hexProperties.size, style: { cursor: 'pointer' }, onClick: () => onMove({ row: neighbour.row, column: neighbour.column }) }));
            });
        }
        if (!hexes.length) {
            return null;
        }
        return React.createElement("g", null, hexes);
    };
    const Instruction = () => {
        if (!isLoggedInPlayerActive) {
            return React.createElement(OpponentsTurnInstruction, null);
        }
        return React.createElement("p", null, "Move the selected unit");
    };
    const getUnitReplacement = ({ unit, origin, counterSize, key }) => {
        if (unit.id === selectedUnit.id) {
            if (movementData.path.length > 1) {
                return (React.createElement(UnitCounter, { key: key, origin: origin, counterSize: counterSize, unit: unit, containerProps: {
                        style: {
                            opacity: 0.5,
                        },
                    }, isBold: rules.isUnitBold(unit, state) }));
            }
        }
        return null;
    };
    return {
        ActiveLayer,
        Instruction,
        getUnitReplacement,
    };
};
export default createComponents;
