import React from 'react';
import { BattleBonusPurpose, } from '@wareena/grid-battles-entity/game/codebook/battleBonus';
import collectBonusSymbols from '@wareena/grid-battles-engine/gameStateMachine/helpers/battle/collectBonusSymbols';
import Hits from '../Hits.js';
import EquationSymbols from './EquationSymbols.js';
export default function RoundResults({ combat, roundNumber, roundAttackerBonuses, roundDefenderBonuses, isRoundAttackerOnLeft = true, resultElement, }) {
    const attackResults = combat.diceRolls[roundNumber]?.results;
    if (!attackResults) {
        throw `No attack results for round ${roundNumber}`;
    }
    const attackBonusSymbols = collectBonusSymbols(roundAttackerBonuses, BattleBonusPurpose.Attack);
    const defenseBonusSymbols = collectBonusSymbols(roundDefenderBonuses, BattleBonusPurpose.Defense);
    let leftSideResults = attackResults.attackerDiceResults;
    let leftSideBonusSymbols = attackBonusSymbols;
    let rightSideResults = attackResults.defenderDiceResults;
    let rightSideBonusSymbols = defenseBonusSymbols;
    if (!isRoundAttackerOnLeft) {
        leftSideResults = attackResults.defenderDiceResults;
        leftSideBonusSymbols = defenseBonusSymbols;
        rightSideResults = attackResults.attackerDiceResults;
        rightSideBonusSymbols = attackBonusSymbols;
    }
    return (React.createElement("div", { className: "is-flex mt-2" },
        React.createElement("div", { style: {
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
            } },
            React.createElement(EquationSymbols, { diceResults: leftSideResults, bonusSymbols: leftSideBonusSymbols }),
            React.createElement("span", { className: "icon" },
                React.createElement("i", { className: "fas fa-minus" })),
            React.createElement(EquationSymbols, { diceResults: rightSideResults, bonusSymbols: rightSideBonusSymbols }),
            React.createElement("span", { className: "icon" },
                React.createElement("i", { className: "fas fa-equals" })),
            React.createElement("div", { style: { display: 'flex', alignItems: 'center', gap: '0.5rem' } },
                React.createElement(Hits, { hits: combat.stats[roundNumber].roundDefender.receivedHits, size: 20 }),
                resultElement))));
}
