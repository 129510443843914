import { createPathToTutorialById } from '@local/tutorial/services/routing';
import { createHexProperties } from '@wareena/game-algorithms';
import collectBonusAttackDice from '@wareena/grid-battles-engine/gameStateMachine/helpers/battle/collectBonusAttackDice';
import collectBonusDefenseDice from '@wareena/grid-battles-engine/gameStateMachine/helpers/battle/collectBonusDefenseDice';
import collectBonusSymbols from '@wareena/grid-battles-engine/gameStateMachine/helpers/battle/collectBonusSymbols';
import { BattleBonusPurpose } from '@wareena/grid-battles-entity/game/codebook/battleBonus';
import {
    TerrainId,
    getTerrainBonuses,
    getTerrainName,
    isBlockingTerrain,
} from '@wareena/grid-battles-entity/game/map/terrain';
import BonusSymbolAsImage from '@wareena/grid-battles-ui/game/dice/BonusSymbolAsImage';
import DieIconAsImage from '@wareena/grid-battles-ui/game/dice/DieIconAsImage';
import terrainMap from '@wareena/grid-battles-ui/game/terrain/index';
import Link from 'next/link';

export default function TerrainAndCombatPage() {
    const hexProperties = createHexProperties({
        hexSize: 25,
        isFlatTop: false,
        isFirstOffsetted: false,
    });

    const hexCenter = hexProperties.calculateCenterPos(0, 0);

    const padding = 3;

    const terrainImgWidth = hexProperties.width + 2 * padding;
    const terrainImgHeight = hexProperties.height + 2 * padding;

    return (
        <>
            <div className="rules-text mb-4">
                <h2 className="title is-2">Terrain and Combat</h2>

                <p>
                    Terrain does not only have an effect on movement when it determines how many{' '}
                    <i>movement points</i> it costs to enter some space and if it can be entered at
                    all. It can also have a huge impact on your ability to attack an enemy unit and
                    on the result of such an attack.
                </p>

                <h3 className="title is-3">Effects on Line of Sight</h3>

                <p>
                    Some terrains block LOS and some do not. This property is very important for all
                    units using ranged attack (archers, crossbowmen...). Because if you are unable
                    to see the enemy unit, you are not able to attack it. You can find more on LOS
                    on a{' '}
                    <Link href={createPathToTutorialById('line-of-sight')}>specialized page.</Link>
                </p>

                <table className="table is-striped">
                    <thead>
                        <tr>
                            <th> </th>
                            <th>Terrain</th>
                            <th>Does block LOS?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(terrainMap)
                            .toSorted((a, b) => {
                                const isABlocking = isBlockingTerrain(+a);
                                const isBBlocking = isBlockingTerrain(+b);

                                if (isABlocking && !isBBlocking) {
                                    return -1;
                                } else if (isBBlocking && !isABlocking) {
                                    return 1;
                                }

                                return 0;
                            })
                            .map((idx) => {
                                const terrainId: TerrainId = +idx;

                                const TerrainHex = terrainMap[terrainId];
                                const isBlocking = isBlockingTerrain(terrainId);

                                return (
                                    <tr key={terrainId}>
                                        <td>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={terrainImgWidth}
                                                height={terrainImgHeight}
                                            >
                                                <TerrainHex
                                                    isFlatTop={false}
                                                    size={hexProperties.size}
                                                    center={{
                                                        x: hexCenter.x + padding,
                                                        y: hexCenter.y + padding,
                                                    }}
                                                    dimensions={{
                                                        width: hexProperties.width,
                                                        height: hexProperties.height,
                                                    }}
                                                />
                                            </svg>
                                        </td>
                                        <td>{getTerrainName(terrainId)}</td>
                                        <td>{isBlocking ? 'Yes' : 'No'}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>

                <h3 className="title is-3 mt-4">Effects on Combat Resolution</h3>

                <p>
                    Some types of terrain can provide combat benefits to the unit occupying it. This
                    benefit can be in a sort of bonus attack or defense dice, or even some bonus
                    symbols. You can see the list of such terrains and their bonuses in the table
                    below.
                </p>

                <table className="table is-striped">
                    <thead>
                        <tr>
                            <th> </th>
                            <th>Terrain</th>
                            <th>Attack dice</th>
                            <th>Attack symbols</th>
                            <th>Defense dice</th>
                            <th>Defense symbols</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(terrainMap)
                            .filter((idx) => {
                                const terrainId: TerrainId = +idx;

                                return getTerrainBonuses(terrainId).length;
                            })
                            .map((idx) => {
                                const terrainId: TerrainId = +idx;

                                const TerrainHex = terrainMap[terrainId];

                                const bonuses = getTerrainBonuses(terrainId);

                                const bonusAttackDice = collectBonusAttackDice(bonuses);
                                const bonusDefenseDice = collectBonusDefenseDice(bonuses);
                                const bonusAttackSymbols = collectBonusSymbols(
                                    bonuses,
                                    BattleBonusPurpose.Attack
                                );
                                const bonusDefenseSymbols = collectBonusSymbols(
                                    bonuses,
                                    BattleBonusPurpose.Defense
                                );

                                return (
                                    <tr key={terrainId}>
                                        <td>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={terrainImgWidth}
                                                height={terrainImgHeight}
                                            >
                                                <TerrainHex
                                                    isFlatTop={false}
                                                    size={hexProperties.size}
                                                    center={{
                                                        x: hexCenter.x + padding,
                                                        y: hexCenter.y + padding,
                                                    }}
                                                    dimensions={{
                                                        width: hexProperties.width,
                                                        height: hexProperties.height,
                                                    }}
                                                />
                                            </svg>
                                        </td>
                                        <td>{getTerrainName(terrainId)}</td>
                                        <td>
                                            <div className="is-flex is-align-items-center mb-1 has-small-column-gap">
                                                {bonusAttackDice.length === 0
                                                    ? '-'
                                                    : bonusAttackDice.map((gameDie, idx) => {
                                                          return (
                                                              <DieIconAsImage
                                                                  key={`${gameDie}-${idx}`}
                                                                  dieType={gameDie}
                                                                  size={25}
                                                              />
                                                          );
                                                      })}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="is-flex is-align-items-center mb-1 has-small-column-gap">
                                                {bonusAttackSymbols.length === 0
                                                    ? '-'
                                                    : bonusAttackSymbols.map((symbol, idx) => (
                                                          <BonusSymbolAsImage
                                                              symbol={symbol}
                                                              size={20}
                                                              key={`${symbol}-${idx}`}
                                                          />
                                                      ))}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="is-flex is-align-items-center mb-1 has-small-column-gap">
                                                {bonusDefenseDice.length === 0
                                                    ? '-'
                                                    : bonusDefenseDice.map((gameDie, idx) => {
                                                          return (
                                                              <DieIconAsImage
                                                                  key={`${gameDie}-${idx}`}
                                                                  dieType={gameDie}
                                                                  size={25}
                                                              />
                                                          );
                                                      })}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="is-flex is-align-items-center mb-1 has-small-column-gap">
                                                {bonusDefenseSymbols.length === 0
                                                    ? '-'
                                                    : bonusDefenseSymbols.map((symbol, idx) => (
                                                          <BonusSymbolAsImage
                                                              symbol={symbol}
                                                              size={20}
                                                              key={`${symbol}-${idx}`}
                                                          />
                                                      ))}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>

                <p>
                    <strong>Woods</strong> provide benefits to the defender because it is easier to
                    hide there before missiles and trees, bushes and uneven ground make it hard for
                    the attacker.
                </p>
                <p>
                    Units placed on <strong>Hills</strong> have it easier to attack enemies below
                    them. This is especially true for ranged units, but also melee units can benefit
                    from better reach and also gravity helping their velocity when attacking while
                    moving down. <i>Hills</i> are also easily defendable positions because it
                    requires more stamina to attack uphill. Therefore they also provide a bonus{' '}
                    <i>Shield</i> symbol for defenders.
                </p>
                <p>
                    Your units on <strong>Bridges</strong> are safer than in open country because
                    they can not be flanked and attacked from sides. Also, armies were usually
                    determined to hold the bridges because of their strategic importance. Thess are
                    all reasons for a <i>Bridge</i> terrain to provide benefits for a defender.
                </p>

                <p>
                    If you want to see how these benefits can influence your chances, go check our{' '}
                    <Link href={createPathToTutorialById('chances-table')}>Chances Table.</Link>
                </p>
            </div>
        </>
    );
}
