import React from 'react';
import Hex from './Hex.js';
function HexOnPosition({ position, hexProperties, ...otherProps }) {
    const center = hexProperties.calculateCenterPos(position.column, position.row);
    return (React.createElement(Hex, { center: center, size: hexProperties.size, isFlatTop: hexProperties.isFlatTop, dimensions: {
            width: hexProperties.width,
            height: hexProperties.height,
        }, ...otherProps }));
}
export default HexOnPosition;
