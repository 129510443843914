import React from 'react';
import createRules from '@wareena/grid-battles-engine/rules';
import { selectDefender, unselectAttacker, unselectDefender, } from '@wareena/grid-battles-engine/gameStateMachine/request';
import { filterUnitsAbleToAttack, getActivatedUnits, getPossibleTargetsForUnits, hasUnitAlreadyAttacked, } from '@wareena/grid-battles-engine/gameStateMachine/helpers';
import { AttackType } from '@wareena/grid-battles-entity/game/codebook/attackTypes';
import HexOnPosition from '@wareena/ui/game/map/HexOnPosition';
import { calculateCounterOrigin } from '../../unit/services/counter.js';
import FieldOfView from '../../map/FieldOfView.js';
import EligibleUnitMarker from '../../marker/EligibleUnitMarker.js';
import SelectedUnitMarker from '../../marker/SelectedAttackerMarker.js';
import PossibleTargetMarker from '../../marker/PossibleTargetMarker.js';
import SelectedDefenderMarker from '../../marker/SelectedDefenderMarker.js';
import CancelIcon from '../../iconButton/counterOverlay/CancelIcon.js';
import ConfirmationIcon from '../../iconButton/counterOverlay/ConfirmationIcon.js';
import OpponentsTurnInstruction from '../helper/OpponentsTurnInstruction.js';
import ActionPanelContainer, { ActionPanelButtons, ActionPanelPreview, ActionPanelText, } from '../helper/ActionPanelContainer/index.js';
import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
import UnitOnTerrainAsImage from '../../unit/UnitCounter/UnitOnTerrainAsImage.js';
import { isSamePosition } from '@wareena/game-algorithms';
const createComponents = ({ stateMachine, game, state, hexProperties, isLoggedInPlayerActive, loggedInPlayerId, }) => {
    const rules = createRules(game.hexes, hexProperties);
    const currentCombat = state.combats?.find((c) => c.id === state.currentCombatId);
    if (!currentCombat) {
        throw new Error('There is no current combat. It is unexpected');
    }
    const attackerId = currentCombat.attackerId;
    const defenderId = currentCombat.defenderId;
    const attacker = state.units.find((u) => u.id === attackerId);
    const selectedDefender = defenderId ? state.units.find((u) => u.id === defenderId) : null;
    if (!attacker) {
        return null;
    }
    function selectUnit(unit) {
        stateMachine.executeRequest(selectDefender(unit.id, loggedInPlayerId), state);
    }
    function selectAnother() {
        stateMachine.executeRequest(unselectDefender(loggedInPlayerId), state);
    }
    function getDefenders() {
        const possibleTargets = rules.getPossibleTargets(attacker, state.units);
        if (!possibleTargets?.length) {
            return [];
        }
        return possibleTargets.map((target) => {
            return rules.getUnitOnPosition(target, state.units);
        });
    }
    const defenders = defenderId ? [] : getDefenders();
    const activatedUnits = getActivatedUnits(state);
    const possibleTargets = getPossibleTargetsForUnits({
        units: activatedUnits,
        rules,
        hasUnitAlreadyAttacked: (unitId) => hasUnitAlreadyAttacked(unitId, state),
        allUnits: state.units,
    });
    const unitsAbleToAttack = filterUnitsAbleToAttack({
        possibleAttackers: activatedUnits,
        possibleTargets,
    });
    const ActiveLayerForPassivePlayer = () => {
        const hexes = [];
        hexes.push(React.createElement(SelectedUnitMarker, { key: `activeHexForPassivePlayer-${attacker.position.column}-${attacker.position.row}`, position: attacker.position, hexProperties: hexProperties }));
        unitsAbleToAttack.forEach((unit) => {
            if (unit.id !== attacker.id) {
                hexes.push(React.createElement(EligibleUnitMarker, { key: `activated-${unit.position.column}-${unit.position.row}`, position: unit.position, hexProperties: hexProperties }));
            }
        });
        if (selectedDefender) {
            hexes.push(React.createElement(SelectedDefenderMarker, { key: `selectedDefender-${selectedDefender.position.column}-${selectedDefender.position.row}`, position: selectedDefender.position, hexProperties: hexProperties }));
        }
        else {
            defenders.forEach((defender) => {
                hexes.push(React.createElement(PossibleTargetMarker, { key: `possibleDefender-${defender.position.column}-${defender.position.row}`, position: defender.position, hexProperties: hexProperties }));
            });
        }
        if (!hexes.length) {
            return null;
        }
        return React.createElement("g", null, hexes);
    };
    const ActiveLayer = (props) => {
        if (!isLoggedInPlayerActive) {
            return React.createElement(ActiveLayerForPassivePlayer, null);
        }
        const hexes = [];
        if (attacker.attackType === AttackType.Ranged) {
            hexes.unshift(React.createElement(FieldOfView, { key: `field-of-view-${attacker.id}`, hexProperties: hexProperties, gameState: state, rules: rules, unit: attacker }));
        }
        hexes.push(React.createElement(SelectedUnitMarker, { key: `activeHexForActivePlayer-${attacker.position.column}-${attacker.position.row}`, position: attacker.position, hexProperties: hexProperties, onClick: () => stateMachine.executeRequest(unselectAttacker(loggedInPlayerId), state) }));
        unitsAbleToAttack.forEach((unit) => {
            if (unit.id !== attacker.id) {
                hexes.push(React.createElement(EligibleUnitMarker, { key: `activated-${unit.position.column}-${unit.position.row}`, position: unit.position, hexProperties: hexProperties }));
            }
        });
        if (defenderId && selectedDefender) {
            const counterSize = hexProperties.isFlatTop
                ? hexProperties.height / 1.5
                : hexProperties.width / 1.5;
            const center = hexProperties.calculateCenterPos(selectedDefender.position.column, selectedDefender.position.row);
            const counterOrigin = calculateCounterOrigin(center, counterSize);
            hexes.push(React.createElement("g", { key: `selectedDefender-${selectedDefender.position.column}-${selectedDefender.position.row}` },
                React.createElement(HexOnPosition, { position: selectedDefender.position, hexProperties: hexProperties, style: {
                        fill: 'rgba(255, 0, 0, 0.3)',
                        stroke: 'red',
                        strokeWidth: '3px',
                    } }),
                React.createElement(ConfirmationIcon, { counterSize: counterSize, counterOrigin: counterOrigin, onClick: () => {
                        props?.onShowCombatDetail?.();
                    } }),
                React.createElement(CancelIcon, { counterSize: counterSize, counterOrigin: counterOrigin, onClick: () => {
                        selectAnother();
                    } })));
        }
        else {
            defenders.forEach((defender) => {
                if (!defender) {
                    return;
                }
                hexes.push(React.createElement(PossibleTargetMarker, { key: `possibleDefender-${defender.position.column}-${defender.position.row}`, position: defender.position, hexProperties: hexProperties, onClick: () => selectUnit(defender) }));
            });
        }
        return React.createElement("g", null, hexes);
    };
    const Instruction = () => {
        if (!isLoggedInPlayerActive) {
            return React.createElement(OpponentsTurnInstruction, null);
        }
        if (defenderId && selectedDefender) {
            return React.createElement("p", null,
                "Decide whether to attack ",
                selectedDefender.label);
        }
        return React.createElement("p", null, "Select defender");
    };
    let ActionPanel = undefined;
    if (isLoggedInPlayerActive && attackerId && defenderId && selectedDefender) {
        ActionPanel = function (props) {
            let onShowCombatDetail = () => { };
            if (props && props.onShowCombatDetail) {
                onShowCombatDetail = props.onShowCombatDetail;
            }
            const isBattleBackPossible = currentCombat.wasResolved
                ? currentCombat.stats.length > 1
                : rules.isBattleBackPossible(attacker, selectedDefender, state);
            const doesAttackerHaveAdvantage = rules.doesHaveAdvantageAgainst(attacker, selectedDefender);
            const doesDefenderHaveAdvantage = rules.doesHaveAdvantageAgainst(selectedDefender, attacker);
            const attackerHex = game.hexes.find((h) => isSamePosition(h, attacker.position));
            const defenderHex = game.hexes.find((h) => isSamePosition(h, selectedDefender.position));
            if (!attackerHex) {
                throw `Missing hex [${attacker.position.column},${attacker.position.row}] in game map`;
            }
            if (!defenderHex) {
                throw `Missing hex [${selectedDefender.position.column},${selectedDefender.position.row}] in game map`;
            }
            return (React.createElement(ActionPanelContainer, null,
                React.createElement(ActionPanelText, null,
                    "Attack",
                    ' ',
                    React.createElement("span", { className: selectedDefender.side === SideId.A
                            ? 'has-color-player-a'
                            : 'has-color-player-b' }, selectedDefender.label),
                    "?"),
                React.createElement(ActionPanelPreview, null,
                    React.createElement("div", { className: "is-flex" },
                        React.createElement("div", { className: "is-flex is-align-items-center is-flex-direction-column is-align-content-flex-start", style: { gap: '0.5rem' } },
                            React.createElement(UnitOnTerrainAsImage, { unit: attacker, isFlatTop: hexProperties.isFlatTop, hexSize: 50, terrainId: attackerHex.terrainId, graphicalVariant: attackerHex.graphicalVariant, position: attackerHex, isUnitBold: rules.isUnitBold(attacker, state) }),
                            doesAttackerHaveAdvantage ? (React.createElement("span", { className: "tag is-info" }, "Advantage: Yes")) : (React.createElement("span", { className: "tag is-light" }, "Advantage: No"))),
                        React.createElement("span", { className: "icon is-align-self-center", key: `preview-icon` },
                            React.createElement("i", { className: "fas fa-times" })),
                        React.createElement("div", { className: "is-flex is-align-items-center is-flex-direction-column is-align-content-flex-start", style: { gap: '0.5rem' } },
                            React.createElement(UnitOnTerrainAsImage, { unit: selectedDefender, isFlatTop: hexProperties.isFlatTop, hexSize: 50, terrainId: defenderHex.terrainId, graphicalVariant: defenderHex.graphicalVariant, position: defenderHex, isUnitBold: rules.isUnitBold(selectedDefender, state) }),
                            isBattleBackPossible ? (React.createElement("span", { className: `tag is-warning` }, "Battle Back: Yes")) : (React.createElement("span", { className: "tag is-light" }, "Battle Back: No")),
                            doesDefenderHaveAdvantage ? (React.createElement("span", { className: `tag is-info` }, "Advantage: Yes")) : (React.createElement("span", { className: "tag is-light" }, "Advantage: No"))))),
                React.createElement(ActionPanelButtons, null,
                    React.createElement("button", { className: "button is-primary", onClick: onShowCombatDetail },
                        React.createElement("span", { className: "icon-text" },
                            React.createElement("span", { className: "icon" },
                                React.createElement("i", { className: "fas fa-check" })),
                            React.createElement("span", null, "Yes"))),
                    React.createElement("button", { className: "button is-secondary", onClick: selectAnother },
                        React.createElement("span", { className: "icon-text" },
                            React.createElement("span", { className: "icon" },
                                React.createElement("i", { className: "fas fa-xmark" })),
                            React.createElement("span", null, "No, attack another"))))));
        };
    }
    return {
        ActiveLayer,
        Instruction,
        ActionPanel,
    };
};
export default createComponents;
