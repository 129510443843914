import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { CombatEffectSymbol } from '@wareena/grid-battles-entity/game/codebook/dice';
import { UnitBaseType } from '@wareena/grid-battles-entity/game/codebook/unitBaseTypes';
import { getUnitTypes } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import BonusSymbolAsImage from '@wareena/grid-battles-ui/game/dice/BonusSymbolAsImage';
import UnitCounterAsImage from '@wareena/grid-battles-ui/game/unit/UnitCounterAsImage';

export default function AdvantagePage() {
    const allUnits = getUnitTypes();

    const infantryUnits = allUnits
        .filter((unit) => unit.baseTypeId === UnitBaseType.Infantry)
        .map((unit) => createUnit(unit.id));
    const missileUnits = allUnits
        .filter((unit) => unit.baseTypeId === UnitBaseType.Missile)
        .map((unit) => createUnit(unit.id));
    const mountedUnits = allUnits
        .filter((unit) => unit.baseTypeId === UnitBaseType.Mounted)
        .map((unit) => createUnit(unit.id));

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
        gridTemplateRows: 'auto',
        gridColumnGap: '1rem',
        gridRowGap: '1rem',
    };

    return (
        <div className="rules-text mb-4">
            <h2 className="title is-2">Advantage</h2>

            <p>
                On a battlefield, units fare slightly better against some types of opponents and
                worse against others. Part of this is due to the unit&apos;s strength and armor, but
                there are also some basic qualities. For example, archers are far better at shooting
                slowly moving targets than an enemy galloping on fast horses. We chose to imitate
                these basic qualities by a mechanic called <i>Advantage</i>.
            </p>

            <p>
                A unit that has the <i>Advantage</i> against another has a slightly better chance to
                deal more hits when attacking. This is implemented by counting <i>Bonus Damage</i> (
                <BonusSymbolAsImage symbol={CombatEffectSymbol.Bonus} size={15} />) symbols from an
                attack dice roll as a regular <i>Damage</i> symbol.
            </p>

            <p>
                A unit that does not have the <i>Advantage</i> against its enemy simply ignores the{' '}
                <i>Bonus Damage</i> symbols (
                <BonusSymbolAsImage symbol={CombatEffectSymbol.Bonus} size={15} />) rolled on its
                attack dice roll.
            </p>

            <p>
                <i>Advantage</i> is implemented in a rock-paper-scissor manner.
            </p>

            <h3 className="title is-3 mt-4">Missile (ranged) units</h3>
            <p>
                Missile units have the <i>Advantage</i> against infantry because infantry moves
                slowly and usually in dense formations.
            </p>

            <div style={gridStyle}>
                <div className="card has-background-success-light">
                    <div className="card-content">
                        <div className="content">
                            <h4 className="title is-4">Missile units</h4>
                            <p>
                                These units have the <i>Advantage</i> when attacking infantry
                            </p>
                            {missileUnits.map((unit) => (
                                <UnitCounterAsImage key={unit.id} unit={unit} counterSize={60} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="card has-background-danger-light">
                    <div className="card-content">
                        <div className="content">
                            <h4 className="title is-4">Infantry units</h4>
                            <p>
                                These units are at a disadvantage when defending against missile
                                units
                            </p>
                            {infantryUnits.map((unit) => (
                                <UnitCounterAsImage key={unit.id} unit={unit} counterSize={60} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <h3 className="title is-3 mt-4">Infantry units</h3>

            <p>
                Infantry units have the <i>Advantage</i> against mounted units because horses fear
                tight formations, shield walls and spears of an infantry.
            </p>

            <div style={gridStyle}>
                <div className="card has-background-success-light">
                    <div className="card-content">
                        <div className="content">
                            <h4 className="title is-4">Infantry units</h4>
                            <p>
                                These units have the <i>Advantage</i> when attacking mounted units
                            </p>
                            {infantryUnits.map((unit) => (
                                <UnitCounterAsImage key={unit.id} unit={unit} counterSize={60} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="card has-background-danger-light">
                    <div className="card-content">
                        <div className="content">
                            <h4 className="title is-4">Mounted units</h4>
                            <p>
                                These units are at a disadvantage when defending against infantry
                                units
                            </p>
                            {mountedUnits.map((unit) => (
                                <UnitCounterAsImage key={unit.id} unit={unit} counterSize={60} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <h3 className="title is-3 mt-4">Mounted units</h3>

            <p>
                Mounted units have the <i>Advantage</i> against missile units because archers fought
                in sparse formations and can not form shield walls.
            </p>

            <div style={gridStyle}>
                <div className="card has-background-success-light">
                    <div className="card-content">
                        <div className="content">
                            <h4 className="title is-4">Mounted units</h4>
                            <p>
                                These units have the <i>Advantage</i> when attacking missile units
                            </p>
                            {mountedUnits.map((unit) => (
                                <UnitCounterAsImage key={unit.id} unit={unit} counterSize={60} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="card has-background-danger-light">
                    <div className="card-content">
                        <div className="content">
                            <h4 className="title is-4">Missile units</h4>
                            <p>
                                These units are at a disadvantage when defending against mounted
                                units
                            </p>
                            {missileUnits.map((unit) => (
                                <UnitCounterAsImage key={unit.id} unit={unit} counterSize={60} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
