import React from 'react';
export default function ConfirmationIcon({ counterSize, counterOrigin, onClick, }) {
    const pointSize = counterSize / 32;
    const halfSize = counterSize / 2;
    const rect = {
        x: counterOrigin.x + halfSize + 2 * pointSize,
        y: counterOrigin.y + halfSize + 2 * pointSize,
        width: halfSize,
        height: halfSize,
    };
    const checkPath = `
        M ${rect.x + 2 * pointSize},${rect.y + 7 * pointSize}
        L ${rect.x + 7 * pointSize},${rect.y + rect.width - 3 * pointSize}
        L ${rect.x + 13 * pointSize},${rect.y + 3 * pointSize}
        C ${rect.x + 13 * pointSize},${rect.y + 3 * pointSize}
          ${rect.x + 14 * pointSize},${rect.y + 2 * pointSize}
          ${rect.x + 13 * pointSize},${rect.y + 2 * pointSize}
        L ${rect.x + 7 * pointSize},${rect.y + rect.width - 7 * pointSize}
        L ${rect.x + 2.5 * pointSize},${rect.y + 6 * pointSize}
        C ${rect.x + 2 * pointSize},${rect.y + 6 * pointSize}
          ${rect.x + 2 * pointSize},${rect.y + 7 * pointSize}
          ${rect.x + 2 * pointSize},${rect.y + 7 * pointSize}
        z
    `;
    const containerProps = {};
    if (onClick) {
        containerProps.onClick = onClick;
        containerProps.style = { cursor: 'pointer' };
    }
    return (React.createElement("g", { ...containerProps },
        React.createElement("rect", { x: rect.x, y: rect.y, width: rect.width, height: rect.height, fill: "rgba(255, 215, 0, 0.8)", rx: 2, stroke: "black", strokeWidth: 1, style: {
                filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, .7))',
            } }),
        React.createElement("path", { d: checkPath.trim(), fill: "green", stroke: "darkgreen", strokeWidth: "1" })));
}
