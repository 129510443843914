import React from 'react';
import UnitCounter from '../unit/UnitCounter/index.js';
import { calculateCounterOrigin } from '../unit/services/counter.js';
function UnitLayer({ units, hexProperties, getUnitReplacement = () => null, getBoldStatus = () => false, }) {
    const counterSize = hexProperties.isFlatTop
        ? hexProperties.height / 1.5
        : hexProperties.width / 1.5;
    const counters = units.map((unit) => {
        const centerPos = hexProperties.calculateCenterPos(unit.position.column, unit.position.row);
        const origin = calculateCounterOrigin(centerPos, counterSize);
        const key = `unit-${unit.label}-${unit.position.column}-${unit.position.row}`;
        const replacement = getUnitReplacement?.({
            key,
            origin,
            counterSize,
            unit,
        });
        if (replacement) {
            return replacement;
        }
        const bgColor = unit.isFatigued ? '#c0c0c0' : 'white';
        return (React.createElement(UnitCounter, { key: key, origin: origin, counterSize: counterSize, unit: unit, bgColor: bgColor, isBold: getBoldStatus(unit) }));
    });
    return React.createElement("g", null, counters);
}
export default UnitLayer;
