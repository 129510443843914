import React from 'react';
const createComponents = () => {
    const ActiveLayer = () => null;
    const Instruction = () => React.createElement("p", null, "Game over");
    return {
        ActiveLayer,
        Instruction,
    };
};
export default createComponents;
