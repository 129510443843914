import { isUserActive } from '../utils/index.js';
const assertCanUnselectUnitForActivation = (game, state, userId) => {
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
};
const unselectAllUnitsForActivation = (request, game, state) => {
    const { userId } = request.payload;
    assertCanUnselectUnitForActivation(game, state, userId);
    return {
        ...state,
        unitsSelectedForActivation: [],
    };
};
export default unselectAllUnitsForActivation;
