export default function writeStatsIntoState({ stats, state, combatId, }) {
    return {
        ...state,
        combats: state.combats?.map((combat) => {
            if (combat.id !== combatId) {
                return combat;
            }
            return {
                ...combat,
                stats: [
                    ...(combat.stats ?? []),
                    {
                        ...stats,
                    },
                ],
            };
        }) ?? [],
    };
}
