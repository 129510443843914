import { isUserActive } from '../utils/index.js';
export default function unselectDefender({ request, state, game, }) {
    const { userId } = request.payload;
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
    if (!state.currentCombatId) {
        throw `There is no current combat`;
    }
    const currentCombat = state.combats?.find((c) => c.id === state.currentCombatId && !c.wasResolved);
    if (!currentCombat) {
        throw `There is no current combat`;
    }
    return {
        ...state,
        combats: state.combats?.map((combat) => {
            if (combat.id !== state.currentCombatId) {
                return combat;
            }
            if (combat.wasResolved) {
                throw `Could not change resolved combat`;
            }
            return {
                ...combat,
                defenderId: null,
                defender: null,
            };
        }) ?? [],
    };
}
