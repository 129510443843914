export var RequestType;
(function (RequestType) {
    RequestType["SelectUnitForActivation"] = "selectUnitForActivation";
    RequestType["SetStateName"] = "setStateName";
    RequestType["UnselectUnitForActivation"] = "unselectUnitForActivation";
    RequestType["UnselectAllUnitsForActivation"] = "unselectAllUnitsForActivation";
    RequestType["SelectUnitForMovement"] = "selectUnitForMovement";
    RequestType["MoveUnit"] = "moveUnit";
    RequestType["ReturnToPathLeg"] = "returnToPathLeg";
    RequestType["FinishMovementOfUnit"] = "finishMovementOfUnit";
    RequestType["StayAtOriginalPosition"] = "stayAtOriginalPosition";
    RequestType["SelectAttacker"] = "selectAttacker";
    RequestType["UnselectAttacker"] = "unselectAttacker";
    RequestType["SelectDefender"] = "selectDefender";
    RequestType["UnselectDefender"] = "unselectDefender";
    RequestType["Attack"] = "attack";
})(RequestType || (RequestType = {}));
