import assertCorrectDiceWereThrown from './assertCorrectDiceWereThrown.js';
export default function assertDiceRollsAreValid(attacker, attackerBonusDice, defender, defenderBonusDice, results) {
    if (!results) {
        throw 'No dice roll results';
    }
    if (results.attackerId !== attacker.id) {
        throw 'Invalid attacker for roll results';
    }
    if (results.defenderId !== defender.id) {
        throw 'Invalid defender for roll results';
    }
    if (results.attackerDiceResults?.length !==
        attacker.attackDice.length + attackerBonusDice.length) {
        throw 'Invalid number of attack dice rolls';
    }
    assertCorrectDiceWereThrown(results.attackerDiceResults, [
        ...attacker.attackDice,
        ...attackerBonusDice,
    ]);
    if (results.defenderDiceResults?.length !==
        defender.defenseDice.length + defenderBonusDice.length) {
        throw 'Invalid number of defense dice rolls';
    }
    assertCorrectDiceWereThrown(results.defenderDiceResults, [
        ...defender.defenseDice,
        ...defenderBonusDice,
    ]);
    return true;
}
