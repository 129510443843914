export const isUserActive = (userId, players, activeSideId) => {
    const player = players.find((p) => p.id === userId);
    return player?.side === activeSideId;
};
export const removeProperties = (obj, propertyNames) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (!propertyNames.includes(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
};
