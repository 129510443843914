import { AttackType } from './attackTypes.js';
import { AttackDieType, DefenseDieType } from './dice.js';
import { UnitBaseType } from './unitBaseTypes.js';
export var UnitTypeId;
(function (UnitTypeId) {
    UnitTypeId[UnitTypeId["Archer"] = 101] = "Archer";
    UnitTypeId[UnitTypeId["LongBowArcher"] = 102] = "LongBowArcher";
    UnitTypeId[UnitTypeId["Crossbowman"] = 103] = "Crossbowman";
    UnitTypeId[UnitTypeId["Pikeman"] = 201] = "Pikeman";
    UnitTypeId[UnitTypeId["Swordman"] = 202] = "Swordman";
    UnitTypeId[UnitTypeId["Axeman"] = 203] = "Axeman";
    UnitTypeId[UnitTypeId["Maceman"] = 204] = "Maceman";
    UnitTypeId[UnitTypeId["Knight"] = 301] = "Knight";
    UnitTypeId[UnitTypeId["LightCavalry"] = 302] = "LightCavalry";
    UnitTypeId[UnitTypeId["MediumCavalry"] = 303] = "MediumCavalry";
})(UnitTypeId || (UnitTypeId = {}));
const unitTypes = [
    {
        id: UnitTypeId.Archer,
        baseTypeId: UnitBaseType.Missile,
        name: 'Archer',
        label: 'Archer',
        iconName: 'archer',
        attackType: AttackType.Ranged,
        range: 4,
        health: 4,
        movementPoints: 2,
        attackDice: [AttackDieType.Yellow],
        defenseDice: [DefenseDieType.Brown],
        bonusAgainstBaseTypes: [UnitBaseType.Infantry],
    },
    {
        id: UnitTypeId.LongBowArcher,
        baseTypeId: UnitBaseType.Missile,
        name: 'Longbow',
        label: 'Longbow',
        iconName: 'longbow',
        attackType: AttackType.Ranged,
        range: 5,
        health: 4,
        movementPoints: 2,
        attackDice: [AttackDieType.Blue],
        defenseDice: [DefenseDieType.Grey],
        bonusAgainstBaseTypes: [UnitBaseType.Infantry],
    },
    {
        id: UnitTypeId.Crossbowman,
        baseTypeId: UnitBaseType.Missile,
        name: 'Crossbowman',
        label: 'Crossbow',
        iconName: 'crossbow',
        attackType: AttackType.Ranged,
        range: 3,
        health: 4,
        movementPoints: 2,
        attackDice: [AttackDieType.Red],
        defenseDice: [DefenseDieType.Grey],
        bonusAgainstBaseTypes: [UnitBaseType.Infantry],
    },
    {
        id: UnitTypeId.Pikeman,
        baseTypeId: UnitBaseType.Infantry,
        name: 'Pikeman',
        label: 'Pikeman',
        iconName: 'pikeman',
        attackType: AttackType.Melee,
        range: 1,
        health: 4,
        movementPoints: 2,
        attackDice: [AttackDieType.Yellow],
        defenseDice: [DefenseDieType.Brown],
        bonusAgainstBaseTypes: [UnitBaseType.Mounted],
    },
    {
        id: UnitTypeId.Swordman,
        baseTypeId: UnitBaseType.Infantry,
        name: 'Swordman',
        label: 'Swordman',
        iconName: 'swordman',
        attackType: AttackType.Melee,
        range: 1,
        health: 4,
        movementPoints: 2,
        attackDice: [AttackDieType.Blue],
        defenseDice: [DefenseDieType.Brown],
        bonusAgainstBaseTypes: [UnitBaseType.Mounted],
    },
    {
        id: UnitTypeId.Axeman,
        baseTypeId: UnitBaseType.Infantry,
        name: 'Axeman',
        label: 'Axeman',
        iconName: 'axeman',
        attackType: AttackType.Melee,
        range: 1,
        health: 4,
        movementPoints: 2,
        attackDice: [AttackDieType.Blue],
        defenseDice: [DefenseDieType.Grey],
        bonusAgainstBaseTypes: [UnitBaseType.Mounted],
    },
    {
        id: UnitTypeId.Maceman,
        baseTypeId: UnitBaseType.Infantry,
        name: 'Maceman',
        label: 'Maceman',
        iconName: 'maceman',
        attackType: AttackType.Melee,
        range: 1,
        health: 4,
        movementPoints: 1,
        attackDice: [AttackDieType.Red],
        defenseDice: [DefenseDieType.Black],
        bonusAgainstBaseTypes: [UnitBaseType.Mounted],
    },
    {
        id: UnitTypeId.LightCavalry,
        baseTypeId: UnitBaseType.Mounted,
        name: 'Light Cavalry',
        label: 'Light Cav',
        iconName: 'lightCavalry',
        attackType: AttackType.Melee,
        range: 1,
        health: 3,
        movementPoints: 4,
        attackDice: [AttackDieType.Yellow],
        defenseDice: [DefenseDieType.Brown],
        bonusAgainstBaseTypes: [UnitBaseType.Missile],
    },
    {
        id: UnitTypeId.MediumCavalry,
        baseTypeId: UnitBaseType.Mounted,
        name: 'Medium Cavalry',
        label: 'Mdm Cav',
        iconName: 'mediumCavalry',
        attackType: AttackType.Melee,
        range: 1,
        health: 3,
        movementPoints: 3,
        attackDice: [AttackDieType.Blue],
        defenseDice: [DefenseDieType.Grey],
        bonusAgainstBaseTypes: [UnitBaseType.Missile],
    },
    {
        id: UnitTypeId.Knight,
        baseTypeId: UnitBaseType.Mounted,
        name: 'Knight',
        label: 'Knight',
        iconName: 'knight',
        attackType: AttackType.Melee,
        range: 1,
        health: 3,
        movementPoints: 3,
        attackDice: [AttackDieType.Red],
        defenseDice: [DefenseDieType.Black],
        bonusAgainstBaseTypes: [UnitBaseType.Missile],
    },
];
export const getUnitTypes = () => {
    return unitTypes;
};
