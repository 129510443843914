import React from 'react';
import { SideId } from '@wareena/grid-battles-entity/game/codebook/side';
import { isSamePosition } from '@wareena/game-algorithms';
import ActionPanelContainer, { ActionPanelButtons, ActionPanelPreview, ActionPanelText, } from '../stateComponents/helper/ActionPanelContainer/index.js';
import UnitOnTerrainAsImage from '../unit/UnitCounter/UnitOnTerrainAsImage.js';
import RoundResults from './RoundResults.js';
import ResultUnitCounter from './ResultUnitCounter.js';
export default function CombatActionPanel({ combat, rules, gameState, scenario, hexes, onClose, }) {
    if (!combat || !combat.wasResolved) {
        return null;
    }
    const { attacker, defender } = combat;
    if (!defender) {
        return null;
    }
    const doesAttackerHaveAdvantage = rules.doesHaveAdvantageAgainst(attacker, defender);
    const doesDefenderHaveAdvantage = rules.doesHaveAdvantageAgainst(defender, attacker);
    const attackerHex = hexes.find((h) => isSamePosition(h, attacker.position));
    const defenderHex = hexes.find((h) => isSamePosition(h, defender.position));
    const isBattleBackPossible = combat.wasResolved
        ? combat.isBattleBackPossible
        : rules.isBattleBackPossible(attacker, defender, gameState);
    const wasBattleBackResolved = combat.wasResolved && combat.stats.length > 1;
    const attackerBonuses = rules.getBonusesForUnit(attacker);
    const defenderBonuses = rules.getBonusesForUnit(defender);
    let attackersHealthAfterRound0 = combat.stats[0].roundAttacker.healthAfter;
    let defendersHealthAfterRound0 = combat.stats[0].roundDefender.healthAfter;
    let attackersHealthAfterRound1 = attackersHealthAfterRound0;
    let defendersHealthAfterRound1 = defendersHealthAfterRound0;
    if (combat.stats[1]) {
        attackersHealthAfterRound1 = combat.stats[1].roundDefender.healthAfter;
        defendersHealthAfterRound1 = combat.stats[1].roundAttacker.healthAfter;
    }
    let isAttackerBold = false;
    let isDefenderBold = false;
    if (combat.wasResolved) {
        isAttackerBold = combat.isAttackerBold;
        isDefenderBold = combat.isDefenderBold;
    }
    else {
        isAttackerBold = rules.isUnitBold(attacker, gameState);
        isDefenderBold = rules.isUnitBold(defender, gameState);
    }
    return (React.createElement(ActionPanelContainer, null,
        React.createElement("div", { className: "action-panel-scrollable" },
            React.createElement(ActionPanelText, null,
                "Attack on",
                ' ',
                React.createElement("span", { className: defender.side === SideId.A ? 'has-color-player-a' : 'has-color-player-b' }, defender.label)),
            React.createElement(ActionPanelPreview, null,
                React.createElement("div", { className: "is-flex is-justify-content-center" },
                    React.createElement("div", { className: "is-flex is-align-items-center is-flex-direction-column is-align-content-flex-start combat-preview-unit-container", style: { gap: '0.5rem' } },
                        React.createElement(UnitOnTerrainAsImage, { unit: attacker, isFlatTop: scenario.isFlatTop, hexSize: 50, terrainId: attackerHex.terrainId, graphicalVariant: attackerHex.graphicalVariant, position: attackerHex, isUnitBold: isAttackerBold }),
                        doesAttackerHaveAdvantage ? (React.createElement("span", { className: "tag is-info" }, "Advantage: Yes")) : (React.createElement("span", { className: "tag is-light" }, "Advantage: No"))),
                    React.createElement("span", { className: "icon is-align-self-center", key: `preview-icon` },
                        React.createElement("i", { className: "fas fa-times" })),
                    React.createElement("div", { className: "is-flex is-align-items-center is-flex-direction-column is-align-content-flex-start combat-preview-unit-container", style: { gap: '0.5rem' } },
                        React.createElement(UnitOnTerrainAsImage, { unit: defender, isFlatTop: scenario.isFlatTop, hexSize: 50, terrainId: defenderHex.terrainId, graphicalVariant: defenderHex.graphicalVariant, position: defenderHex, isUnitBold: isDefenderBold }),
                        isBattleBackPossible ? (React.createElement("span", { className: `tag is-warning` }, "Battle Back: Yes")) : (React.createElement("span", { className: "tag is-light" }, "Battle Back: No")),
                        doesDefenderHaveAdvantage ? (React.createElement("span", { className: `tag is-info` }, "Advantage: Yes")) : (React.createElement("span", { className: "tag is-light" }, "Advantage: No"))))),
            React.createElement(ActionPanelPreview, { width: "100%" },
                React.createElement(RoundResults, { combat: combat, roundNumber: 0, roundAttackerBonuses: attackerBonuses, roundDefenderBonuses: defenderBonuses, resultElement: React.createElement(ResultUnitCounter, { unit: { ...defender, health: defendersHealthAfterRound0 }, isBold: isDefenderBold }) })),
            wasBattleBackResolved ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "combat-preview-divider" }),
                React.createElement(ActionPanelText, null, "Battle Back"),
                React.createElement(ActionPanelPreview, null,
                    React.createElement("div", { className: "is-flex is-justify-content-center" },
                        React.createElement("div", { className: "is-flex is-align-items-center is-flex-direction-column is-align-content-flex-start combat-preview-unit-container", style: { gap: '0.5rem' } },
                            React.createElement(UnitOnTerrainAsImage, { unit: { ...defender, health: defendersHealthAfterRound0 }, isFlatTop: scenario.isFlatTop, hexSize: 50, terrainId: defenderHex.terrainId, graphicalVariant: defenderHex.graphicalVariant, position: defenderHex, isUnitBold: isDefenderBold }),
                            doesDefenderHaveAdvantage ? (React.createElement("span", { className: "tag is-info" }, "Advantage: Yes")) : (React.createElement("span", { className: "tag is-light" }, "Advantage: No"))),
                        React.createElement("span", { className: "icon is-align-self-center", key: `preview-icon` },
                            React.createElement("i", { className: "fas fa-times" })),
                        React.createElement("div", { className: "is-flex is-align-items-center is-flex-direction-column is-align-content-flex-start combat-preview-unit-container", style: { gap: '0.5rem' } },
                            React.createElement(UnitOnTerrainAsImage, { unit: { ...attacker, health: attackersHealthAfterRound0 }, isFlatTop: scenario.isFlatTop, hexSize: 50, terrainId: attackerHex.terrainId, graphicalVariant: attackerHex.graphicalVariant, position: attackerHex, isUnitBold: isAttackerBold })))),
                React.createElement(ActionPanelPreview, { width: "100%" },
                    React.createElement(RoundResults, { combat: combat, roundNumber: 1, roundAttackerBonuses: defenderBonuses, roundDefenderBonuses: attackerBonuses, resultElement: React.createElement(ResultUnitCounter, { unit: { ...attacker, health: attackersHealthAfterRound1 }, isBold: isDefenderBold }) })))) : null),
        React.createElement(ActionPanelButtons, null,
            React.createElement("button", { className: "button is-primary", onClick: onClose },
                React.createElement("span", { className: "icon-text" },
                    React.createElement("span", { className: "icon" },
                        React.createElement("i", { className: "fas fa-xmark" })),
                    React.createElement("span", null, "Close"))))));
}
