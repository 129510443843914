import { RequestType } from '../../request/types.js';
import { isUserActive } from '../utils/index.js';
import { areNeighbours, isSamePosition } from '@wareena/game-algorithms';
import createRules from '../../../rules/index.js';
import { isPassableTerrain } from '@wareena/grid-battles-entity/game/map/terrain';
export const onEnter = ({ state, payload, }) => {
    const movingUnits = state.movingUnits?.units ?? [];
    const { unitId } = payload;
    const unit = state.units.find((u) => u.id === unitId);
    if (!unit) {
        throw `Can not find unit with id ${unitId}`;
    }
    return {
        ...state,
        movingUnits: {
            currentlyMovingId: unitId,
            units: [
                ...movingUnits,
                {
                    id: unitId,
                    finishedMovement: false,
                    maxMovementPoints: unit.movementPoints,
                    currentMovementPoints: unit.movementPoints,
                    path: [
                        {
                            to: { ...unit.position },
                            movementPointsAfterMoveHere: unit.movementPoints,
                        },
                    ],
                },
            ],
        },
    };
};
export const onLeave = ({ state }) => state;
export const executeRequest = ({ request, game, state, hexProperties, stateMachine, }) => {
    switch (request.type) {
        case RequestType.MoveUnit:
            return moveUnit({
                request: request,
                game,
                state,
                hexProperties,
            });
        case RequestType.ReturnToPathLeg:
            return returnToPathLeg({
                request: request,
                game,
                state,
            });
        case RequestType.FinishMovementOfUnit:
            return finishUnitMovement({
                request: request,
                game,
                state,
                stateMachine,
            });
        case RequestType.StayAtOriginalPosition:
            return stayAtOrigin({
                request: request,
                game,
                state,
                stateMachine,
            });
    }
    return state;
};
const moveUnit = ({ request, game, state, hexProperties, }) => {
    const { unitId, userId, position } = request.payload;
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
    const unit = state.units.find((u) => u.id === unitId);
    if (!unit) {
        throw `Can not find unit with id ${unitId}`;
    }
    if (unit.side !== state.activeSide) {
        throw `Unit ${unit.label} does not belong to active side`;
    }
    if (state.movingUnits?.currentlyMovingId !== unitId) {
        throw `Unit ${unit.label} is not currently moving`;
    }
    const movementData = state.movingUnits.units.find((d) => d.id === unitId);
    if (!movementData) {
        throw `Missing movement data`;
    }
    const targetHex = game.hexes.find((h) => isSamePosition(h, position));
    if (!targetHex) {
        throw `Position does not exist`;
    }
    if (!isPassableTerrain(targetHex.terrainId)) {
        throw `Terrain is not passable`;
    }
    if (movementData.currentMovementPoints <= 0) {
        throw `No more movement points`;
    }
    const rules = createRules(game.hexes, hexProperties);
    const path = movementData.path;
    const prevPosition = path.length > 1 ? path[path.length - 1].to : unit.position;
    const movementCost = rules.getMovementCost(prevPosition, position, unit);
    if (movementCost === null) {
        throw `Movement to this position is not possible. From: ${prevPosition.column}:${prevPosition.row}; to: ${position.column}:${position.row}; unit: ${unit.id}`;
    }
    if (movementCost > movementData.currentMovementPoints) {
        throw `Not enough movement points`;
    }
    const otherUnitOnPosition = state.units.find((u) => {
        if (u.position.row === position.row && u.position.column === position.column) {
            return true;
        }
        return false;
    });
    if (otherUnitOnPosition) {
        throw `Unit ${unit.label} blocks the target position`;
    }
    if (!areNeighbours(prevPosition, position, hexProperties)) {
        throw `Positions must be neighbours`;
    }
    return {
        ...state,
        movingUnits: {
            ...state.movingUnits,
            units: state.movingUnits.units.map((data) => {
                if (data.id !== unitId) {
                    return data;
                }
                const currentMovementPoints = data.currentMovementPoints - movementCost;
                return {
                    ...data,
                    currentMovementPoints,
                    path: [
                        ...data.path,
                        {
                            from: prevPosition,
                            to: position,
                            movementPointsAfterMoveHere: currentMovementPoints,
                        },
                    ],
                };
            }),
        },
    };
};
const returnToPathLeg = ({ request, game, state, }) => {
    const { unitId, userId, position } = request.payload;
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
    const unit = state.units.find((u) => u.id === unitId);
    if (!unit) {
        throw `Can not find unit with id ${unitId}`;
    }
    if (unit.side !== state.activeSide) {
        throw `Unit ${unit.label} does not belong to active side`;
    }
    if (state.movingUnits?.currentlyMovingId !== unitId) {
        throw `Unit ${unit.label} is not currently moving`;
    }
    const movementData = state.movingUnits.units.find((d) => d.id === unitId);
    if (!movementData) {
        throw `Missing movement data`;
    }
    const idx = movementData.path.findIndex((leg) => {
        return leg.to.column === position.column && leg.to.row === position.row;
    });
    const movementPointsAfterMoveHere = movementData.path[idx].movementPointsAfterMoveHere;
    const newPath = movementData.path.slice(0, idx + 1);
    return {
        ...state,
        movingUnits: {
            ...state.movingUnits,
            units: state.movingUnits.units.map((data) => {
                if (data.id !== unitId) {
                    return data;
                }
                return {
                    ...data,
                    currentMovementPoints: movementPointsAfterMoveHere,
                    path: newPath,
                };
            }),
        },
    };
};
const finishUnitMovement = ({ request, game, state, stateMachine, }) => {
    const { unitId, userId } = request.payload;
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
    if (state.movingUnits?.currentlyMovingId !== unitId) {
        throw `Unit ${unitId} is not currently moving`;
    }
    const movementData = state.movingUnits.units.find((d) => d.id === unitId);
    if (!movementData) {
        throw `Missing movement data`;
    }
    const lastLeg = movementData.path[movementData.path.length - 1];
    const nextState = {
        ...state,
        units: state.units.map((unit) => {
            if (unit.id !== unitId) {
                return unit;
            }
            return {
                ...unit,
                movementPoints: movementData.currentMovementPoints,
                position: {
                    column: lastLeg.to.column,
                    row: lastLeg.to.row,
                },
            };
        }),
        movingUnits: {
            ...state.movingUnits,
            currentlyMovingId: null,
            units: state.movingUnits.units.map((data) => {
                if (data.id !== unitId) {
                    return data;
                }
                return {
                    ...data,
                    finishedMovement: true,
                };
            }),
        },
    };
    return changeToNextState({ state: nextState, stateMachine });
};
export const stayAtOrigin = ({ request, game, state, stateMachine, }) => {
    const { unitId, userId } = request.payload;
    if (!isUserActive(userId, game.players, state.activeSide)) {
        throw `User ${userId} is not active`;
    }
    if (state.movingUnits?.currentlyMovingId !== unitId) {
        throw `Unit ${unitId} is not currently moving`;
    }
    const movementData = state.movingUnits.units.find((d) => d.id === unitId);
    if (!movementData) {
        throw `Missing movement data`;
    }
    const nextState = {
        ...state,
        movingUnits: {
            ...state.movingUnits,
            currentlyMovingId: null,
            units: state.movingUnits.units.map((data) => {
                if (data.id !== unitId) {
                    return data;
                }
                return {
                    ...data,
                    path: data.path.slice(0, 1),
                    currentMovementPoints: data.maxMovementPoints,
                    finishedMovement: true,
                };
            }),
        },
    };
    return changeToNextState({ state: nextState, stateMachine });
};
const changeToNextState = ({ state, stateMachine, }) => {
    const numOfAlreadyMoved = state.movingUnits?.units.reduce((sum, data) => {
        if (data.finishedMovement) {
            return sum + 1;
        }
        return sum;
    }, 0) ?? 0;
    const numOfSelected = state.unitsSelectedForActivation.length;
    let nextStateName = 'selectingForMovement';
    let nextState = state;
    if (numOfAlreadyMoved === numOfSelected) {
        nextStateName = 'selectingAttacker';
        nextState = {
            ...state,
            movingUnits: {
                currentlyMovingId: null,
                units: [],
            },
        };
    }
    return stateMachine.changeState(nextState, nextStateName);
};
