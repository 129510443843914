import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import UnitCounterAsImage from '@wareena/grid-battles-ui/game/unit/UnitCounterAsImage';
import ExampleBoldCalculation from './ExampleBoldCalculation';
import ExampleBattleBack from './ExampleBattleBack';

export default function BoldAndBattleBackPage() {
    return (
        <>
            <div className="rules-text mb-4">
                <h2 className="title is-2">Bold and Battle Back</h2>

                <p>
                    In battle, it is important to have friends close to you. Their presence can give
                    you courage not only to withstand blows from the enemy but also a will to return
                    them. We decided to model this behavior by introducing a <i>Bold</i> status for
                    units.
                </p>

                <article className="message">
                    <div className="message-body">
                        <strong>
                            A unit is <i>Bold</i> if there are at least two other friendly units
                            adjacent to it.
                        </strong>
                    </div>
                </article>

                <p>
                    This <i>Bold</i> status gives the unit a new capability called{' '}
                    <i>Battle Back.</i>
                </p>

                <article className="message">
                    <div className="message-body">
                        <strong>
                            <i>Battle Back</i> allows your unit to immediately respond to a melee
                            attack by a counter-attack of its own.
                        </strong>
                    </div>
                </article>

                <p>
                    The resolution of this counter-attack is automatic, you do not have to do
                    anything. It is played as a second round of combat resolution. If your unit
                    survives the initial attack, the roles of attacker and defender are reversed and
                    your unit immediately attacks back.
                </p>

                <p>
                    Remember:{' '}
                    <strong>
                        the unit can <i>Battle Back</i> only to melee attacks.
                    </strong>{' '}
                    It is not possible to respond to ranged attacks made even from a close distance.
                    There is a very good reason for that. Ranged units attack from a distance, and
                    even when they seem adjacent on a map, they are actually keeping their distance.
                    By responding to such attacks, your heavier warriors would have to leave their
                    formation and pursue a lighter enemy. It could be a disaster for them. So, we
                    just pretend your units are well trained and disciplined and do not do such
                    hasty reactions to missile fire.
                </p>

                <p>
                    An easy way how to quickly see which of your units have the <i>Bold</i> status
                    is by looking at their counters. All <i>Bold</i> units have a light-blue stripe
                    on the lower part of their counter.
                </p>

                <div className="is-flex is-justify-content-flex-start">
                    <figure className="mr-4 is-flex is-flex-direction-column is-align-items-center">
                        <UnitCounterAsImage
                            counterSize={58}
                            unit={createUnit(UnitTypeId.Pikeman)}
                        />
                        <figcaption>Normal unit</figcaption>
                    </figure>
                    <figure className="mr-4 is-flex is-flex-direction-column is-align-items-center">
                        <UnitCounterAsImage
                            counterSize={58}
                            unit={createUnit(UnitTypeId.Pikeman)}
                            isBold={true}
                        />
                        <figcaption>Bold unit</figcaption>
                    </figure>
                </div>

                <p className="mt-4">
                    Notice in the example below, how the <i>Bold</i> status changes when you move
                    Axeman unit around. During the movement, we are trying to show you a future
                    status not only for a moving unit but also how it affects others.
                </p>

                <ExampleBoldCalculation />

                <p className="mt-4">
                    Below you can try to do some attacks. Notice in the &quot;Combat detail&quot;
                    modal window that:
                </p>
                <ul className="list has-disc mb-4">
                    <li>
                        No unit is able to <i>Battle Back</i> when you are attacking with{' '}
                        <i>Archer</i>,
                    </li>
                    <li>
                        <i>Pikeman 1</i> is not <i>Bold</i>, so can not <i>Battle Back</i>
                    </li>
                    <li>
                        <i>Pikeman 3</i> is <i>Bold</i> and will <i>Battle Back</i> when attacked by
                        a melee unit (<i>Light Cav</i>), but not by the ranged attack (<i>Archer</i>
                        )
                    </li>
                </ul>

                <ExampleBattleBack />
            </div>
        </>
    );
}
