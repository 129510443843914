import React from 'react';
import HexOnPosition from '@wareena/ui/game/map/HexOnPosition';
function SelectedDefenderMarker({ position, hexProperties, }) {
    const style = {
        fill: 'rgba(255, 0, 0, 0.3)',
        stroke: 'red',
        strokeWidth: '3px',
    };
    return React.createElement(HexOnPosition, { position: position, hexProperties: hexProperties, style: style });
}
export default SelectedDefenderMarker;
