'use client';

import ExampleMovement from './ExampleMovement';
import ExampleSelect from './ExampleSelect';
import MovementSymbol from '@wareena/grid-battles-ui/game/unit/UnitCounter/MovementSymbol';
import UnitCounter from '@wareena/grid-battles-ui/game/unit/UnitCounter/index';
import createUnit from '@wareena/grid-battles-engine/services/unit/unitFactory';
import { UnitTypeId } from '@wareena/grid-battles-entity/game/codebook/unitTypes';
import Arrow from '@wareena/ui/game/markers/Arrow';
import terrainMap from '@wareena/grid-battles-ui/game/terrain/index';
import { TerrainId, isPassableTerrain } from '@wareena/grid-battles-entity/game/map/terrain';
import { createHexProperties } from '@wareena/game-algorithms';
import { getMovementCost } from '@wareena/grid-battles-entity/game/map/terrain';
import { getTerrainName } from '@wareena/grid-battles-entity/game/map/terrain';
import ExampleTerrain from './ExampleTerrain';
import ConfirmationIcon from '@wareena/grid-battles-ui/game/iconButton/counterOverlay/ConfirmationIcon';

export default function MovementPage() {
    const terrainHexProperties = createHexProperties({
        hexSize: 20,
        isFlatTop: false,
        isFirstOffsetted: false,
    });

    const terrainHexCenter = terrainHexProperties.calculateCenterPos(0, 0);

    const padding = 3;

    const terrainImgWidth = terrainHexProperties.width + 2 * padding;
    const terrainImgHeight = terrainHexProperties.height + 2 * padding;

    return (
        <>
            <div className="rules-text">
                <h2 className="title is-2">Movement</h2>

                <p>
                    After you activate your units, you are able to move them. All activated units
                    are marked with a yellow hexagon around them. You move each unit individually
                    and one after another. The first thing to do is to decide which of your
                    activated units will move first.
                </p>
                <p>You can try it in the example below, select 1 of your activated units.</p>

                <ExampleSelect />

                <h3 className="title is-3 mt-4">Basic Rules of Movement</h3>

                <ul className="list has-disc">
                    <li>You can move only one unit at a time</li>
                    <li>
                        Movement of one unit must be completely finished before you can start moving
                        another unit
                    </li>
                    <li>Only one unit can occupy a hex</li>
                    <li>The unit can not move through another unit</li>
                </ul>

                <p className="mt-4">
                    You can try these rules in action in the example below. Move one of your three
                    activated units.
                </p>

                <ExampleMovement />

                <h3 className="title is-3 mt-4">Symbols</h3>

                <div className="columns">
                    <div className="column is-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={125} height={63}>
                            <UnitCounter
                                counterSize={60}
                                origin={{ x: 5, y: 0 }}
                                unit={createUnit(UnitTypeId.LightCavalry)}
                            />
                            <MovementSymbol
                                position={{ x: 90, y: 9 }}
                                counterSize={150}
                                movementPoints={4}
                            />
                            <circle
                                cx={13}
                                cy={21}
                                r={10}
                                fill="transparent"
                                stroke="red"
                                strokeWidth={2}
                            />
                            <line x1={13} y1={11} x2={95} y2={2} stroke="red" strokeWidth={2} />
                            <line x1={13} y1={31} x2={95} y2={40} stroke="red" strokeWidth={2} />
                            <circle
                                cx={102}
                                cy={21}
                                r={20}
                                fill="transparent"
                                stroke="red"
                                strokeWidth={2}
                            />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            This symbol in the top left corner of a unit&apos;s counter indicates
                            how many movement points your unit has left. It is updated as you move
                            your unit around. If your unit has no movement points left, it can move
                            no further (this round).
                        </p>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={70} height={70}>
                            <UnitCounter
                                counterSize={60}
                                origin={{ x: 5, y: 0 }}
                                unit={createUnit(UnitTypeId.LightCavalry)}
                            />
                            <ConfirmationIcon counterSize={60} counterOrigin={{ x: 5, y: 0 }} />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            The &quot;Checked&quot; icon positioned over the unit counter is used as
                            a confirmation button. When you click on it, the movement of your unit
                            will be confirmed and the unit will be irreversibly moved to the
                            selected location and its movement will be finished. If you do not wish
                            to move your unit at all during your turn, just click on this icon while
                            your unit is still in its original position.
                        </p>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={55} height={63}>
                            <Arrow angle={180} tipPoint={{ x: 3, y: 30 }} baseSize={50} />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            The green arrow pointing away from your unit indicates the hexes where
                            the unit can move in its next step. Clicking on an arrow (or hex it is
                            inside of) will move the unit to the next hex and show you the next
                            movement possibilities from a new position.
                        </p>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={45} height={52}>
                            <Arrow
                                angle={180}
                                tipPoint={{ x: 3, y: 25 }}
                                baseSize={40}
                                style={{ opacity: 0.5 }}
                            />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            The semi-transparent green arrow is used to show you the path your unit
                            took during the current movement. You can click on each hex of this path
                            to return your unit back and then you can choose another path.
                        </p>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width={70} height={70}>
                            <UnitCounter
                                counterSize={60}
                                origin={{ x: 5, y: 0 }}
                                unit={createUnit(UnitTypeId.LightCavalry)}
                                containerProps={{ style: { opacity: 0.5 } }}
                            />
                        </svg>
                    </div>
                    <div className="column is-4">
                        <p>
                            The semi-transparent unit counter is shown during movement to indicate
                            the original position of your unit. If you click on it, your unit will
                            be returned back to this position and you can choose to take another
                            path (or not move at all).
                        </p>
                    </div>
                </div>

                <h3 className="title is-3 mt-4">Movement Points (MPs)</h3>

                <p>
                    Some units carry only light weapons and wear no armor, others are heavily
                    armored and also carry a huge or heavy weapon. And some of them are mounted. All
                    of this affect their ability to move and dictates how fast they are and how far
                    they can go.
                </p>

                <p>
                    We decided to model this constraint by the number of movement points each unit
                    has at its disposal during each round. Lighter and/or faster units have more
                    movement points available than heavier-equipped ones. Also mounted units
                    generally have more movement points than foot units.
                </p>
                <p>
                    At the start of a new round, each unit receives the number of movement points up
                    to its maximum. During the movement phase of the turn, the unit spends its
                    movement points to move to another position. Not spent movement points can not
                    be saved for another round and will not increase the maximum number of movement
                    points.
                </p>

                <p>
                    The unit is always able to move to the hex adjacent to its starting position,
                    regardless of the movement cost. In such a case, the unit will pay with all of
                    its movement points.
                </p>

                <p>
                    You can see the maximum number of movement points for each unit at the start of
                    a round. It is shown in the top-left corner of a unit counter.
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" width={125} height={63}>
                    <UnitCounter
                        counterSize={60}
                        origin={{ x: 5, y: 0 }}
                        unit={createUnit(UnitTypeId.LightCavalry)}
                    />
                    <MovementSymbol
                        position={{ x: 90, y: 9 }}
                        counterSize={150}
                        movementPoints={4}
                    />
                    <circle
                        cx={13}
                        cy={21}
                        r={10}
                        fill="transparent"
                        stroke="red"
                        strokeWidth={2}
                    />
                    <line x1={13} y1={11} x2={95} y2={2} stroke="red" strokeWidth={2} />
                    <line x1={13} y1={31} x2={95} y2={40} stroke="red" strokeWidth={2} />
                    <circle
                        cx={102}
                        cy={21}
                        r={20}
                        fill="transparent"
                        stroke="red"
                        strokeWidth={2}
                    />
                </svg>

                <h3 className="title is-3 mt-4">Terrain and its Effects on Movement</h3>

                <p>
                    It is usually more difficult for units to move and maneuver in a rough terrain.
                    Therefore it costs the unit a different number of movement points to enter
                    different types of terrain. And some terrain is even unpassable (like rivers).
                </p>

                <table className="table is-striped">
                    <thead>
                        <tr>
                            <th> </th>
                            <th>Terrain</th>
                            <th>Is passable</th>
                            <th>Number of movement points</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(terrainMap).map((idx) => {
                            const terrainId: TerrainId = +idx;

                            const TerrainHex = terrainMap[terrainId];

                            return (
                                <tr key={terrainId}>
                                    <td>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={terrainImgWidth}
                                            height={terrainImgHeight}
                                        >
                                            <TerrainHex
                                                isFlatTop={false}
                                                size={terrainHexProperties.size}
                                                center={terrainHexCenter}
                                                dimensions={{
                                                    width: terrainHexProperties.width,
                                                    height: terrainHexProperties.height,
                                                }}
                                            />
                                        </svg>
                                    </td>
                                    <td>{getTerrainName(terrainId)}</td>
                                    <td>{isPassableTerrain(terrainId) ? 'Yes' : 'No'}</td>
                                    <td>
                                        {isPassableTerrain(terrainId)
                                            ? getMovementCost(terrainId)
                                            : ''}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <p>You can try and see the effects of terrain in the example below:</p>

                <ExampleTerrain />
            </div>
        </>
    );
}
